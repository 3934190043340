import { useState } from "react";
import { Button } from "../../../../../components/Button/Button.tsx";
import { ClickableIcon } from "../../../../../components/Icon/ClickableIcon.tsx";
import { Popover } from "../../../../../components/Popover/Popover.tsx";
import { ASSETS, BOARDS } from "../../../../../routes.ts";
import { Card } from "../../../components/Card.tsx";

export const ShareButton = ({ assetUuid }: { assetUuid: string }) => {
  const [isShareBoardMenuOpen, setIsShareBoardMenuOpen] = useState(false);

  return (
    <Popover
      isOpen={isShareBoardMenuOpen}
      onOpenChange={setIsShareBoardMenuOpen}
      content={
        <AssetSharingMenu
          assetUuid={assetUuid}
          onCopy={() => setIsShareBoardMenuOpen(false)}
          onClose={() => setIsShareBoardMenuOpen(false)}
        />
      }
    >
      <ClickableIcon
        name="Share"
        size={12}
        onClick={() => setIsShareBoardMenuOpen(true)}
        iconClassName="stroke-gray-600 stroke-[0.7px]"
        tooltip={{ content: "Share", side: "top" }}
      />
    </Popover>
  );
};

const AssetSharingMenu = ({
  assetUuid,
  onCopy,
  onClose,
}: {
  assetUuid: string;
  onCopy: () => void;
  onClose: () => void;
}) => {
  // XXX: Instead of computing directly the shared link, we should ask the backend to generate it. This is a first
  // short implementation to test the feature.
  const urlSearchParams = new URLSearchParams();
  urlSearchParams.append("share", "true");
  const sharedLink = assetUuid
    ? `${getRootUrl()}${ASSETS}/${assetUuid}?${urlSearchParams.toString()}`
    : "";
  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(sharedLink);
    } finally {
      onCopy();
    }
  };

  return (
    <Card
      cardTitle={
        <span className="mr-auto text-2xl font-semibold text-gray-600">
          Share it with the world!
        </span>
      }
      children={
        <div className="w-full">
          <span className="text-lg text-secondary">
            Anyone with the link will be able to access your image
          </span>
          <div className="flex-row gap-2xl">
            <input
              type="text"
              className="flex-fill px-md rounded-sm border text-base text-secondary"
              readOnly
              value={sharedLink}
            />
            <Button onClick={() => void copyToClipboard()}>Copy Link</Button>
          </div>
        </div>
      }
      onCancel={onClose}
    />
  );
};

const getRootUrl = () =>
  window.location.href.slice(
    0,
    Math.max(0, window.location.href.lastIndexOf(BOARDS)),
  );
