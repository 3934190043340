import classNames from "classnames";
import { Link } from "react-router-dom";
import { Animation } from "../../../components/Animation/Animation.tsx";
import { Icon } from "../../../components/Icon/Icon.tsx";
import { HOME } from "../../../routes.ts";

export const StyleInitSplashScreen = ({
  widthClassName,
}: {
  widthClassName: string;
}) => (
  <div className="relative flex-col-center flex-fill w-full overflow-y-auto">
    <Link
      to={HOME}
      className="absolute top-16 left-16 rounded-sm border-[1px] border-gray-300 bg-white text-gray-600"
    >
      <Icon
        name="X"
        size={32}
        className="p-md rounded-sm bg-white text-gray-600 hover:bg-gray-200"
      />
    </Link>
    <div
      className={classNames(
        "flex-fill w-full flex-col pt-[80px] pb-[30px] gap-[40px] items-center",
        widthClassName,
      )}
    >
      <div className="flex-col items-center">
        <span className="text-[38px] text-gray-900 font-burg">
          style in creation...
        </span>
        <span className="text-xl text-gray-400">
          It takes on average 5 minutes. You'll be notified by email.
        </span>
        <div
          style={{ height: 327, width: 324 }}
          className="flex-fill flex-col-center"
        >
          <Animation
            loadData={() => import("../../../assets/boardInitLoading.json")}
            loop
            autoplay
          />
        </div>
      </div>

      <Link to="/">
        <span className="hover:opacity-70 underline underline-offset-4">
          Home
        </span>
      </Link>
    </div>
  </div>
);
