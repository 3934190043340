import { createAtom } from "../../../atoms/createAtom.ts";

export const useInformationMenuDisplayState = createAtom(
  {
    isInformationMenuOpen: true,
  },
  ({ get, set }) => ({
    toggleInformationMenuDisplayState: () =>
      set({
        isInformationMenuOpen: !get().isInformationMenuOpen,
      }),
  }),
);
