import classNames from "classnames";
import {
  type ButtonHTMLAttributes,
  forwardRef,
  type ReactNode,
  type Ref,
} from "react";
import { BaseButton } from "./BaseButton.tsx";
import styles from "./button.module.css";

export type ButtonProps = {
  children?: ReactNode;
  variant?: "base" | "primary";
  loading?: boolean;
  loadingContent?: ReactNode;
  size?: "small" | "medium" | "large";
} & ButtonHTMLAttributes<HTMLButtonElement>;

export const Button = forwardRef(
  (
    {
      children,
      loading,
      loadingContent,
      disabled,
      variant = "primary",
      type = "button",
      size = "medium",
      className,
      ...rest
    }: ButtonProps,
    ref: Ref<HTMLButtonElement>,
  ) => (
    <BaseButton
      ref={ref}
      className={classNames(styles[variant], styles[size], className)}
      type={type}
      disabled={!!disabled || loading}
      loading={loading}
      loadingContent={loadingContent}
      children={children}
      {...rest}
    />
  ),
);
