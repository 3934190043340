import type { ReactNode } from "react";
import { Slider } from "../../../../../components/Slider/Slider.tsx";
import { Info } from "../../../components/Info.tsx";

export const SliderSection = ({
  min,
  max,
  value,
  onChange,
  sliderName,
  sliderInformationSection,
  defaultValue,
  displayPercentage = true,
}: {
  min: number;
  max: number;
  value: number;
  onChange: (value: number) => void;
  sliderName: ReactNode;
  sliderInformationSection?: ReactNode;
  defaultValue?: number;
  displayPercentage?: boolean;
}) => (
  <div className="h-3xl flex-row items-center w-full justify-between">
    <div className="flex-row items-center gap-sm">
      {sliderName}
      {sliderInformationSection ? (
        <Info content={sliderInformationSection} />
      ) : null}
    </div>
    <Slider
      value={value}
      min={min}
      max={max}
      markValue={defaultValue}
      onChange={onChange}
      displayPercentage={displayPercentage}
      className="w-[114px]"
    />
  </div>
);
