export const OnboardingNoTextTip = ({ className }: { className?: string }) => (
  <svg
    width="52"
    height="50"
    viewBox="0 0 52 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <g id="icon">
      <g id="Group 1400003158">
        <path
          id="Vector"
          d="M36.8759 1H5.76476C3.31016 1 1.32031 2.98985 1.32031 5.44444V36.5556C1.32031 39.0102 3.31016 41 5.76476 41H36.8759C39.3305 41 41.3203 39.0102 41.3203 36.5556V5.44444C41.3203 2.98985 39.3305 1 36.8759 1Z"
          stroke="#919299"
        />
      </g>
      <g id="Group 1400003160">
        <path
          id="Vector_2"
          d="M12.0625 24.4643L16.4274 15.7344L20.7924 24.4643"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_3"
          d="M13.1562 22.2832H19.7037"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_4"
          d="M26.43 24.4654C28.238 24.4654 29.7037 22.9997 29.7037 21.1917C29.7037 19.3837 28.238 17.918 26.43 17.918C24.6219 17.918 23.1562 19.3837 23.1562 21.1917C23.1562 22.9997 24.6219 24.4654 26.43 24.4654Z"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_5"
          d="M29.7109 17.918V24.4654"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <g id="Group 1400003152">
        <circle
          id="Ellipse 1183"
          cx="41.4219"
          cy="39.7344"
          r="10"
          fill="#F8F8F8"
        />
        <g id="Group 1400003136">
          <path
            id="Vector_6"
            d="M45.2704 35.8887L37.5781 44.2366"
            stroke="#FF3366"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            id="Vector_7"
            d="M37.5781 36.0391L45.2704 44.387"
            stroke="#FF3366"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </g>
    </g>
  </svg>
);
