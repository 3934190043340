import { createAtom } from "../../../../../../../atoms/createAtom.ts";

export const useGeneratePrompt = createAtom(
  {
    prompt: "",
  },
  ({ set, setter }) => ({
    setPrompt: setter("prompt"),
    resetPrompt: () => set({ prompt: "" }),
  }),
);
