import classNames from "classnames";
import { ClickableIcon } from "../../../components/Icon/ClickableIcon.tsx";
import { Image } from "../../../components/Image/Image.tsx";
import { useAppMutation } from "../../../http/useAppMutation.ts";
import type { StyleImage } from "../../types.ts";

export const StyleCreationImageTile = ({
  image,
  className,
  innerClassName,
  styleUuid,
}: {
  image: StyleImage;
  styleUuid: string;
  className?: string;
  innerClassName?: string;
}) => {
  const deleteContent = useAppMutation({
    path: "contents/delete",
    invalidate: ["styles", `styles/${styleUuid}`],
  }).mutation;

  return (
    <div className={classNames("group relative", className)}>
      <ClickableIcon
        name="Trash"
        className="absolute top-12 right-12 p-sm invisible group-hover:visible rounded-full border-[1px] bg-white z-10"
        disabled={deleteContent.isLoading}
        onClick={(event) => {
          event.stopPropagation();
          deleteContent.mutate({
            uuids: [image.uuid],
            destroy: true,
          });
        }}
      />
      <div
        className={classNames(innerClassName, "h-full w-full overflow-clip")}
      >
        <Image
          className="h-full w-full"
          imageClassName="h-full w-full object-cover object-center"
          src={image.url}
        />
      </div>
    </div>
  );
};
