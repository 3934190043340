import { createAtom } from "../../../../../atoms/createAtom.ts";

export type ViewMode = "single" | "overlap";
export const useImageViewMode = createAtom(
  {
    viewMode: "overlap" as ViewMode,
  },
  ({ set }) => ({
    setViewMode: (viewMode: ViewMode) =>
      set({
        viewMode,
      }),
  }),
);
