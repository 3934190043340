import { useParams } from "react-router";
import { Query } from "../../components/Query/Query.tsx";
import { BoardContent } from "./BoardContent.tsx";
import { useShowDeletedAssets } from "./hooks/useShowDeletedAssets.ts";

export const BoardView = () => {
  const boardUuid = useParams()["uuid"]!;

  const { showDeletedAssets } = useShowDeletedAssets();

  return (
    <Query
      queryKey={`boards/${boardUuid}`}
      queryParamsKey={
        showDeletedAssets
          ? { include_deleted: showDeletedAssets.toString() }
          : undefined
      }
    >
      {(board) => <BoardContent board={board} />}
    </Query>
  );
};
