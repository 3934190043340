import classNames from "classnames";
import { useEffect, useState } from "react";
import { Accordion } from "../../../../../components/Accordion/Accordion.tsx";
import { ClickableIcon } from "../../../../../components/Icon/ClickableIcon.tsx";
import { Image } from "../../../../../components/Image/Image.tsx";
import { Spinner } from "../../../../../components/Spinner/Spinner.tsx";
import { useUser } from "../../../../../hooks/useUser.ts";
import { useAppMutation } from "../../../../../http/useAppMutation.ts";
import { useAppQuery } from "../../../../../http/useAppQuery.ts";
import { STYLE_COLOR_KIND, STYLE_IMAGES_KIND } from "../../../../constants.ts";
import type {
  Style,
  StyleColorKind,
  StyleImagesKind,
} from "../../../../types.ts";
import { useSelectedStyleUuid } from "../../../hooks/useSelectedStyleUuid.ts";
import { useOpenStyleImageModal } from "../../../ImageModal/useOpenStyleImageModal.ts";
import type { Board } from "../../../types.ts";
import { SelectSection } from "./SelectSection.tsx";
import { SettingsSectionLayout } from "./SettingsSectionWrapper.tsx";
import { StyleSelector } from "./StyleSelector.tsx";
import { TextSection } from "./TextSection.tsx";

export const StyleWrappedSection = ({ board }: { board: Board }) => {
  const { selectedStyleUuid } = useSelectedStyleUuid();
  const [isStyleSelectorOpen, setIsStyleSelectorOpen] = useState(false);

  useEffect(() => {
    if (selectedStyleUuid === null) setIsStyleSelectorOpen(true);
  }, [selectedStyleUuid]);

  const { data: style, isLoading } = useAppQuery<Style>({
    queryKey: `styles/${selectedStyleUuid}`,
    enabled: !!selectedStyleUuid,
  });

  return (
    // FIXME: better manage the possible values of selectedStyleUuid. It is currently unstable.
    selectedStyleUuid !== null ? (
      isLoading ? (
        <Spinner />
      ) : style ? (
        <StyleMenu style={style} board={board} />
      ) : null
    ) : (
      <StyleSelector
        isOpen={isStyleSelectorOpen}
        onOpenChange={setIsStyleSelectorOpen}
        board={board}
        clickOutsideAuthorized={false}
        displayCloseButton={false}
      />
    )
  );
};

const StyleMenu = ({ style, board }: { style: Style; board: Board }) => {
  const [areAllImageDisplayed, setAreAllImageDisplayed] = useState<string>();

  const { mutate: updateStyle } = useAppMutation({
    path: "styles/update",
    invalidate: [`styles/${style.uuid}`],
  }).mutation;

  const [composition, setComposition] = useState<string>(
    style.composition ?? "",
  );
  const [additionalElements, setAdditionalElements] = useState<string>(
    style.additional_elements ?? "",
  );

  const { user } = useUser();

  // XXX: User can update style only if the style is its own or if the user has the permission to update curated styles
  const isStyleUpdateDisabled =
    style.is_curated &&
    !user?.permissions.includes("styles:update-curated-styles");

  const [isStyleSelectorOpen, setIsStyleSelectorOpen] =
    useState<boolean>(false);

  return (
    <>
      <SettingsSectionLayout>
        <Accordion
          value={areAllImageDisplayed}
          onValueChange={setAreAllImageDisplayed}
          accordionItemClassName="border-b-0 !p-0"
          accordionTriggerClassName="relative hover:!no-underline w-full"
          iconClassName="stroke-xl"
          iconSize={16}
          iconPosition="right"
          iconTriggerOnly
          elements={[
            {
              title: (
                //  XXX: We put a cursor-auto class to prevent cursor change when hovering the accordion trigger as
                //  only the arrow icon is clickable
                <div className="flex-fill h-3xl flex-row items-center justify-between cursor-auto">
                  <span className="font-semibold">Style</span>
                  {/* FIXME: We should not use a button inside another button that will results in React warning. We
                        should instead:
                        - Review design to avoid this situation and split accordion from style change button
                        - Review accordion component to make only icon clickable */}
                  <button
                    className="flex-row max-w-[80%] h-2xl gap-md items-center px-md rounded-sm hover:bg-gray-200"
                    onClick={(event) => {
                      event.stopPropagation();
                      setIsStyleSelectorOpen(true);
                    }}
                  >
                    <span className="max-w-[80%] truncate">
                      {style.name === "" ? "Untitled style" : style.name}
                    </span>
                    <Image
                      className="w-xl h-xl aspect-square"
                      imageClassName="h-full w-full object-cover object-center rounded-xs"
                      src={style.training_images[0].url}
                    />
                  </button>
                </div>
              ),
              content: (
                <div className="pt-md pr-md flex-col gap-md">
                  <StyleImagesGrid style={style} />
                  <div className="flex-col w-full">
                    <SelectSection
                      name="Type"
                      value={style.images_kind ?? undefined}
                      onChange={(type) => {
                        updateStyle({
                          uuid: style.uuid,
                          images_kind: type,
                        });
                      }}
                      options={STYLE_IMAGES_KIND}
                      optionToLabel={StyleTypeDisplay}
                      disabled={isStyleUpdateDisabled}
                      informationSection="Your choice will influence your generations. “Other” will not influence your prompt."
                    />
                    <SelectSection
                      name="Color"
                      value={style.color_kind ?? undefined}
                      onChange={(type) => {
                        updateStyle({
                          uuid: style.uuid,
                          color_kind: type,
                        });
                      }}
                      options={STYLE_COLOR_KIND}
                      optionToLabel={ColorTypeDisplay}
                      disabled={isStyleUpdateDisabled}
                      informationSection="Your choice will influence your generations. “Other” will not influence your prompt."
                    />
                    <TextSection
                      title="Style additional elements"
                      textInformationSection="These elements will be automatically added to all your prompts."
                      valuePlaceholder="motion blur, waterpainting..."
                      value={additionalElements}
                      onValueChange={setAdditionalElements}
                      onBlur={() =>
                        updateStyle({
                          uuid: style.uuid,
                          additional_elements: additionalElements,
                        })
                      }
                      disabled={isStyleUpdateDisabled}
                    />
                    <TextSection
                      title="Composition"
                      textInformationSection="These elements will be automatically added to all your prompts."
                      valuePlaceholder="very detailed, low angle, close up, indoor light, dramatic..."
                      value={composition}
                      onValueChange={setComposition}
                      onBlur={() =>
                        updateStyle({
                          uuid: style.uuid,
                          composition,
                        })
                      }
                      disabled={isStyleUpdateDisabled}
                    />
                  </div>
                </div>
              ),
              value: "style",
            },
          ]}
        />
      </SettingsSectionLayout>
      <StyleSelector
        isOpen={isStyleSelectorOpen}
        onOpenChange={setIsStyleSelectorOpen}
        board={board}
        clickOutsideAuthorized
        displayCloseButton
      />
    </>
  );
};

const StyleImagesGrid = ({
  style,
  startRange,
  endRange,
  className,
}: {
  style: Style;
  startRange?: number;
  endRange?: number;
  className?: string;
}) => {
  const { openStyleImageModal } = useOpenStyleImageModal();

  return (
    <div
      className={classNames(className, "cursor-auto grid grid-cols-4 gap-md")}
      onClick={(event) => {
        event.stopPropagation();
      }}
    >
      {style.training_images.slice(startRange, endRange).map((image) => (
        <div
          key={image.uuid}
          className="relative group h-full w-full aspect-square"
        >
          <Image
            className="h-full w-full aspect-square"
            imageClassName="h-full w-full object-cover object-center rounded-xs"
            src={image.url}
          />
          <ClickableIcon
            name="Expand"
            size={10}
            tooltip={{ side: "left", content: "View full size" }}
            className="absolute top-4 right-4 invisible group-hover:visible tile-action"
            onClick={(event) => {
              event.stopPropagation();
              openStyleImageModal(style.uuid, image.uuid, true);
            }}
          />
        </div>
      ))}
    </div>
  );
};

const StyleTypeDisplay: Record<StyleImagesKind, string> = {
  photography: "Photography",
  illustration_2d: "2D Illustration",
  graphic_3d: "3D Graphic",
  icon: "Icon",
  other: "Other",
} as const;

const ColorTypeDisplay: Record<StyleColorKind, string> = {
  black_and_white: "Black and White",
  monochrome: "Monochrome",
  bland: "Bland",
  regular: "Regular",
  bright: "Bright",
  other: "Other",
} as const;
