import { BaseButton } from "../../../components/Button/BaseButton.tsx";
import { EditableText } from "../../../components/EditableText/EditableText.tsx";
import { Icon } from "../../../components/Icon/Icon.tsx";
import { Image } from "../../../components/Image/Image.tsx";
import { useUser } from "../../../hooks/useUser.ts";
import { useAppMutation } from "../../../http/useAppMutation.ts";
import { isUserAllowedTo } from "../../../types.ts";
import { useUserCanOpenStyleTagMenu } from "../hooks/useUserCanOpenStyleTagMenu.ts";
import type { Style } from "../types.ts";
import { StyleExtraMenu } from "./StyleExtraMenu.tsx";

export const StyleTile = ({
  style,
  onClick,
  isLoading,
  disabled,
  isEditable = true,
}: {
  style: Style;
  onClick?: () => void;
  isLoading?: boolean;
  disabled?: boolean;
  isEditable?: boolean;
}) => {
  const { user } = useUser();
  const userCanOpenStyleTagMenu = useUserCanOpenStyleTagMenu();

  const { mutate: nameMutate, isLoading: nameIsloading } = useAppMutation({
    path: "styles/update",
    invalidate: ["styles", `styles/${style.uuid}`],
  }).mutation;

  return (
    <div
      className="group relative flex-col-center gap-lg rounded-sm"
      style={{ width: 180 }}
    >
      <BaseButton
        className="w-full"
        style={{ height: 220 }}
        onClick={onClick}
        loading={isLoading}
        disabled={disabled}
      >
        {style.thumbnail_url ? (
          <Image
            src={style.thumbnail_url}
            className="h-full w-full rounded-xs"
            imageClassName="h-full w-full object-cover object-center hover:scale-110"
            loading="lazy"
          />
        ) : (
          <div className="h-full w-full rounded-xs overflow-clip">
            <div className="flex-col-center h-full w-full bg-gray-100 hover:scale-110">
              <Icon
                size={70}
                name="PimentoStar"
                className="stroke-transparent fill-pimento-blue"
              />
            </div>
          </div>
        )}
      </BaseButton>
      {isEditable ? (
        <EditableText
          text={style.name ?? "Untitled"}
          onTextChange={(name) => nameMutate({ uuid: style.uuid, name })}
          className="h-3xl"
          textClassName="text-lg font-semibold"
          inputClassName="rounded text-lg font-semibold"
          isLoading={nameIsloading}
        />
      ) : (
        <span className="w-full max-h-full truncate text-lg font-semibold">
          {style.name ?? "Untitled"}
        </span>
      )}
      {user &&
        (isUserAllowedTo(user, "styles:delete") ||
          isUserAllowedTo(user, "styles:curate") ||
          userCanOpenStyleTagMenu) && (
          <div className="absolute right-sm top-sm">
            <StyleExtraMenu
              style={style}
              className="invisible group-hover:visible"
            />
          </div>
        )}
    </div>
  );
};
