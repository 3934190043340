import type { ReactNode } from "react";
import { Info } from "../../../components/Info.tsx";

export const TextSection = ({
  title,
  value,
  onValueChange,
  valuePlaceholder,
  textInformationSection,
  onBlur,
  disabled = false,
}: {
  title?: string;
  value: string;
  valuePlaceholder?: string;
  onValueChange: (text: string) => void;
  textInformationSection?: ReactNode;
  onBlur?: () => void;
  disabled?: boolean;
}) => (
  <div className="flex-col pb-md">
    <div className="flex-row items-center gap-sm">
      {title && <div className="h-3xl flex-row items-center">{title}</div>}
      {textInformationSection ? (
        <Info content={textInformationSection} />
      ) : null}
    </div>
    <textarea
      className="w-full resize-none h-[64px] rounded-sm p-md border placeholder:text-placeholder focus:border-gray-300 hover:border-gray-300"
      placeholder={valuePlaceholder}
      value={value}
      onChange={(event) => {
        onValueChange(event.target.value);
      }}
      onKeyDown={(e) => {
        e.stopPropagation();
      }}
      onBlur={onBlur}
      disabled={disabled}
    />
  </div>
);
