import { useMemo } from "react";
import { useUser } from "../../../hooks/useUser.ts";
import { useAppQuery } from "../../../http/useAppQuery.ts";
import { isUserAllowedTo } from "../../../types.ts";
import type { Style } from "../types.ts";

// FIXME: these values should come from the backend directly.
// For now we protect ourselves with a 10.000.000 boards for premium users.
const LIMITED_AMOUNT_OF_STYLES = 2;
const UNLIMITED_AMOUNT_OF_STYLES = 10000000;

export const useStyles = () => {
  const { user } = useUser();
  const stylesQuery = useAppQuery<Style[]>({ queryKey: "styles" });

  const userStyles = stylesQuery.data?.filter((board) => !board.is_curated);
  const libraryStyles = stylesQuery.data?.filter((board) => board.is_curated);

  const maxAmountOfUserStyles =
    user && isUserAllowedTo(user, "styles:create-unlimited")
      ? UNLIMITED_AMOUNT_OF_STYLES
      : LIMITED_AMOUNT_OF_STYLES;

  const remainingUserStyles = useMemo(() => {
    if (userStyles) {
      return Math.max(0, maxAmountOfUserStyles - userStyles.length);
    }
    // If we don't know how many user board the user has created,
    // we consider they don't have remaining ones to create.
    return 0;
  }, [userStyles, maxAmountOfUserStyles]);

  const libraryStylesCountByTags = useMemo(
    () =>
      libraryStyles
        ?.flatMapNotNull((libraryBoard) => libraryBoard.tags)
        .reduce(
          (accumulator, value) => {
            accumulator[value] =
              accumulator[value] !== undefined ? accumulator[value]! + 1 : 1;
            return accumulator;
          },
          {} as {
            [Key: string]: number | undefined;
          },
        ),
    [libraryStyles],
  );

  return {
    userStyles,
    maxAmountOfUserStyles,
    remainingUserStyles,
    libraryStyles,
    libraryStylesCountByTags,
  };
};
