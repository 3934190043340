import { useSearchParams } from "react-router-dom";
import { updateSearch } from "../../../utils/url.ts";

export const useOpenStyleImageModal = () => {
  const [params, setParams] = useSearchParams();
  const styleUuidParam = params.get("styleUuid");
  const imageUuidParam = params.get("imageUuid");
  return {
    isOpenStyleImageModal: styleUuidParam !== null && imageUuidParam !== null,
    openStyleImageModal: (
      styleUuid: string,
      imageUuid: string,
      navigate = true,
    ) =>
      setParams(
        updateSearch({
          styleUuid,
          imageUuid,
          navigate: navigate ? "true" : "false",
        }),
      ),
  };
};
