import type { IEvent } from "fabric/fabric-impl";
import type { Board } from "../../../../types.ts";
import { EditToolViewLayout } from "../../components/EditToolViewLayout.tsx";
import { GenerativeFillSettings } from "./GenerativeFillSettings.tsx";
import { GenerativeFillTool } from "./GenerativeFillTool.tsx";
import { GenerativeFillToolbar } from "./GenerativeFillToolbar.tsx";
import { useGenerativeFillCanvas } from "./hooks/useGenerativeFillCanvas.ts";

export const GenerativeFillView = ({ board }: { board: Board }) => {
  const { fabricCanvas, setFabricCanvas, addObject, removeObject } =
    useGenerativeFillCanvas();

  return (
    <EditToolViewLayout
      settingsView={
        <GenerativeFillSettings board={board} fabricCanvas={fabricCanvas} />
      }
      contentView={
        <GenerativeFillTool
          board={board}
          fabricCanvas={fabricCanvas}
          onCanvasReady={(canvas) => {
            canvas.on("object:added", addObject as (e: IEvent) => void);
            canvas.on("object:removed", removeObject as (e: IEvent) => void);
            setFabricCanvas(canvas);
          }}
        />
      }
      toolBarContent={<GenerativeFillToolbar board={board} />}
      board={board}
    />
  );
};
