import { SettingsSectionWrapper } from "../../../components/SettingsMenu/SettingsSectionWrapper.tsx";
import { InitialImagePicker } from "./InitialImagePicker.tsx";

export const InitialImageWrappedSection = ({
  imageUuid,
  onImageUuidChange,
}: {
  imageUuid?: string;
  onImageUuidChange: (uuid: string | undefined) => void;
}) => (
  <SettingsSectionWrapper
    name="Initial image"
    content={
      <InitialImagePicker
        imageUuid={imageUuid}
        onImageUuidChange={onImageUuidChange}
      />
    }
  />
);
