import type { ReactNode } from "react";

export const WorkspaceLayout = ({
  toolMenu,
  content,
}: {
  toolMenu: ReactNode;
  content: ReactNode;
}) => (
  <div className="relative flex-fill flex-row">
    {toolMenu}
    {content}
  </div>
);
