import type { IEvent } from "fabric/fabric-impl";
import type { Board } from "../../../../types.ts";
import { EditToolViewLayout } from "../../components/EditToolViewLayout.tsx";
import { DrawToolbar } from "./DrawToolbar.tsx";
import { useDrawCanvas } from "./hooks/useDrawCanvas.ts";
import { MagicDrawSettings } from "./MagicDrawSettings.tsx";
import { MagicDrawTool } from "./MagicDrawTool.tsx";

export const MagicDrawView = ({ board }: { board: Board }) => {
  const {
    fabricCanvas,
    setFabricCanvas,
    addObject,
    removeObject,
    addActionHistory,
    removeActionHistory,
    clearActionHistory,
  } = useDrawCanvas();

  return (
    <EditToolViewLayout
      settingsView={
        <MagicDrawSettings board={board} fabricCanvas={fabricCanvas} />
      }
      contentView={
        <MagicDrawTool
          board={board}
          fabricCanvas={fabricCanvas}
          onCanvasReady={(canvas) => {
            canvas.on("object:added", addObject as (e: IEvent) => void);
            canvas.on("object:removed", removeObject as (e: IEvent) => void);
            canvas.on(
              "history:append",
              addActionHistory as (e: IEvent) => void,
            );
            canvas.on(
              "history:clear",
              clearActionHistory as (e: IEvent) => void,
            );
            canvas.on("history:redo", addActionHistory as (e: IEvent) => void);
            canvas.on(
              "history:undo",
              removeActionHistory as (e: IEvent) => void,
            );
            setFabricCanvas(canvas);
          }}
        />
      }
      toolBarContent={<DrawToolbar board={board} />}
      board={board}
    />
  );
};
