import { useEffect, useState } from "react";
import { DescriptionLoader } from "../../../../components/DescriptionLoader.tsx";
import { useGetSelectedAsset } from "../../../../hooks/useGetSelectedAsset.ts";
import { useSelectedStyleUuid } from "../../../../hooks/useSelectedStyleUuid.ts";
import type { Board } from "../../../../types.ts";
import { GenerationBar } from "../../../components/GenerationBar/GenerationBar.tsx";
import { scrollImageStripeToStart } from "../../ImageStripe/hooks/scrollImageStripeToStart.ts";
import { useVariationGeneration } from "./hooks/useVariationGeneration.ts";

export const VariationGenerationBar = ({ board }: { board: Board }) => {
  const [isWaitingForDescription, setIsWaitingForDescription] = useState(false);
  const selectedAsset = useGetSelectedAsset(board);
  const [prompt, setPrompt] = useState(selectedAsset?.image.description ?? "");

  useEffect(() => {
    setPrompt(selectedAsset?.image.description ?? "");
  }, [selectedAsset]);

  useEffect(() => {
    // XXX: we add prompt in the condition is case the image is never described
    if (
      (selectedAsset === undefined || selectedAsset.image.description) ??
      prompt
    ) {
      setIsWaitingForDescription(false);
    } else {
      setIsWaitingForDescription(true);
    }
  }, [selectedAsset, prompt]);

  const {
    mutation: generateVariation,
    isLoading: isVariationGenerationLoading,
  } = useVariationGeneration({
    board,
    onSuccess: () => {
      scrollImageStripeToStart();
    },
  });

  const { selectedStyleUuid } = useSelectedStyleUuid();

  return (
    <GenerationBar
      isLoading={isVariationGenerationLoading || isWaitingForDescription}
      loadingContent={
        isWaitingForDescription ? <DescriptionLoader /> : undefined
      }
      onGenerate={() => {
        if (selectedAsset && selectedStyleUuid) {
          generateVariation({
            prompt,
            styleUuid: selectedStyleUuid,
            assetUuid: selectedAsset.uuid,
          });
        }
      }}
      isDeactivated={!selectedStyleUuid || !selectedAsset}
      prompt={prompt}
      setPrompt={setPrompt}
      sectionTitle="Image description"
      buttonContent="Generate variations"
    />
  );
};
