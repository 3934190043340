import { Button } from "../../../components/Button/Button.tsx";
import { RadialProgress } from "../../../components/RadialProgress/RadialProgress.tsx";
import { useIsUpgradeDialogOpen } from "../../../singletons/UpgradeDialog/hooks/useIsUpgradeDialogOpen.ts";
import { useCreateStyle } from "../hooks/useCreateStyle.ts";
import { useStyles } from "../hooks/useStyles.ts";

export const NewStyleTileOrUpgrade = () => {
  const { setIsUpgradeDialogOpen } = useIsUpgradeDialogOpen();
  const { createStyle, isCreateStyleLoading } = useCreateStyle({
    onStyleCreationForbidden: () => setIsUpgradeDialogOpen(true),
  });
  const { remainingUserStyles, maxAmountOfUserStyles } = useStyles();

  return (
    <Button
      className="group"
      onClick={createStyle}
      loading={isCreateStyleLoading}
    >
      <div className="flex-row items-center gap-md text-inherit">
        New style
        {remainingUserStyles < 100 && (
          <RadialProgress
            progress={remainingUserStyles}
            minProgress={0}
            maxProgress={maxAmountOfUserStyles}
            counterClockwise
            backgroundClassName="group-hover:bg-gray-600 bg-gray-700"
          />
        )}
      </div>
    </Button>
  );
};
