import * as TooltipPrimitive from "@radix-ui/react-tooltip";
import classNames from "classnames";
import type { ReactNode } from "react";

export type TooltipProps = {
  className?: string;
  content?: ReactNode;
  delayDuration?: TooltipPrimitive.TooltipProps["delayDuration"];
  side?: TooltipPrimitive.TooltipContentProps["side"];
  align?: TooltipPrimitive.TooltipContentProps["align"];
  isOpened?: boolean;
  arrowDisplayed?: boolean;
  children: ReactNode;
};

export const Tooltip = ({
  children,
  content,
  className,
  side = "top",
  align = "center",
  arrowDisplayed = false,
  delayDuration,
  isOpened,
  ...props
}: TooltipProps) => {
  if (!content) return children;

  return (
    <TooltipPrimitive.Tooltip delayDuration={delayDuration} open={isOpened}>
      <TooltipPrimitive.Trigger asChild>{children}</TooltipPrimitive.Trigger>
      <TooltipPrimitive.Content
        sideOffset={4}
        className={classNames(
          "z-50 overflow-hidden p-lg text-base text-primary bg-white rounded-sm shadow-md animate-in fade-in-0 zoom-in-95 data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=closed]:zoom-out-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2",
          className,
        )}
        side={side}
        align={align}
        {...props}
      >
        {content}
        {arrowDisplayed && <TooltipPrimitive.Arrow />}
      </TooltipPrimitive.Content>
    </TooltipPrimitive.Tooltip>
  );
};

export { TooltipProvider } from "@radix-ui/react-tooltip";
