import { fabric } from "fabric";
import { useEffect, useRef } from "react";

fabric.Object.prototype.set({
  cornerSize: 6,
});

export const FabricCanvas = ({
  initWidth,
  initHeight,
  initBackgroundColor,
  onCanvasReady,
}: {
  initWidth: number;
  initHeight: number;
  initBackgroundColor?: string;
  onCanvasReady: (canvas: fabric.Canvas) => void;
}) => {
  const canvasRef = useRef(null);

  useEffect(() => {
    const fabricCanvas = new fabric.Canvas(canvasRef.current, {
      height: initHeight,
      width: initWidth,
      backgroundColor: initBackgroundColor,
      isDrawingMode: true,
    });
    const disposeFabric = () => {
      fabricCanvas.dispose();
    };

    onCanvasReady(fabricCanvas);

    return () => {
      disposeFabric();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <canvas ref={canvasRef} />
    </div>
  );
};
