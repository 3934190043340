import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Carousel } from "../../../components/Carousel/Carousel.tsx";
import { Icon } from "../../../components/Icon/Icon.tsx";
import { BOARDS, CREATE_WORKSPACE } from "../../../routes.ts";
import { useBoards } from "../hooks/useBoards.ts";
import type { Board } from "../types.ts";
import { BoardTile } from "./BoardTile.tsx";
import { NewBoardOrUpgrade } from "./NewBoardOrUpgrade.tsx";

export const BoardSection = () => {
  const [searchText, setSearchText] = useState("");

  const navigate = useNavigate();

  const { userBoards } = useBoards();

  const filteredBoards = useMemo(
    () =>
      userBoards?.filter((board: Board) =>
        board.name.toLowerCase().includes(searchText),
      ),
    [userBoards, searchText],
  );

  return userBoards && userBoards.length > 0 ? (
    <div className="flex-col gap-lg">
      <div className="px-3xl flex-row items-center justify-between">
        <div className="text-2xl font-semibold">My projects</div>
        <div className="flex-row gap-xl">
          <div className="relative w-[175px] flex-row items-center">
            <input
              className="w-full h-3xl pl-md pr-xl rounded border placeholder:text-gray-350"
              placeholder="Search..."
              value={searchText}
              onChange={(event) => {
                setSearchText(event.target.value.toLowerCase());
              }}
            />
            <Icon name="Search" className="absolute right-md stroke-gray-350" />
          </div>
          <NewBoardOrUpgrade key="new_board_tile_or_upgrade" />
        </div>
      </div>
      <div className="w-full">
        {filteredBoards && filteredBoards.length > 0 ? (
          <Carousel
            items={filteredBoards
              .sortDesc((board) => board.edited_at)
              .map((board) => (
                <BoardTile
                  key={board.uuid}
                  board={board}
                  onClick={() => {
                    navigate(`${BOARDS}/${board.uuid}/${CREATE_WORKSPACE}`);
                  }}
                />
              ))}
            leftButtonClassName="top-[86px] left-3xl"
            rightButtonClassName="top-[86px] right-3xl"
            className="px-3xl gap-lg"
          />
        ) : (
          <div className="h-[258px] flex-col-center text-xl font-semibold text-gray-400">
            No results found
          </div>
        )}
      </div>
    </div>
  ) : null;
};
