import classNames from "classnames";
import type { ReactNode } from "react";
import { BaseButton } from "../../../components/Button/BaseButton.tsx";
import { Dialog } from "../../../components/Dialog/Dialog.tsx";
import { Icon } from "../../../components/Icon/Icon.tsx";

export const DeletionDialog = ({
  dialogOpen,
  onDialogChange,
  onCancel,
  isLoading,
  onClick,
  children,
}: {
  dialogOpen: boolean;
  onDialogChange: (dialogChange: boolean) => void;
  onCancel: () => void;
  isLoading?: boolean;
  onClick: () => void;
  children: ReactNode;
}) => (
  <Dialog
    isOpen={dialogOpen}
    onOpenChange={onDialogChange}
    displayCloseButton={false}
    clickOutsideAuthorized
    className="sm:max-w-[500px] w-[400px] bg-white p-2xl"
    content={
      <>
        <div className="mb-16">
          <Icon name="Trash" size={24} />
        </div>
        {children}
        <div className="flex-row justify-between">
          <BaseButton
            onClick={() => onCancel()}
            disabled={isLoading}
            className="rounded-sm py-sm flex-row items-center"
          >
            <div className="text-black font-medium underline underline-offset-4 hover:text-gray-700">
              Cancel
            </div>
          </BaseButton>
          <BaseButton
            className={classNames(
              "rounded-sm border-2 min-w-[90px] min-h-[40px] flex-row-center hover:bg-pimento-red",
              isLoading ? "bg-pimento-red" : "",
            )}
            loading={isLoading}
            onClick={onClick}
          >
            <div className="text-black font-medium">Delete</div>
          </BaseButton>
        </div>
      </>
    }
  />
);
