import type { SelectedFilters } from "../../../hooks/useTagsFilter.ts";
import type { BoardAsset } from "../../../types.ts";

// FIXME: This function interface should be refactored to use custom types instead of common ones specific to some
//  components (Board, ...) in the application.
//  For instance instead of using `ImageContent` and `Tag` types, we should use a custom type like : {uuid: string}[]
export const filterAssetsByTags = ({
  assets,
  filterTags,
}: {
  assets: BoardAsset[];
  filterTags: SelectedFilters;
}) => {
  if (filterTags.length === 0) return assets;

  return assets.filter((image) => {
    for (const tagFilter of filterTags) {
      if (image.tags.some((tag) => tag.uuid === tagFilter.uuid)) return true;
    }
    return false;
  });
};
