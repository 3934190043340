import { UrlAnimation } from "../../../../../components/Animation/UrlAnimation.tsx";
import { SliderSection } from "./SliderSection.tsx";

export const StyleIntensitySection = ({
  loraScale,
  onLoraScaleChange,
  defaultLoraScale,
}: {
  loraScale: number;
  onLoraScaleChange: (loraScale: number) => void;
  defaultLoraScale?: number;
}) => (
  <SliderSection
    value={Math.round(loraScale * 100)}
    min={0}
    max={100}
    onChange={(loraScalePercentage) =>
      onLoraScaleChange(loraScalePercentage / 100)
    }
    sliderName="Style intensity"
    defaultValue={defaultLoraScale ? defaultLoraScale * 100 : undefined}
    sliderInformationSection={
      <div className="flex-col min-h-[260px] py-sm gap-md">
        <div>
          Higher style intensity leads to generations closer to your style
          images.
        </div>
        <UrlAnimation url="https://storage.googleapis.com/419c45cf-be8a-4cba-bbcd-74a221eb2587/app/assets/0fce170e-234c-479b-99f9-c25642c628ef.json" />
      </div>
    }
  />
);
