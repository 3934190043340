import { useAuth0 } from "@auth0/auth0-react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios, { type AxiosProgressEvent, type AxiosResponse } from "axios";
import { useState } from "react";
import { useIsUpgradeDialogOpen } from "../singletons/UpgradeDialog/hooks/useIsUpgradeDialogOpen.ts";
import { notifier } from "../utils/notifier.ts";
import { trackEvent } from "../utils/trackEvent.ts";
import { APIErrorCodeMap, APIValidErrorCode } from "./apiError.ts";
import { getUserIp } from "./getUserIp.ts";

export const useAppMutation = <TData>({
  path,
  invalidate,
  waitForInvalidation = true,
  onMutate,
  onSuccess,
  onSettled,
  onCacheUpdated,
  onError,
  config,
}: {
  path: string;
  invalidate: string[];
  waitForInvalidation?: boolean;
  onMutate?: (variables: any) => any;
  onSettled?: () => void | Promise<void>;
  onSuccess?: (response: AxiosResponse<TData>) => void | Promise<void>;
  onError?: (error: Error, variables: any, context: any) => void;
  onCacheUpdated?: (response: AxiosResponse<TData>) => void | Promise<void>;
  config?: {
    headers?: { [p: string]: string };
    onUploadProgress?: (progressEvent: AxiosProgressEvent) => void;
  };
}) => {
  const queryClient = useQueryClient();
  const { getAccessTokenSilently } = useAuth0();
  const [progress, setProgress] = useState(0);
  const { setIsUpgradeDialogOpen } = useIsUpgradeDialogOpen();

  return {
    progress,
    mutation: useMutation({
      mutationKey: [path],
      onSettled,
      onMutate,
      onSuccess: async (response) => {
        await onSuccess?.(response);
        const invalidationPromise = Promise.all(
          invalidate.map((queryKey) =>
            queryClient.invalidateQueries({ queryKey: [queryKey] }),
          ),
        ).then(() => onCacheUpdated?.(response));
        if (waitForInvalidation) await invalidationPromise;
      },
      onError: (error: Error, variables, context) => {
        onError?.(error, variables, context);
        console.log(error);

        let message: string;
        if (
          typeof error === "object" &&
          "response" in error &&
          error.response &&
          typeof error.response === "object" &&
          "data" in error.response &&
          typeof error.response.data === "object" &&
          error.response.data &&
          "detail" in error.response.data &&
          typeof error.response.data.detail === "string" &&
          "headers" in error.response &&
          typeof error.response.headers === "object" &&
          error.response.headers &&
          "x-pimento-error-code" in error.response.headers
        ) {
          const errorCodeValue = error.response.headers[
            "x-pimento-error-code"
          ] as string;

          message = `Error ${errorCodeValue}: ${error.response.data.detail}`;
          if (APIValidErrorCode.includesWiden(errorCodeValue)) {
            const errorCode = APIErrorCodeMap[errorCodeValue];
            // FIXME: we handle this very specific error here,
            // as an easy way to show the UpgradeModal in some scenarios.
            // But a better code would require:
            // 1. To handle errors at a higher level...
            // 2. ...and to send errors only for unexpected stuff, not for situations
            // that we could have prevented like this one.
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
            if (errorCode === "INSUFFICIENT_AMOUNT_OF_CREDITS") {
              setIsUpgradeDialogOpen(true);
              trackEvent("upgrade:display_popup", {
                reason: "insufficient_credits",
              });

              // Don't show error code in this specific scenario.
              message = `${error.response.data.detail}`;
            }
          }
        } else {
          message = "Unknown error";
        }
        notifier.error(message);
      },
      mutationFn: async (payload: object) => {
        const accessToken = await getAccessTokenSilently();
        const userIp = await getUserIp();
        return axios.post(`/api/${path}`, payload, {
          headers: {
            ...config?.headers,
            Authorization: `Bearer ${accessToken}`,
            "X-Client-Ip": userIp,
          },
          onUploadProgress: (progressEvent) =>
            setProgress(progressEvent.progress ?? 0),
        });
      },
    }),
  };
};
