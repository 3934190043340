import { useNavigate } from "react-router-dom";
import { useUser } from "../../../hooks/useUser.ts";
import { useAppMutation } from "../../../http/useAppMutation.ts";
import { STYLES } from "../../../routes.ts";
import { isUserAllowedTo } from "../../../types.ts";
import { trackEvent } from "../../../utils/trackEvent.ts";
import { useStyles } from "./useStyles.ts";

export const useCreateStyle = ({
  onStyleCreationForbidden,
}: {
  onStyleCreationForbidden?: () => void;
}) => {
  const { user } = useUser();
  const { remainingUserStyles } = useStyles();

  const { mutate, isLoading } = useAppMutation({
    path: "styles",
    invalidate: ["styles"],
    waitForInvalidation: false,
  }).mutation;

  const navigate = useNavigate();

  return {
    createStyle: () => {
      if (
        user &&
        (remainingUserStyles > 0 ||
          isUserAllowedTo(user, "styles:create-unlimited"))
      ) {
        mutate(
          { name: "" },
          {
            onSuccess: ({ data }) => {
              navigate(`${STYLES}/${data.uuid as string}`);
            },
          },
        );
      } else {
        onStyleCreationForbidden?.();
        trackEvent("upgrade:display_popup", {
          reason: "style_creation_blocked",
        });
      }
    },
    isCreateStyleLoading: isLoading,
  };
};
