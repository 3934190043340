import { useEffect, useState } from "react";
import { UrlAnimation } from "../../../../../../components/Animation/UrlAnimation.tsx";
import { LearnMoreLink } from "../../../../components/LearnMoreLink.tsx";
import type { Board } from "../../../../types.ts";
import { CollapsableSettingsSectionWrapper } from "../../../components/SettingsMenu/CollapsableSettingsSectionWrapper.tsx";
import { QualityPresetWrappedSection } from "../../../components/SettingsMenu/QualityPresetWrappedSection.tsx";
import {
  scalePromptStrength,
  unscalePromptStrength,
} from "../../../components/SettingsMenu/settingsScaleUtils.ts";
import { SettingsSectionWrapper } from "../../../components/SettingsMenu/SettingsSectionWrapper.tsx";
import { SliderSection } from "../../../components/SettingsMenu/SliderSection.tsx";
import { StyleIntensitySection } from "../../../components/SettingsMenu/StyleIntensitySection.tsx";
import { StyleWrappedSection } from "../../../components/SettingsMenu/StyleWrappedSection.tsx";
import { TextSection } from "../../../components/SettingsMenu/TextSection.tsx";
import { SettingsMenuLayout } from "../../../components/SettingsMenuLayout.tsx";
import { useVariationSettings } from "./hooks/useVariationSettings.ts";
import { VariationCreativityStrengthPresetSection } from "./VariationCreativityStrengthPresetSection.tsx";
import { VariationGenerationBar } from "./VariationGenerationBar.tsx";

export const VariationSettings = ({ board }: { board: Board }) => {
  const { variationSettings, setVariationSettings } = useVariationSettings(
    board.uuid,
  );
  const [advancedSectionOpen, setAdvancedSectionOpen] = useState(false);

  useEffect(() => {
    if (variationSettings.creativity_strength_preset === "custom") {
      setAdvancedSectionOpen(true);
    }
  }, [variationSettings.creativity_strength_preset]);

  return (
    <SettingsMenuLayout
      body={
        <div className="flex-col">
          <StyleWrappedSection board={board} />
          <SettingsSectionWrapper
            name="Creativity"
            content={<VariationCreativityStrengthPresetSection board={board} />}
            infoContent={
              <ul className="list-disc">
                <li>
                  Low Creativity is adapted for light modifications, results
                  will be close to your initial image.
                </li>
                <li>
                  High Creativity is adapted for strong modifications, results
                  can be very different from your initial image.
                </li>
              </ul>
            }
          />
          <QualityPresetWrappedSection
            value={variationSettings.quality_preset}
            onChange={(preset) =>
              setVariationSettings({ quality_preset: preset })
            }
          />
          <CollapsableSettingsSectionWrapper
            name="Advanced"
            open={advancedSectionOpen}
            onOpenChange={setAdvancedSectionOpen}
            content={
              <div className="flex-col">
                <StyleIntensitySection
                  loraScale={variationSettings.lora_scale}
                  onLoraScaleChange={(loraScale: number) =>
                    setVariationSettings({
                      lora_scale: loraScale,
                    })
                  }
                />
                <SliderSection
                  min={0}
                  max={100}
                  value={scalePromptStrength(variationSettings.prompt_strength)}
                  onChange={(promptStrengthPercentage) =>
                    setVariationSettings({
                      prompt_strength: unscalePromptStrength(
                        promptStrengthPercentage,
                      ),
                      creativity_strength_preset: "custom",
                    })
                  }
                  sliderName="Creativity"
                  sliderInformationSection={
                    <div className="flex-col gap-lg">
                      <div>
                        <span className="font-bold">Creativity</span> - Lower
                        values will lead to similar images. Higher values will
                        lead to more creative images.
                      </div>
                      <UrlAnimation url="https://storage.googleapis.com/419c45cf-be8a-4cba-bbcd-74a221eb2587/app/assets/f4520585-172c-481c-ac9e-f81768893251" />
                      <LearnMoreLink
                        url="https://pimento.crisp.help/en/article/use-an-image-as-reference-195wk5u/"
                        className="self-end"
                      />
                    </div>
                  }
                />
                <TextSection
                  title="Exclude"
                  valuePlaceholder="illustration, wonderful chilli pepper, vivid colors..."
                  value={variationSettings.negative_prompt}
                  onValueChange={(negativePrompt: string) =>
                    setVariationSettings({ negative_prompt: negativePrompt })
                  }
                />
              </div>
            }
          />
        </div>
      }
      footer={<VariationGenerationBar board={board} />}
    />
  );
};
