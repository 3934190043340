import { useNavigate } from "react-router-dom";
import { useAppMutation } from "../../../../../http/useAppMutation.ts";
import { POPULAR_TAG } from "../../../../Homepage/Style/constants.ts";
import { StylePicker } from "../../../../Homepage/Style/StylePicker.tsx";
import { useSelectedStyleUuid } from "../../../hooks/useSelectedStyleUuid.ts";
import type { Board } from "../../../types.ts";

export const StyleSelector = ({
  board,
  isOpen,
  onOpenChange,
  displayCloseButton,
  clickOutsideAuthorized,
}: {
  board: Board;
  isOpen: boolean;
  onOpenChange: (dialogChange: boolean) => void;
  displayCloseButton?: boolean;
  clickOutsideAuthorized?: boolean;
}) => {
  const { mutate: updateBoard } = useAppMutation({
    path: "boards/update",
    invalidate: [`boards/${board.uuid}`],
  }).mutation;
  const navigate = useNavigate();
  const { setSelectedStyleUuid } = useSelectedStyleUuid();

  return (
    <StylePicker
      dialogOpen={isOpen}
      onDialogChange={onOpenChange}
      onStyleSelect={(it) => {
        if (it.status !== "running") {
          navigate(`/styles/${it.uuid}`);
        } else {
          updateBoard({
            uuid: board.uuid,
            last_used_style_uuid: it.uuid,
          });
          setSelectedStyleUuid(it.uuid);
        }
        onOpenChange(false);
      }}
      displayCloseButton={displayCloseButton}
      clickOutsideAuthorized={clickOutsideAuthorized}
      initTag={POPULAR_TAG}
    />
  );
};
