import { useQuery } from "@tanstack/react-query";
import axios from "axios";

export const useNonAuthAppQuery = <T>(queryKey: string) =>
  useQuery<T>({
    queryKey: [queryKey],
    queryFn: async () => {
      const { data } = await axios.get(`/api/${queryKey}`, {});
      return data;
    },
    retry: false,
  });
