import type { QualityPreset } from "./types.ts";

export const FAVORITE_TAG_NAME = "__favorite__";

export const IMAGE_GENERATION_MODEL_TYPE = [
  "text_to_image",
  "image_to_image",
  "upscaler",
  "generative_fill",
  "style_transfer",
] as const;

export const SCHEDULERS = [
  "DDIM",
  "DPMSolverMultistep",
  "HeunDiscrete",
  "KarrasDPM",
  "K_EULER_ANCESTRAL",
  "K_EULER",
  "PNDM",
];

export const SDXL_REFINERS = [
  "no_refiner",
  "base_image_refiner",
  "expert_ensemble_refiner",
];

export const DIMENSIONS_RATIOS = {
  512: {
    "1:1": { width: 512, height: 512 },
    "1:2": { width: 704, height: 1408 },
    "2:1": { width: 1408, height: 704 },
  },
  768: {
    "1:1": { width: 768, height: 768 },
    "1:2": { width: 704, height: 1408 },
    "2:1": { width: 1408, height: 704 },
  },
  1024: {
    "1:1": { width: 1024, height: 1024 },
    "1:2": { width: 704, height: 1408 },
    "2:1": { width: 1408, height: 704 },
  },
} as const;

export const DIMENSIONS = [512, 768, 1024] as const;
export const RATIOS = ["1:1", "1:2", "2:1"] as const;

export const NODE_TYPES = [
  "add_color_keywords",
  "add_composition_keywords",
  "add_image_quality_keywords",
  "add_negative_prompt_color_keywords",
  "add_negative_prompt_style_keywords",
  "add_style_additional_elements_keywords",
  "add_style_keywords",
  "blacklist_style_keywords",
  "create_dreambooth_training",
  "describe_image",
  "generative_fill",
  "generative_fill_creativity_strength_preset",
  "variation_creativity_strength_preset",
  "generate_image",
  "generate_image_with_controlnet",
  "generate_suggestions",
  "keywords_to_prompt",
  "prompt_to_keywords",
  "retrieve_ml_model_weights",
  "split_uuid_list",
  "style_transfer_strength_preset",
  "upscale_imagination_strength_preset",
  "upscale_image",
] as const;

export const NODE_STATUS = [
  "running",
  "succeeded",
  "failed",
  "not_running",
] as const;

export const QUALITY_PRESETS: QualityPreset[] = ["fast", "high"];
