import classNames from "classnames";
import { useEffect } from "react";
import { ClickableIcon } from "../../../../../components/Icon/ClickableIcon.tsx";
import { DRAW_TOOL_PATH, SELECT_TOOL_PATH } from "../../../../../routes.ts";
import { useGetSelectedAsset } from "../../../hooks/useGetSelectedAsset.ts";
import type { Board } from "../../../types.ts";
import { useGetSelectedAssetInitImageUuid } from "../hooks/useGetSelectedAssetInitImageUuid.ts";
import { useImageViewMode } from "../hooks/useImageViewMode.ts";

export const ImageViewModeButtons = ({ board }: { board: Board }) => {
  const { viewMode, setViewMode } = useImageViewMode();

  const selectedAsset = useGetSelectedAsset(board);
  const selectedImageInitImageUuid = useGetSelectedAssetInitImageUuid(board);

  // XXX: We deactivate view mode in draw and select tool as not compatible with the current canvas implementation.
  const isViewModeToolCompatible = !(
    window.location.href.includes(DRAW_TOOL_PATH) ||
    window.location.href.includes(SELECT_TOOL_PATH)
  );

  const isSingleViewEnabled =
    isViewModeToolCompatible &&
    selectedAsset !== undefined &&
    selectedAsset.image.status === "succeeded";

  const isOverlapViewEnabled =
    isViewModeToolCompatible &&
    selectedAsset !== undefined &&
    selectedImageInitImageUuid !== undefined &&
    selectedAsset.image.status === "succeeded";

  // XXX: When changing selected image, we reset view mode to single if compare view disabled and activated.
  useEffect(() => {
    if (
      viewMode === "overlap" &&
      !isOverlapViewEnabled &&
      isSingleViewEnabled
    ) {
      setViewMode("single");
    }
  }, [isOverlapViewEnabled, isSingleViewEnabled, viewMode, setViewMode]);

  return (
    <div className="flex-row">
      <ClickableIcon
        name="Square"
        size={16}
        className="!opacity-100"
        iconClassName={classNames(
          !isSingleViewEnabled
            ? "stroke-gray-200"
            : viewMode === "single"
            ? "stroke-gray-600"
            : "stroke-gray-350",
        )}
        onClick={() => setViewMode("single")}
        isDisabled={!isSingleViewEnabled}
      />
      <ClickableIcon
        name="ImageSlider"
        className="!opacity-100"
        iconClassName={classNames(
          !isOverlapViewEnabled
            ? "fill-gray-200 stroke-gray-200"
            : viewMode === "overlap"
            ? "fill-gray-600 stroke-gray-600"
            : "fill-gray-350 stroke-gray-350",
        )}
        onClick={() => setViewMode("overlap")}
        isDisabled={!isOverlapViewEnabled}
      />
    </div>
  );
};
