import classNames from "classnames";
import styles from "./spinner.module.css";

export const Spinner = ({
  className,
  inline,
  small,
  currentColor,
}: {
  className?: string;
  inline?: boolean;
  small?: boolean;
  currentColor?: boolean;
}) => (
  <span
    role="status"
    className={classNames(styles["spinner"], className, {
      [styles["center"]]: !inline,
      [styles["small"]]: small,
      "text-black": !currentColor,
    })}
  />
);
