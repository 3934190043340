import { Icon } from "../../../../../components/Icon/Icon.tsx";
import { Image } from "../../../../../components/Image/Image.tsx";
import { LearnMoreLink } from "../../../components/LearnMoreLink.tsx";

export const ToolMenuTooltipContent = ({
  title,
  description,
  beforeImageUrl,
  afterImageUrl,
  learnMoreLink,
}: {
  title: string;
  description: string;
  beforeImageUrl: string;
  afterImageUrl: string;
  learnMoreLink: string;
}) => (
  <div className="flex-col gap-md">
    <div className="flex-row-center gap-4">
      <Image
        className="h-[120px] aspect-square"
        imageClassName="h-full w-full object-contain object-center"
        src={beforeImageUrl}
      />
      <Icon name="ArrowRight" size={24} className="stroke-white" />
      <Image
        className="h-[120px] aspect-square"
        imageClassName="h-full w-full object-contain object-center"
        src={afterImageUrl}
      />
    </div>
    <div className="flex-col gap-sm">
      <div className="flex-row items-center justify-between">
        <span className="text-lg font-semibold text-white">{title}</span>
        <LearnMoreLink url={learnMoreLink} variant="secondary" />
      </div>
      <span className="text-gray-350">{description}</span>
    </div>
  </div>
);
