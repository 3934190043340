import { createAtom } from "../../../atoms/createAtom.ts";

export const useIsUpgradeDialogOpen = createAtom(
  {
    isUpgradeDialogOpen: false as boolean,
  },
  ({ setter }) => ({
    setIsUpgradeDialogOpen: setter("isUpgradeDialogOpen"),
  }),
);
