export const Download = ({ className }: { className?: string }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 18 18"
    fill="none"
    className={className}
  >
    <path d="M17 11.6667V15.2223C17 15.6938 16.8127 16.146 16.4793 16.4794C16.1459 16.8128 15.6937 17.0001 15.2222 17.0001H2.77778C2.30628 17.0001 1.8541 16.8128 1.5207 16.4794C1.1873 16.146 1 15.6938 1 15.2223V11.6667" />
    <path d="M4.55566 7.22217L9.00011 11.6666L13.4446 7.22217" />
    <path d="M9 11.6667V1" />
  </svg>
);
