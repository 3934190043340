import { useEffect, useState } from "react";
import { useAppQuery } from "../../../../../../http/useAppQuery.ts";
import { DescriptionLoader } from "../../../../components/DescriptionLoader.tsx";
import { useSelectedStyleUuid } from "../../../../hooks/useSelectedStyleUuid.ts";
import type { Board, ImageContent } from "../../../../types.ts";
import { scrollCreateWorkspaceGridToTop } from "../../../AssetsWorkspace/utils/scrollCreateWorkspaceGridToTop.ts";
import { GenerationBar } from "../../../components/GenerationBar/GenerationBar.tsx";
import { useStyleTransferBaseImage } from "./hooks/useStyleTransferBaseImage.ts";
import { useStyleTransferGeneration } from "./hooks/useStyleTransferMutation.ts";

export const StyleTransferGenerationBar = ({ board }: { board: Board }) => {
  const [isWaitingForDescription, setIsWaitingForDescription] = useState(false);
  const { styleTransferBaseImageUuid } = useStyleTransferBaseImage();
  const { data: initImage } = useAppQuery<ImageContent>({
    queryKey: `contents/${styleTransferBaseImageUuid}`,
    enabled: !!styleTransferBaseImageUuid,
  });

  const [prompt, setPrompt] = useState(initImage?.description ?? "");

  useEffect(() => {
    setPrompt(initImage?.description ?? "");
  }, [initImage]);

  const { mutation: transferStyle, isLoading: isStyleTransferLoading } =
    useStyleTransferGeneration({
      board,
      onSuccess: scrollCreateWorkspaceGridToTop,
    });

  useEffect(() => {
    // XXX: we add prompt in the condition is case the image is never described
    if ((initImage === undefined || initImage.description) ?? prompt) {
      setIsWaitingForDescription(false);
    } else {
      setIsWaitingForDescription(true);
    }
  }, [initImage, prompt]);

  const { selectedStyleUuid } = useSelectedStyleUuid();

  return (
    <GenerationBar
      onGenerate={() => {
        if (initImage && selectedStyleUuid) {
          transferStyle({
            prompt,
            initImageUuid: initImage.uuid,
            styleUuid: selectedStyleUuid,
          });
        }
      }}
      isDeactivated={
        !initImage || initImage.status !== "succeeded" || !selectedStyleUuid
      }
      isLoading={isStyleTransferLoading || isWaitingForDescription}
      loadingContent={
        isWaitingForDescription ? <DescriptionLoader /> : undefined
      }
      prompt={prompt}
      setPrompt={setPrompt}
      sectionTitle="Image description"
      buttonContent="Transfer style"
      autoFocus
    />
  );
};
