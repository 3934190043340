import type { ReactNode } from "react";
import TextareaAutosize from "react-textarea-autosize";
import { Button } from "../../../../../components/Button/Button.tsx";
import { SettingsSectionWrapper } from "../SettingsMenu/SettingsSectionWrapper.tsx";

export const GenerationBar = ({
  prompt,
  setPrompt,
  isLoading,
  loadingContent,
  isDeactivated,
  onGenerate,
  infoContent,
  sectionTitle = "Prompt",
  buttonContent = "Generate",
  placeholder = "illustration, wonderful chilli pepper, sunbathing, vivid colors",
  blockEmptyPrompt = false,
  autoFocus = false,
}: {
  prompt: string;
  setPrompt: (prompt: string) => void;
  isLoading: boolean;
  loadingContent?: ReactNode;
  onGenerate: (prompt: string) => void;
  infoContent?: ReactNode;
  sectionTitle?: string;
  buttonContent?: string;
  isDeactivated?: boolean;
  placeholder?: string;
  blockEmptyPrompt?: boolean;
  autoFocus?: boolean;
}) => {
  const generationDeactivated =
    (blockEmptyPrompt && !prompt) || isLoading || isDeactivated;
  return (
    <SettingsSectionWrapper
      name={sectionTitle}
      infoContent={infoContent}
      className="border-t border-b-0 pb-lg"
      content={
        <div className="w-full flex-col-center gap-md">
          <TextareaAutosize
            placeholder={placeholder}
            minRows={2}
            className="w-full flex-fill px-sm resize-none border rounded-sm p-md"
            value={prompt}
            onChange={(event) => {
              setPrompt(event.target.value);
            }}
            autoFocus={autoFocus}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();
                if (!generationDeactivated) onGenerate(prompt);
              }
              e.stopPropagation();
            }}
          />
          <Button
            className="w-full"
            disabled={generationDeactivated}
            onClick={() => {
              onGenerate(prompt);
            }}
            loading={isLoading}
            loadingContent={loadingContent}
          >
            {buttonContent}
          </Button>
        </div>
      }
    />
  );
};
