import { fabric } from "fabric";
import { useEffect, useLayoutEffect, useState } from "react";
import { useGetSelectedAsset } from "../../../../hooks/useGetSelectedAsset.ts";
import { useSelectedStyleUuid } from "../../../../hooks/useSelectedStyleUuid.ts";
import type { Board } from "../../../../types.ts";
import { GenerationBar } from "../../../components/GenerationBar/GenerationBar.tsx";
import { isEmpty } from "../../components/BaseEditor/utils.ts";
import { generateLassosMask } from "../../components/MaskEditor/utils.ts";
import { scrollImageStripeToStart } from "../../ImageStripe/hooks/scrollImageStripeToStart.ts";
import { useGenerativeFillPrompt } from "./hooks/useGenerativeFillPrompt.ts";
import { useUploadAndGenerativeFillMutation } from "./hooks/useUploadAndGenerativeFillMutation.ts";

export const GenerativeFillGenerationBar = ({
  board,
  fabricCanvas,
}: {
  board: Board;
  fabricCanvas?: fabric.Canvas;
}) => {
  const { prompt, setPrompt } = useGenerativeFillPrompt();

  const selectedAsset = useGetSelectedAsset(board);

  const [originalSize, setOriginalSize] = useState<{
    width: number;
    height: number;
  }>();

  useLayoutEffect(() => {
    const img = new Image();
    if (!selectedAsset) return;
    img.src = selectedAsset.image.url;
    img.onload = () => {
      setOriginalSize({ width: img.width, height: img.height });
    };
  }, [selectedAsset]);

  const [isCanvasEmpty, setIsCanvasEmpty] = useState<boolean>(true);

  useEffect(() => {
    if (fabricCanvas) {
      const onAdd = () => setIsCanvasEmpty(false);
      const onRemove = () => setIsCanvasEmpty(isEmpty(fabricCanvas));

      fabricCanvas.on("object:added", onAdd);
      fabricCanvas.on("object:removed", onRemove);
      return () => {
        fabricCanvas.off("object:added", onAdd);
        fabricCanvas.off("object:removed", onRemove);
      };
    }
  }, [fabricCanvas]);

  const { mutate: inpaintingMutate, isLoading: inpaintingIsLoading } =
    useUploadAndGenerativeFillMutation({
      boardUuid: board.uuid,
      onSuccess: () => {
        scrollImageStripeToStart();
      },
    });

  const { selectedStyleUuid } = useSelectedStyleUuid();

  return (
    <GenerationBar
      prompt={prompt}
      setPrompt={setPrompt}
      blockEmptyPrompt
      sectionTitle="Replace by"
      buttonContent="Replace"
      placeholder="a chilli pepper with sunglasses"
      isDeactivated={
        !selectedAsset ||
        selectedAsset.image.status !== "succeeded" ||
        isCanvasEmpty ||
        !selectedStyleUuid
      }
      isLoading={inpaintingIsLoading}
      onGenerate={async () => {
        if (
          originalSize !== undefined &&
          selectedAsset &&
          fabricCanvas &&
          selectedStyleUuid
        ) {
          const maskBlob = await generateLassosMask({ fabricCanvas });
          inpaintingMutate({
            mask: maskBlob,
            prompt,
            assetUuid: selectedAsset.uuid,
            styleUuid: selectedStyleUuid,
          });
        }
      }}
    />
  );
};
