import { useAppMutation } from "../../../http/useAppMutation.ts";

export const useDescribeImage = () => {
  const {
    mutation: { mutate, isLoading },
  } = useAppMutation({
    path: "contents/describe",
    invalidate: [],
  });

  return {
    mutate: (uuid: string) => mutate({ uuids: [uuid] }),
    isLoading,
  };
};
