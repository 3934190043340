import classNames from "classnames";
import type { ReactNode } from "react";
import type { Board } from "../../../types.ts";
import { ActionsBar } from "../ActionsBar/ActionsBar.tsx";
import { GenerationResultOptions } from "./GenerationResultOptions/GenerationResultOptions.tsx";

export const EditToolViewLayout = ({
  settingsView,
  contentView,
  toolBarContent,
  board,
}: {
  settingsView: ReactNode;
  contentView: ReactNode;
  toolBarContent?: ReactNode;
  board: Board;
}) => (
  <div className="flex-fill flex-row">
    {settingsView}
    <div className="flex-fill flex-col gap-2xl">
      <div
        className={classNames(
          "w-full h-[48px] flex-row-center px-md",
          toolBarContent !== undefined ? "bg-white border-r border-b" : "",
        )}
      >
        {toolBarContent}
      </div>
      <div className="pl-[168px] pr-xl flex-fill flex-row gap-xl">
        {/* FIXME: Instead of defining a content view per tool, we should use a single component `ImageContainer` for all
           edit tools instead of redefining different ones per tools. To do so:
            - Merge canvas in draw / fill tool
            - Unify the use of canvas for all edit tools
            This will remove all flash when switching between tools */}
        <div className="flex-fill flex-col-center">{contentView}</div>
        <div className="h-full w-[120px]">
          <GenerationResultOptions board={board} />
        </div>
      </div>
      <ActionsBar board={board} />
    </div>
  </div>
);
