import { fabric } from "fabric";
import { useEffect, useState } from "react";
import { useGetSelectedAsset } from "../../../../hooks/useGetSelectedAsset.ts";
import { useSelectedStyleUuid } from "../../../../hooks/useSelectedStyleUuid.ts";
import type { Board } from "../../../../types.ts";
import { GenerationBar } from "../../../components/GenerationBar/GenerationBar.tsx";
import { isEmpty } from "../../components/BaseEditor/utils.ts";
import { generateObjectsMask } from "../../components/ImageEditor/utils.tsx";
import { scrollImageStripeToStart } from "../../ImageStripe/hooks/scrollImageStripeToStart.ts";
import { useMagicDrawPrompt } from "./hooks/useMagicDrawPrompt.ts";
import { useUploadAndGenerateMagicDraw } from "./hooks/useUploadAndGenerateMagicDraw.ts";

export const MagicDrawGenerationBar = ({
  board,
  fabricCanvas,
}: {
  board: Board;
  fabricCanvas?: fabric.Canvas;
}) => {
  const { prompt, setPrompt } = useMagicDrawPrompt();
  const [isGenerating, setIsGenerating] = useState(false);

  const selectedAsset = useGetSelectedAsset(board);

  const [initImageSize, setInitImageSize] = useState<{
    width: number;
    height: number;
  }>();

  useEffect(() => {
    if (selectedAsset) {
      const img = new Image();
      img.src = selectedAsset.image.url;
      img.onload = () => {
        setInitImageSize({ width: img.width, height: img.height });
      };
    }
  }, [selectedAsset]);

  const [isCanvasEmpty, setIsCanvasEmpty] = useState<boolean>(true);

  useEffect(() => {
    if (fabricCanvas) {
      const onAdd = () => setIsCanvasEmpty(false);
      const onRemove = () => setIsCanvasEmpty(isEmpty(fabricCanvas));

      fabricCanvas.on("object:added", onAdd);
      fabricCanvas.on("object:removed", onRemove);
      return () => {
        fabricCanvas.off("object:added", onAdd);
        fabricCanvas.off("object:removed", onRemove);
      };
    }
  }, [fabricCanvas]);

  const { mutate } = useUploadAndGenerateMagicDraw({
    boardUuid: board.uuid,
    onSuccess: () => {
      setIsGenerating(false);
      scrollImageStripeToStart();
    },
  });

  const { selectedStyleUuid } = useSelectedStyleUuid();

  return (
    <GenerationBar
      prompt={prompt}
      setPrompt={setPrompt}
      isLoading={isGenerating}
      isDeactivated={isCanvasEmpty || !selectedAsset || !selectedStyleUuid}
      placeholder="Describe what to generate in the drawn zone"
      blockEmptyPrompt
      onGenerate={async (userPrompt) => {
        setIsGenerating(true);
        if (
          fabricCanvas &&
          initImageSize &&
          selectedStyleUuid &&
          selectedAsset
        ) {
          const imageURL = fabricCanvas.toDataURL({
            format: "jpeg",
            multiplier: initImageSize.height / (fabricCanvas.height ?? 1),
          });
          const imageBlob = await (await fetch(imageURL)).blob();

          const maskBlob = await generateObjectsMask({ fabricCanvas });

          mutate({
            image: imageBlob,
            mask: maskBlob,
            prompt: userPrompt,
            height: initImageSize.height,
            width: initImageSize.width,
            styleUuid: selectedStyleUuid,
            assetUuid: selectedAsset.uuid,
          });
        }
      }}
    />
  );
};
