import classNames from "classnames";
import { useState } from "react";
import { Link } from "react-router-dom";
import { EditableText } from "../../../components/EditableText/EditableText.tsx";
import { PimentoStar } from "../../../components/Icon/assets/PimentoStar.tsx";
import { ClickableIcon } from "../../../components/Icon/ClickableIcon.tsx";
import { Icon } from "../../../components/Icon/Icon.tsx";
import { Popover } from "../../../components/Popover/Popover.tsx";
import { useUser } from "../../../hooks/useUser.ts";
import { useAppMutation } from "../../../http/useAppMutation.ts";
import { useIsUpgradeDialogOpen } from "../../../singletons/UpgradeDialog/hooks/useIsUpgradeDialogOpen.ts";
import { isUserAllowedTo } from "../../../types.ts";
import { trackEvent } from "../../../utils/trackEvent.ts";
import { useShowDeletedAssets } from "../hooks/useShowDeletedAssets.ts";
import type { Board } from "../types.ts";
import { WorkspaceMenu } from "./WorkspaceMenu.tsx";

export const Header = ({ board }: { board: Board }) => {
  const { user } = useUser();

  const { mutate: nameMutate, isLoading: nameIsLoading } = useAppMutation({
    path: "boards/update",
    invalidate: ["boards", `boards/${board.uuid}`],
  }).mutation;

  return (
    <div
      className={classNames(
        "flex-row w-full h-[48px] justify-between items-center bg-white shadow-sm border pr-lg",
      )}
    >
      {/* FIXME:  Use NavMenuButton for the home link once the custom icons can be used with the Icon component */}
      <div className="flex-row h-full items-center">
        <Link to="/" className="group h-full w-3xl flex-col-center">
          <PimentoStar
            size={13}
            className="fill-black group-hover:stroke-pimento-blue group-hover:fill-pimento-blue"
          />
        </Link>
        <EditableText
          text={board.name}
          onTextChange={(name) => nameMutate({ uuid: board.uuid, name })}
          className="max-w-[300px] flex items-center"
          textClassName="text-lg font-semibold"
          inputClassName="text-lg font-semibold"
          isLoading={nameIsLoading}
        />
      </div>
      <WorkspaceMenu />
      <div className="flex-row items-center">
        {user && isUserAllowedTo(user, "mode:debug") && (
          <ShowDeletedAssetsButton />
        )}
        <CreditsCounter />
        <a
          href="https://pimento.crisp.help/en/"
          target="_blank"
          className="p-md aspect-square flex-row-center rounded-sm hover:bg-gray-200"
        >
          <Icon
            name="HelpCircle"
            size={16}
            tooltip={{ content: "Help", side: "bottom" }}
          />
        </a>
      </div>
    </div>
  );
};

const CreditsCounter = () => {
  const { user } = useUser();
  const { setIsUpgradeDialogOpen } = useIsUpgradeDialogOpen();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  if (!user) return null;

  return (
    <Popover
      isOpen={isMenuOpen}
      onOpenChange={setIsMenuOpen}
      className="!bg-gray-600"
      align="end"
      content={
        <div className="flex-row gap-lg p-md items-center">
          <div className="flex-col-center h-3xl aspect-square bg-pimento-blue rounded-full">
            <Icon
              size={20}
              name="PimentoStar"
              className="fill-white stroke-0"
            />
          </div>
          <div className="flex-col gap-sm">
            <div className="text-lg text-white font-bold">
              {user.total_credits_remaining_amount} credits left
            </div>
            {user.stripe_subscription_type === "free" && (
              <button
                className="text-pimento-blue text-lg font-bold underline"
                onClick={() => {
                  trackEvent("upgrade:display_popup", {
                    reason: "remaining_credits_click",
                  });
                  setIsMenuOpen(false);
                  setIsUpgradeDialogOpen(true);
                }}
              >
                Upgrade now!
              </button>
            )}
          </div>
        </div>
      }
    >
      <button
        onClick={() => setIsMenuOpen(true)}
        className={classNames(
          "flex-row gap-md p-md rounded-sm items-center",
          isMenuOpen ? "bg-gray-300" : "hover:bg-gray-200",
        )}
      >
        <div className="flex-col-center h-xl aspect-square bg-pimento-blue rounded-full">
          <Icon size={12} name="PimentoStar" className="fill-white stroke-0" />
        </div>
        {user.total_credits_remaining_amount}
      </button>
    </Popover>
  );
};

const ShowDeletedAssetsButton = () => {
  const { showDeletedAssets, toggleShowDeletedAssets } = useShowDeletedAssets();
  return (
    <ClickableIcon
      name="Trash"
      size={14}
      className={classNames(
        "p-sm rounded-sm mb-[2px]",
        showDeletedAssets ? "bg-pimento-red" : "",
      )}
      iconClassName={classNames(
        "stroke-xl",
        showDeletedAssets ? "stroke-white" : "",
      )}
      onClick={() => toggleShowDeletedAssets()}
    />
  );
};
