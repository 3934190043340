import type { Board } from "../../../../types.ts";
import { EditToolViewLayout } from "../../components/EditToolViewLayout.tsx";
import { ImageContainer } from "../../components/ImageContainer/ImageContainer.tsx";
import { UpscaleSettings } from "./UpscaleSettings.tsx";

export const UpscaleView = ({ board }: { board: Board }) => (
  <EditToolViewLayout
    settingsView={<UpscaleSettings board={board} />}
    contentView={<ImageContainer board={board} />}
    board={board}
  />
);
