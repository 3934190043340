import { Icon, type IconName } from "../../../../components/Icon/Icon.tsx";

export const EmptyStateInformation = ({
  iconName,
  iconClassName,
  title,
  body,
}: {
  iconName: IconName;
  iconClassName: string;
  title: string;
  body: string;
}) => (
  <div className="flex-col-center flex-fill h-4/5 gap-2xl">
    <Icon size={50} name={iconName} className={iconClassName} />
    <div className="flex-col-center">
      <div className="font-burg text-4xl">{title}</div>
      <span className="text-lg">{body}</span>
    </div>
  </div>
);
