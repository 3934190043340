import classNames from "classnames";
import { useState } from "react";
import { Link } from "react-router-dom";
import { Button } from "../../../components/Button/Button.tsx";
import { Icon } from "../../../components/Icon/Icon.tsx";
import { useAppMutation } from "../../../http/useAppMutation.ts";
import { HOME } from "../../../routes.ts";
import type { Style } from "../../types.ts";
import { StylePreview } from "../components/StylePreview.tsx";

export const StyleNameForm = ({
  widthClassName,
  style,
  onContinue,
}: {
  widthClassName?: string;
  style: Style;
  onContinue: () => void;
}) => {
  const [styleName, setStyleName] = useState<string>(style.name ?? "");

  const { mutate: renameStyle } = useAppMutation({
    path: "styles/update",
    invalidate: ["styles", `styles/${style.uuid}`],
  }).mutation;

  return (
    <div className="relative flex-col-center w-full flex-fill overflow-y-auto">
      <Link
        to={HOME}
        className="absolute top-16 left-16 rounded-sm border-[1px] border-gray-300 bg-white text-gray-600"
      >
        <Icon
          name="X"
          size={32}
          className="p-8 rounded-sm bg-white text-gray-600 hover:bg-gray-200"
        />
      </Link>
      <div
        className={classNames(
          "flex-col flex-fill pt-[80px] gap-3xl items-center",
          widthClassName,
        )}
      >
        <StylePreview style={style} title="give your style a name" />
        <input
          type="text"
          className="w-full h-3xl py-md px-lg rounded-sm border placeholder:text-gray-300 placeholder:italic"
          placeholder="My brand illustrations"
          value={styleName}
          onChange={(event) => {
            setStyleName(event.target.value);
          }}
        />
        <Button
          className="w-full"
          disabled={styleName.isEmpty()}
          onClick={() => {
            renameStyle({
              uuid: style.uuid,
              name: styleName,
            });
            onContinue();
          }}
        >
          {styleName.isEmpty() ? "Name your style" : "Next"}
        </Button>
      </div>
    </div>
  );
};
