export const scalePromptStrength = (value: number) =>
  Math.round(((Math.max(value, 0.5) - 0.5) / 0.5) * 100);
export const unscalePromptStrength = (scaledValue: number) =>
  0.5 + (scaledValue / 100) * 0.5;

export const scaleControlStrength = (value: number) =>
  Math.round((Math.min(value, 0.8) / 0.8) * 100);
export const unscaleControlStrength = (scaledValue: number) =>
  (scaledValue / 100) * 0.8;

export const scaleControlEnd = (value: number) =>
  Math.round(((Math.max(value, 0.5) - 0.5) / 0.5) * 100);

// FIXME: move scaling in the backend. It can be handle in pipelines
export const unscaleControlEnd = (scaledValue: number) =>
  (scaledValue / 100) * 0.5 + 0.5;

export const promptStrengthToInputColorPercentage = (promptStrength: number) =>
  100 - scalePromptStrength(promptStrength);

export const inputColorPercentageToPromptStrength = (
  inputColorPercentage: number,
) => unscalePromptStrength(100 - inputColorPercentage);

export const controlEndToCreativity = (controlEnd: number) =>
  100 - scaleControlEnd(controlEnd);

export const creativityToControlEnd = (creativity: number) =>
  unscaleControlEnd(100 - creativity);
