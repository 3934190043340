import { useAppMutation } from "../../../http/useAppMutation.ts";
import { DeletionDialog } from "../components/DeletionDialog.tsx";
import type { Style } from "../types.ts";

export const StyleDeletionDialog = ({
  style,
  dialogOpen,
  onDialogChange,
  onCancel,
}: {
  style: Style;
  dialogOpen: boolean;
  onDialogChange: (dialogChange: boolean) => void;
  onCancel: () => void;
}) => {
  const deleteStyleMutation = useAppMutation({
    path: "styles/delete",
    invalidate: ["styles"],
  }).mutation;

  return (
    <DeletionDialog
      onDialogChange={onDialogChange}
      dialogOpen={dialogOpen}
      isLoading={deleteStyleMutation.isLoading}
      onClick={() => {
        deleteStyleMutation.mutate({
          uuid: style.uuid,
        });
      }}
      onCancel={onCancel}
    >
      <div className="flex-col-center gap-3xl">
        <div className="flex-col-center gap-xs">
          <span className="text-2xl font-bold">Delete style</span>
          <span className="text-2xl">
            {style.name !== "" ? style.name : "Untitled"}
          </span>
        </div>
        {/* FIXME: Improve tailwind classes by using gap instead of explicit margin */}
        <div className="mt-8 mb-52 text-base">
          This style will not be accessible anymore.
        </div>
      </div>
    </DeletionDialog>
  );
};
