import classNames from "classnames";
import type { ReactNode } from "react";
import { ClickableIcon } from "../../../components/Icon/ClickableIcon.tsx";
import { Popover } from "../../../components/Popover/Popover.tsx";

export const ExtraMenu = ({
  isOpen,
  onOpenChange,
  dialog,
  popoverContent,
  className,
}: {
  isOpen: boolean;
  onOpenChange: (open: boolean) => void;
  dialog: ReactNode;
  popoverContent: ReactNode;
  className: string;
}) => (
  <div className={classNames(isOpen ? "visible" : className)}>
    {dialog}
    <Popover
      isOpen={isOpen}
      onOpenChange={(open) => {
        onOpenChange(open);
      }}
      align="center"
      side="right"
      className="z-30 m-2 p-md flex-auto rounded-sm border-none flex-row-center text-xl font-medium max-w-[280px]"
      content={popoverContent}
    >
      <ClickableIcon
        name="MoreHorizontal"
        className="tile-action"
        iconClassName="stroke-xl"
        rotate={90}
      />
    </Popover>
  </div>
);
