import classNames from "classnames";
import { ClickableIcon } from "../../../../../components/Icon/ClickableIcon.tsx";
import { RATIOS } from "../../../constants.ts";
import type { Ratio } from "../../../types.ts";

export const RatioSection = ({
  ratio,
  onRatioChange,
}: {
  ratio: Ratio;
  onRatioChange: (ratio: Ratio) => void;
}) => (
  <div className="flex-fill h-3xl flex-row items-center justify-between">
    <div>Ratio</div>
    <div className="flex-row items-center gap-sm">
      {RATIOS.map((ratioValue) => (
        <ClickableIcon
          key={ratioValue}
          className={classNames(
            "rounded-xs h-xl aspect-square",
            ratioValue === ratio
              ? "bg-gray-300 cursor-auto"
              : "hover:bg-gray-200",
          )}
          name={
            ratioValue === "1:1"
              ? "Square"
              : ratioValue === "2:1"
              ? "RectangleHorizontal"
              : "RectangleVertical"
          }
          size={14}
          iconClassName="stroke-lg"
          onClick={() => onRatioChange(ratioValue)}
        />
      ))}
    </div>
  </div>
);
