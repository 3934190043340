import { fabric } from "fabric";
import type { Board } from "../../../../types.ts";
import { CollapsableSettingsSectionWrapper } from "../../../components/SettingsMenu/CollapsableSettingsSectionWrapper.tsx";
import {
  scalePromptStrength,
  unscalePromptStrength,
} from "../../../components/SettingsMenu/settingsScaleUtils.ts";
import { SliderSection } from "../../../components/SettingsMenu/SliderSection.tsx";
import { StyleIntensitySection } from "../../../components/SettingsMenu/StyleIntensitySection.tsx";
import { StyleWrappedSection } from "../../../components/SettingsMenu/StyleWrappedSection.tsx";
import { TextSection } from "../../../components/SettingsMenu/TextSection.tsx";
import { SettingsMenuLayout } from "../../../components/SettingsMenuLayout.tsx";
import {
  MAGIC_DRAW_DEFAULT_SETTINGS,
  useMagicDrawSettings,
} from "./hooks/useMagicDrawSettings.ts";
import { MagicDrawGenerationBar } from "./MagicDrawGenerationBar.tsx";

export const MagicDrawSettings = ({
  board,
  fabricCanvas,
}: {
  board: Board;
  fabricCanvas?: fabric.Canvas;
}) => {
  const { magicDrawSettings, setMagicDrawSettings } = useMagicDrawSettings(
    board.uuid,
  );

  return (
    <SettingsMenuLayout
      body={
        <div className="flex-col">
          <StyleWrappedSection board={board} />
          <CollapsableSettingsSectionWrapper
            name="Advanced"
            content={
              <div className="flex-col">
                <StyleIntensitySection
                  loraScale={magicDrawSettings.lora_scale}
                  onLoraScaleChange={(loraScale) =>
                    setMagicDrawSettings({
                      lora_scale: loraScale,
                    })
                  }
                  defaultLoraScale={MAGIC_DRAW_DEFAULT_SETTINGS.lora_scale}
                />
                <SliderSection
                  min={0}
                  max={100}
                  defaultValue={scalePromptStrength(
                    MAGIC_DRAW_DEFAULT_SETTINGS.prompt_strength,
                  )}
                  // XXX: If prompt strength is in between 0 and 0.5, the results are the same (really close to the initial image)
                  // we want to make prompt_strength to vary from 0.5 to 1 only but display a creativity param between 0 and 100
                  value={scalePromptStrength(magicDrawSettings.prompt_strength)}
                  onChange={(promptStrengthPercentage) =>
                    setMagicDrawSettings({
                      prompt_strength: unscalePromptStrength(
                        promptStrengthPercentage,
                      ),
                    })
                  }
                  sliderName="Creativity"
                  sliderInformationSection={
                    <div>
                      <span className="font-bold">Creativity</span> - Lower
                      values will lead to similar images. Higher values will
                      lead to more creative images.
                    </div>
                  }
                />
                <TextSection
                  title="Exclude"
                  valuePlaceholder="illustration, wonderful chilli pepper, vivid colors..."
                  value={magicDrawSettings.negative_prompt}
                  onValueChange={(negativePrompt: string) =>
                    setMagicDrawSettings({ negative_prompt: negativePrompt })
                  }
                />
              </div>
            }
          />
        </div>
      }
      footer={
        <MagicDrawGenerationBar board={board} fabricCanvas={fabricCanvas} />
      }
    />
  );
};
