import type { AxiosResponse } from "axios";
import { useAppMutation } from "../../../http/useAppMutation.ts";
import type { Board } from "../types.ts";
import { useSelectedAssetUuid } from "./useSelectedAssetUuid.ts";

export const useDeleteAssetOnBoard = (board: Board) => {
  const { selectedAssetUuid, clearSelectedAssetUuid, setSelectedAssetUuid } =
    useSelectedAssetUuid();

  return useAppMutation({
    path: "assets/delete",
    invalidate: [`boards/${board.uuid}`],
    onSuccess: (
      response: AxiosResponse<{
        uuids: string[];
      }>,
    ) => {
      // XXX: We automatically select the closest next image if the deleted image was selected only if there are more
      // than one image in the exploration tag
      if (
        selectedAssetUuid &&
        response.data.uuids.includes(selectedAssetUuid) &&
        board.assets.length === 0
      ) {
        clearSelectedAssetUuid();
      } else if (
        selectedAssetUuid &&
        response.data.uuids.includes(selectedAssetUuid) &&
        board.assets.length > 1
      ) {
        const selectedAssetIndex = board.assets.findIndex(
          (asset) => asset.uuid === selectedAssetUuid,
        );
        selectedAssetIndex === board.assets.length - 1
          ? setSelectedAssetUuid(board.assets[selectedAssetIndex - 1].uuid)
          : setSelectedAssetUuid(board.assets[selectedAssetIndex + 1].uuid);
      }
    },
  }).mutation;
};
