import { useEffect } from "react";
import { Navigate, Route } from "react-router";
import { Routes } from "react-router-dom";
import {
  ASSETS_WORKSPACE,
  CREATE_WORKSPACE,
  EDIT_WORKSPACE,
} from "../../routes.ts";
import { Header } from "./Header/Header.tsx";
import { useSelectedStyleUuid } from "./hooks/useSelectedStyleUuid.ts";
import { useTagsFilter } from "./hooks/useTagsFilter.ts";
import { StyleImageModal } from "./ImageModal/StyleImageModal.tsx";
import type { Board } from "./types.ts";
import { AssetsWorkspace } from "./Workspaces/AssetsWorkspace/AssetsWorkspace.tsx";
import { CreateWorkspace } from "./Workspaces/CreateWorkspace/CreateWorkspace.tsx";
import { useGeneratePrompt } from "./Workspaces/CreateWorkspace/Tools/GenerateView/hooks/useGeneratePrompt.ts";
import { useStyleTransferBaseImage } from "./Workspaces/CreateWorkspace/Tools/StyleTransferView/hooks/useStyleTransferBaseImage.ts";
import { EditWorkspace } from "./Workspaces/EditWorkspace/EditWorkspace.tsx";
import { useGenerativeFillPrompt } from "./Workspaces/EditWorkspace/Tools/GenerativeFillView/hooks/useGenerativeFillPrompt.ts";
import { useMagicDrawPrompt } from "./Workspaces/EditWorkspace/Tools/MagicDrawView/hooks/useMagicDrawPrompt.ts";

export const BoardContent = ({ board }: { board: Board }) => {
  useEffect(() => {
    useSelectedStyleUuid
      .getState()
      .setSelectedStyleUuid(board.last_used_style_uuid);
    useTagsFilter.getState().resetTagsFilter();
    useGeneratePrompt.getState().resetPrompt();
    useMagicDrawPrompt.getState().resetPrompt();
    useGenerativeFillPrompt.getState().resetPrompt();
    useStyleTransferBaseImage.getState().resetStyleTransferBaseImage();
    // XXX: On component unmount, we clear all the states to avoid any deprecated states when switching between boards,
    // such as useless query on previous selected style.
    return () => {
      useSelectedStyleUuid.getState().clearSelectedStyleUuid();
      useTagsFilter.getState().resetTagsFilter();
      useGeneratePrompt.getState().resetPrompt();
      useMagicDrawPrompt.getState().resetPrompt();
      useGenerativeFillPrompt.getState().resetPrompt();
      useStyleTransferBaseImage.getState().resetStyleTransferBaseImage();
    };
    //   eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="flex-fill flex-col bg-background">
      <Header board={board} />
      <div className="relative flex-fill flex-row">
        <StyleImageModal />
        <Routes>
          <Route
            path={`${CREATE_WORKSPACE}/*`}
            element={<CreateWorkspace board={board} />}
          />
          <Route
            path={`${EDIT_WORKSPACE}/*`}
            element={<EditWorkspace board={board} />}
          />
          <Route
            path={ASSETS_WORKSPACE}
            element={<AssetsWorkspace board={board} />}
          />
          <Route
            path="*"
            element={<Navigate replace to={CREATE_WORKSPACE} />}
          />
        </Routes>
      </div>
    </div>
  );
};
