export const APP_INITIAL_STATES = [
  "draw",
  "editSelection",
  "generateFromText",
  "getVariations",
  "transferStyle",
  "upscaleAndEnhance",
] as const;

export const STYLE_IMAGES_KIND = [
  "photography",
  "illustration_2d",
  "graphic_3d",
  "icon",
  "other",
];

export const STYLE_COLOR_KIND = [
  "black_and_white",
  "monochrome",
  "bland",
  "regular",
  "bright",
  "other",
];
