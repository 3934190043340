import classNames from "classnames";
import { useParams } from "react-router";
import styles from "../../components/Button/button.module.css";
import { Icon } from "../../components/Icon/Icon.tsx";
import { Image } from "../../components/Image/Image.tsx";
import { NonAuthQuery } from "../../components/Query/NonAuthQuery.tsx";
import type { SharedAsset } from "./types.ts";

export const SharedAssetView = () => {
  const assetUuid = useParams()["uuid"]!;

  return (
    <NonAuthQuery queryKey={`shared/assets/${assetUuid}`}>
      {(asset: SharedAsset) => (
        <div className="relative flex-col h-full items-center">
          <div className="absolute top-[44px] flex-row-center w-full">
            <a
              href={window.location.origin}
              target="_self"
              className="flex-row-center"
            >
              <Icon
                name="Pimento"
                size={130}
                className="absolute left-16 lg:left-44"
              />
            </a>
            <div className="absolute right-16 lg:sticky h-[53px] flex-row items-center rounded-sm">
              <a
                href="https://www.pimento.design"
                target="_blank"
                className={classNames(styles["base"], styles["small"])}
              >
                Join Pimento
              </a>
            </div>
          </div>
          <div className="flex-row-center items-center aspect-square h-full px-[100px] pt-[140px] pb-[100px]">
            <SharedAssetTile
              asset={asset}
              className="rounded-sm w-[275px] h-[275px] lg:h-full lg:w-full border-[1px] leading-7 shadow-sm"
            />
          </div>
        </div>
      )}
    </NonAuthQuery>
  );
};

const SharedAssetTile = ({
  asset,
  className,
}: {
  asset: SharedAsset;
  className: string;
}) => (
  <Image
    className={classNames(className, "aspect-square ")}
    imageClassName="h-full w-full object-cover object-center"
    src={asset.url}
  />
);
