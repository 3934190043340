import { createAtom } from "../../../../../../../atoms/createAtom.ts";

export const useStyleTransferBaseImage = createAtom(
  {
    styleTransferBaseImageUuid: undefined as string | undefined,
  },
  ({ set }) => ({
    setStyleTransferBaseImage: ({
      styleTransferBaseImageUuid,
    }: {
      styleTransferBaseImageUuid: string | undefined;
    }) =>
      set({
        styleTransferBaseImageUuid,
      }),
    resetStyleTransferBaseImage: () => {
      set({
        styleTransferBaseImageUuid: undefined,
      });
    },
  }),
);
