import { useMemo } from "react";
import { AssetImageTile } from "../../components/AssetImageTile/AssetImageTile.tsx";
import { FAVORITE_TAG_NAME } from "../../constants.ts";
import { useTagsFilter } from "../../hooks/useTagsFilter.ts";
import type { Board } from "../../types.ts";
import { EmptyStateInformation } from "../components/EmptyStateInformation.tsx";
import { TagsFilterBar } from "./TagsFilterBar.tsx";
import { filterAssetsByTags } from "./utils/tagFiltering.ts";
import { ZoomableGridWrapper, ZoomControl } from "./ZoomControl.tsx";

export const AssetsWorkspace = ({ board }: { board: Board }) => {
  const { selectedTagsFilter } = useTagsFilter();

  const filteredAssets = useMemo(
    () =>
      filterAssetsByTags({
        assets: board.assets,
        filterTags: selectedTagsFilter,
      }),
    [board.assets, selectedTagsFilter],
  );

  return (
    <div className="pt-2xl relative flex-fill flex-col w-full gap-sm">
      <div className="flex-row justify-between pr-xl">
        <TagsFilterBar tagsFilter={board.tags} boardUuid={board.uuid} />
      </div>
      {filteredAssets.length ? (
        <div className="px-xl pt-md pb-2xl flex-fill flex-col w-full overflow-auto">
          {/* FIXME: Once the zoom is removed, we can use a normal grid */}
          <ZoomableGridWrapper boardUuid={board.uuid}>
            {filteredAssets.map((asset) => (
              // FIXME: Create a specific tile component for the Assets workspace
              <AssetImageTile
                key={asset.uuid}
                innerClassName="w-full h-full"
                className="shadow-sm"
                asset={asset}
                favoriteTagUuid={
                  board.tags.find((it) => it.name === FAVORITE_TAG_NAME)?.uuid
                }
                board={board}
              />
            ))}
          </ZoomableGridWrapper>
          {/* FIXME: The zoom component will removed */}
          <ZoomControl
            boardUuid={board.uuid}
            className="absolute bottom-16 right-16"
          />
        </div>
      ) : selectedTagsFilter.length === 1 ? (
        selectedTagsFilter[0].name === FAVORITE_TAG_NAME ? (
          <EmptyStateInformation
            iconName="Heart"
            iconClassName="stroke-pimento-red fill-pimento-red"
            title="no favorites yet!"
            body="Click on the heart on any image to save them here."
          />
        ) : (
          <EmptyStateInformation
            iconName="Tag"
            iconClassName="stroke-background fill-pimento-blue"
            title="nothing here yet!"
            body="Tag images to see them here."
          />
        )
      ) : (
        <EmptyStateInformation
          iconName="PimentoStar"
          iconClassName="stroke-pimento-blue fill-pimento-blue"
          title="nothing here yet!"
          body="Go to Create to start generating."
        />
      )}
    </div>
  );
};
