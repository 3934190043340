import type { AxiosResponse } from "axios";
import { useAppMutation } from "../../../../../../../http/useAppMutation.ts";
import type { Board } from "../../../../../types.ts";
import { useGenerateSettings } from "./useGenerateSettings.ts";

export const useImageFromPromptGeneration = ({
  board,
  onSuccess,
}: {
  board: Board;
  onSuccess?: (
    response: AxiosResponse<{
      assets_uuids: string[];
    }>,
  ) => void;
}) => {
  const { generateSettings } = useGenerateSettings(board.uuid);
  const generateImageFromPromptMutation = useAppMutation<{
    assets_uuids: string[];
  }>({
    path: "boards/generate-image-from-prompt",
    invalidate: [`boards/${board.uuid}`, "boards", "users/me"],
    onSuccess,
  }).mutation;

  return {
    mutation: ({
      prompt,
      styleUuid,
    }: {
      prompt: string;
      styleUuid: string;
    }) => {
      generateImageFromPromptMutation.mutate({
        board_uuid: board.uuid,
        prompt,
        style_uuid: styleUuid,
        num_generations: generateSettings.num_generations,
        prompt_strength: generateSettings.prompt_strength,
        width: generateSettings.width,
        height: generateSettings.height,
        lora_scale: generateSettings.lora_scale,
        negative_prompt: generateSettings.negative_prompt,
        prompt_enhancement: generateSettings.prompt_enhancement,
        quality_preset: generateSettings.quality_preset,
      });
    },
    isLoading: generateImageFromPromptMutation.isLoading,
  };
};
