import type { ReactNode } from "react";
import { Info } from "../../../components/Info.tsx";

export const SelectSection = ({
  name,
  informationSection,
  value,
  onChange,
  options,
  optionToLabel,
  disabled = false,
}: {
  name: string;
  informationSection?: ReactNode;
  value?: string;
  onChange: (value: string) => void;
  options: Readonly<string[]>;
  optionToLabel: Readonly<{ [key: string]: string }>;
  disabled?: boolean;
}) => (
  <div className="h-3xl flex-row items-center w-full justify-between">
    <div className="flex-row items-center gap-sm">
      {name}
      {informationSection ? <Info content={informationSection} /> : null}
    </div>
    <select
      className="p-sm"
      value={value}
      onChange={(event) => {
        onChange(event.target.value);
      }}
      disabled={disabled}
    >
      {options.map((type, index) => (
        <option key={index} value={type} className="text-end">
          {optionToLabel[type]}
        </option>
      ))}
    </select>
  </div>
);
