import { useAppQuery } from "../../../../../http/useAppQuery.ts";
import type { Board, ImageContent } from "../../../types.ts";
import { useGetSelectedAssetInitImageUuid } from "./useGetSelectedAssetInitImageUuid.ts";

export const useGetSelectedAssetInitImage = (board: Board) => {
  const initImageUuid = useGetSelectedAssetInitImageUuid(board);

  const { data: initImage } = useAppQuery<ImageContent>({
    queryKey: `contents/${initImageUuid}/`,
    enabled: !!initImageUuid,
  });

  return initImage;
};
