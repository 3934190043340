import {
  type ButtonHTMLAttributes,
  forwardRef,
  type ReactNode,
  type Ref,
} from "react";
import { Spinner } from "../Spinner/Spinner.tsx";

export type BaseButtonProps = {
  children?: ReactNode;
  loading?: boolean;
  loadingContent?: ReactNode;
  size?: "small" | "medium" | "large";
} & ButtonHTMLAttributes<HTMLButtonElement>;

export const BaseButton = forwardRef(
  (
    {
      children,
      loading,
      loadingContent,
      disabled,
      type = "button",
      ...rest
    }: BaseButtonProps,
    ref: Ref<HTMLButtonElement>,
  ) => (
    <button ref={ref} type={type} disabled={!!disabled || loading} {...rest}>
      {loading ? (
        loadingContent ? (
          loadingContent
        ) : (
          <Spinner small inline currentColor />
        )
      ) : (
        children
      )}
    </button>
  ),
);
