import { useGetSelectedAsset } from "../../../../hooks/useGetSelectedAsset.ts";
import type { Board } from "../../../../types.ts";
import { useGetSelectedAssetInitImage } from "../../hooks/useGetSelectedAssetInitImage.ts";
import { useImageViewMode } from "../../hooks/useImageViewMode.ts";
import { OverlapImageContainer } from "./OverlapImageContainer.tsx";
import { SingleImageContainer } from "./SingleImageContainer.tsx";

export const ImageContainer = ({ board }: { board: Board }) => {
  const selectedAsset = useGetSelectedAsset(board);
  const selectedImageInitImage = useGetSelectedAssetInitImage(board);
  const { viewMode } = useImageViewMode();

  return viewMode === "overlap" && selectedImageInitImage && selectedAsset ? (
    <OverlapImageContainer
      initImage={selectedImageInitImage}
      resultingImage={selectedAsset.image}
      board={board}
    />
  ) : (
    <SingleImageContainer image={selectedAsset?.image} board={board} />
  );
};
