import { useEffect, useState } from "react";
import { ReactCompareSlider } from "react-compare-slider";
import { Icon } from "../../../../../../components/Icon/Icon.tsx";
import { Image as InternalImage } from "../../../../../../components/Image/Image.tsx";
import { SizeObservableWrapper } from "../../../../../../components/SizeObservableWrapper/SizeObservableWrapper.tsx";
import type { Board, ImageContent } from "../../../../types.ts";
import { BaseImageContainer } from "./BaseImageContainer.tsx";
import { SingleImageContainer } from "./SingleImageContainer.tsx";

export const OverlapImageContainer = ({
  initImage,
  resultingImage,
  board,
}: {
  initImage: ImageContent;
  resultingImage: ImageContent;
  board: Board;
}) => {
  const [parentMaxSize, setParentMaxSize] = useState<{
    width: number;
    height: number;
  }>();
  const [resultingImageSize, setResultingImageSize] = useState<{
    width: number;
    height: number;
  }>();

  useEffect(() => {
    const img = new Image();
    img.src = resultingImage.url;
    img.onload = () => {
      setResultingImageSize({ width: img.width, height: img.height });
    };
  }, [resultingImage]);

  const scale =
    resultingImageSize && parentMaxSize
      ? Math.min(
          Math.min(parentMaxSize.width, resultingImageSize.width) /
            resultingImageSize.width,
          Math.min(parentMaxSize.height, resultingImageSize.height) /
            resultingImageSize.height,
        )
      : 1;
  const scaledInitImageSize = resultingImageSize
    ? {
        width: resultingImageSize.width * scale,
        height: resultingImageSize.height * scale,
      }
    : undefined;

  return (
    <SizeObservableWrapper
      className="flex-fill w-full flex-col-center"
      onSizeChange={({ width, height }) => setParentMaxSize({ width, height })}
    >
      {/* XXX: We display comparison view only if all size variables computed*/}
      {scaledInitImageSize ? (
        <BaseImageContainer image={resultingImage} board={board}>
          <ReactCompareSlider
            handle={
              <div className="flex-fill flex-col-center h-full w-[2px] bg-black">
                <div
                  style={{
                    width: scaledInitImageSize.width,
                  }}
                  className="flex-row-center"
                >
                  <Icon name="ChevronLeft" className="stroke-xl" size={30} />
                  <Icon name="ChevronRight" className="stroke-xl" size={30} />
                </div>
              </div>
            }
            itemOne={
              <InternalImage
                style={{
                  width: scaledInitImageSize.width,
                  height: scaledInitImageSize.height,
                }}
                imageClassName="h-full w-full"
                src={initImage.url}
              />
            }
            itemTwo={
              <InternalImage
                className="max-h-full max-w-full"
                imageClassName="max-h-full max-w-full"
                src={resultingImage.url}
              />
            }
          />
        </BaseImageContainer>
      ) : (
        <SingleImageContainer image={resultingImage} board={board} />
      )}
    </SizeObservableWrapper>
  );
};
