import classNames from "classnames";
import { type ReactNode, useEffect, useMemo, useState } from "react";
import { BaseButton } from "../../../components/Button/BaseButton.tsx";
import { Dialog } from "../../../components/Dialog/Dialog.tsx";
import { Icon } from "../../../components/Icon/Icon.tsx";
import { useAppQuery } from "../../../http/useAppQuery.ts";
import { useIsUpgradeDialogOpen } from "../../../singletons/UpgradeDialog/hooks/useIsUpgradeDialogOpen.ts";
import { useCreateStyle } from "../hooks/useCreateStyle.ts";
import { useStyles } from "../hooks/useStyles.ts";
import type { Style } from "../types.ts";
import { CUSTOM_TAG, POPULAR_TAG } from "./constants.ts";
import { NewStyleTile } from "./NewStyleTile.tsx";
import { StyleTile } from "./StyleTile.tsx";

export const StylePicker = ({
  dialogOpen,
  onDialogChange,
  onStyleSelect,
  initTag,
  displayCloseButton = true,
  clickOutsideAuthorized = true,
}: {
  dialogOpen: boolean;
  onDialogChange: (dialogChange: boolean) => void;
  onStyleSelect?: (style: Style) => void;
  initTag?: string;
  displayCloseButton?: boolean;
  clickOutsideAuthorized?: boolean;
}) => (
  <Dialog
    className="md:w-[840px] lg:w-[1040px] h-[532px] bg-white"
    isOpen={dialogOpen}
    onOpenChange={(dialogChange) => {
      onDialogChange(dialogChange);
    }}
    displayCloseButton={displayCloseButton}
    clickOutsideAuthorized={clickOutsideAuthorized}
    iconSize={24}
    content={
      dialogOpen && (
        <StylePickerContent onStyleSelect={onStyleSelect} initTag={initTag} />
      )
    }
  />
);

const StylePickerContent = ({
  onStyleSelect,
  initTag,
}: {
  onStyleSelect?: (style: Style) => void;
  initTag?: string;
}) => {
  const [searchText, setSearchText] = useState("");
  const [selectedTag, setSelectedTag] = useState<string | undefined>(initTag);
  const [styleCategory, setStyleCategory] = useState<
    "user" | "library" | "all"
  >(initTag ? "library" : "all");

  // FIXME: better manage states to avoid this useEffect
  useEffect(() => {
    setSelectedTag(initTag);
    setStyleCategory(
      initTag ? (initTag === CUSTOM_TAG ? "user" : "library") : "all",
    );
  }, [initTag]);

  // FIXME: We must improve the loading state of this query to avoid empty state
  const { data: styles } = useAppQuery<Style[]>({ queryKey: "styles" });
  const { userStyles, libraryStylesCountByTags } = useStyles();

  const filteredStyles = useMemo(
    () =>
      styles?.filter((style: Style) =>
        styleCategory === "user"
          ? !style.is_curated
          : styleCategory === "library"
          ? selectedTag
            ? style.tags.includes(selectedTag)
            : style.is_curated
          : // XXX: the text search filter is only applied when "all" is selected
            style.name?.toLowerCase().includes(searchText),
      ),
    [styles, styleCategory, selectedTag, searchText],
  );
  return (
    <div className="flex-col flex-fill p-2xl gap-2xl">
      <span className="flex-row-center font-burg text-4xl items-center">
        choose your style
      </span>
      <div className="flex-fill flex-row gap-lg">
        <div className="flex-col w-[180px]">
          <div className="mb-md relative w-full flex-row items-center">
            <input
              className="w-full h-3xl pl-md pr-xl rounded border placeholder:text-gray-350"
              placeholder="Search..."
              value={searchText}
              autoFocus
              onChange={(event) => {
                setSearchText(event.target.value.toLowerCase());
                // XXX:  when the user writes a word, it automatically selects the "All" section to have broad results
                //  the input is cleaned when the user select another tag
                setStyleCategory("all");
                setSelectedTag(undefined);
              }}
            />
            <Icon name="Search" className="absolute right-md stroke-gray-350" />
          </div>
          <div className="flex-fill flex-col overflow-y-auto">
            {libraryStylesCountByTags &&
              Object.keys(libraryStylesCountByTags).includes(POPULAR_TAG) && (
                <TagButton
                  onClick={() => {
                    setSelectedTag(POPULAR_TAG);
                    setStyleCategory("library");
                    setSearchText("");
                  }}
                  isActive={
                    styleCategory === "library" && selectedTag === POPULAR_TAG
                  }
                >
                  {POPULAR_TAG} ({libraryStylesCountByTags[POPULAR_TAG]})
                </TagButton>
              )}
            <TagButton
              onClick={() => {
                setStyleCategory("user");
                setSelectedTag(undefined);
                setSearchText("");
              }}
              isActive={styleCategory === "user"}
            >
              My styles ({userStyles ? userStyles.length : 0})
            </TagButton>
            {libraryStylesCountByTags &&
              Object.keys(libraryStylesCountByTags)
                .filter((tagName) => tagName !== POPULAR_TAG)
                .sort()
                .map((tagName) => (
                  <TagButton
                    key={tagName}
                    onClick={() => {
                      setSelectedTag(tagName);
                      setStyleCategory("library");
                      setSearchText("");
                    }}
                    isActive={tagName === selectedTag}
                  >
                    {tagName} ({libraryStylesCountByTags[tagName]})
                  </TagButton>
                ))}
            <TagButton
              onClick={() => {
                setSelectedTag(undefined);
                setStyleCategory("library");
              }}
              isActive={styleCategory === "library" && !selectedTag}
            >
              Library (
              {styles?.filter((it) => it.is_curated)
                ? styles.filter((it) => it.is_curated).length
                : 0}
              )
            </TagButton>
            <TagButton
              onClick={() => {
                setStyleCategory("all");
                setSelectedTag(undefined);
              }}
              isActive={styleCategory === "all"}
            >
              All ({styles ? styles.length : 0})
            </TagButton>
          </div>
        </div>
        {styleCategory === "user" && filteredStyles?.length === 0 ? (
          <div className="flex-fill">
            <EmptyStyleButton />
          </div>
        ) : filteredStyles?.length === 0 ? (
          <div className="flex-fill">
            <NoResult />
          </div>
        ) : (
          <div className="grid md:grid-cols-3 lg:grid-cols-4 content-start gap-xl overflow-y-auto">
            {["all", "user"].includes(styleCategory) && <NewStyleTile />}
            {filteredStyles?.map((filteredStyle) => (
              <div key={filteredStyle.uuid} className="flex-col w-full h-full">
                <StyleTile
                  key={filteredStyle.uuid}
                  style={filteredStyle}
                  onClick={() => {
                    onStyleSelect?.(filteredStyle);
                  }}
                  isEditable={false}
                />
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

const EmptyStyleButton = () => {
  const { setIsUpgradeDialogOpen } = useIsUpgradeDialogOpen();
  const { createStyle, isCreateStyleLoading } = useCreateStyle({
    onStyleCreationForbidden: () => {
      setIsUpgradeDialogOpen(true);
    },
  });

  return (
    <div className="flex-col-center w-full h-full bg-gray-100 rounded-sm gap-xl">
      <div className="flex-col-center gap-lg">
        <Icon name="Palette" size={20} className="stroke-gray-400" />
        <span className="text-xl font-semibold text-gray-400">
          No style created yet
        </span>
      </div>
      <BaseButton
        loading={isCreateStyleLoading}
        onClick={createStyle}
        className="group flex-row-center h-[48px] rounded-sm border pl-md pr-lg bg-white hover:bg-black"
      >
        <Icon name="Plus" size={24} className="group-hover:stroke-white" />
        <span className="text-gray-600 group-hover:text-white font-semibold">
          Add new style
        </span>
      </BaseButton>
    </div>
  );
};

const NoResult = () => (
  <div className="flex-col-center w-full h-full bg-gray-100 rounded-sm gap-xl">
    <span className="text-xl font-semibold text-gray-400">
      No results found
    </span>
  </div>
);

const TagButton = ({
  onClick,
  isActive,
  children,
}: {
  onClick: () => void;
  isActive: boolean;
  children: ReactNode;
}) => (
  <BaseButton
    onClick={onClick}
    className={classNames(
      "h-3xl px-md text-lg font-semibold rounded-sm",
      isActive ? "bg-background text-gray-600" : "text-gray-400",
    )}
  >
    {children}
  </BaseButton>
);
