import { PresetButton } from "../../../components/PresetButton.tsx";
import { QUALITY_PRESETS } from "../../../constants.ts";
import type { QualityPreset } from "../../../types.ts";
import { SettingsSectionWrapper } from "./SettingsSectionWrapper.tsx";

export const QualityPresetWrappedSection = ({
  value,
  onChange,
}: {
  value: QualityPreset;
  onChange: (preset: QualityPreset) => void;
}) => (
  <SettingsSectionWrapper
    name="Quality"
    content={
      <div className="flex-row-center gap-sm">
        {QUALITY_PRESETS.map((preset) => (
          <PresetButton
            key={preset}
            isSelected={value === preset}
            onClick={() => onChange(preset)}
          >
            {preset}
          </PresetButton>
        ))}
      </div>
    }
    infoContent={
      <ul>
        <li>
          <span className="font-semibold">Fast</span> - Generations are faster
          but can be of lower quality.
        </li>
        <li>
          <span className="font-semibold">High</span> - Generations are slower
          and will be of higher quality.
        </li>
      </ul>
    }
  />
);
