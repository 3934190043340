import type { UseQueryResult } from "@tanstack/react-query";
import type { ReactNode } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "../Button/Button.tsx";
import { Icon } from "../Icon/Icon.tsx";
import { Spinner } from "../Spinner/Spinner.tsx";

export const QueryResult = ({
  query,
  children,
}: {
  query: UseQueryResult<any>;
  children: (output: any) => ReactNode | null;
}) => {
  const navigate = useNavigate();

  if (query.isLoading) return <Spinner />;
  if (query.status === "success" && query.data !== undefined) {
    return children(query.data);
  }

  return (
    <div className="flex-col-center h-full gap-[100px]">
      <Icon name="Pimento" size={240} />
      <div className="flex-col-center gap-lg">
        <div className="text-4xl font-semibold">Page not found</div>
        <div className="font-thin text-2xl">
          {(query.error as any)?.message}
        </div>
      </div>
      <Button size="large" onClick={() => navigate("/")}>
        <div className="text-xl text-inherit">Go back to home</div>
      </Button>
    </div>
  );
};
