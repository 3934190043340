import type { ReactNode } from "react";
import { useAppQuery } from "../../../../../http/useAppQuery.ts";
import type { BoardImageContent } from "../../../types.ts";
import { initImageColorInfluenceLabelMapping } from "../../CreateWorkspace/Tools/StyleTransferView/constants.ts";
import type { ImaginationStrengthPresets } from "../../EditWorkspace/Tools/UpscaleView/types.ts";
import {
  controlEndToCreativity,
  promptStrengthToInputColorPercentage,
  scaleControlStrength,
  scalePromptStrength,
} from "../SettingsMenu/settingsScaleUtils.ts";

export const ImageGenerationParameters = ({
  image,
}: {
  image: BoardImageContent;
}) => {
  const { data: upscaleImaginationStrengthPresets } =
    useAppQuery<ImaginationStrengthPresets>({
      queryKey: "presets/upscale",
    });

  // FIXME: We should display default value from preset if it's not set (as done with upscale type)

  switch (image.generation_model_type) {
    case "text_to_image":
      return (
        <GenerationParametersLayout>
          <div className="flex-row justify-between">
            Quality
            <span className="first-letter:uppercase">
              {image.generation_model_params.quality_preset}
            </span>
          </div>
        </GenerationParametersLayout>
      );
    case "image_to_image":
      return (
        <GenerationParametersLayout>
          <div className="flex-col gap-xs">
            <div className="flex-row justify-between">
              Creativity preset
              <span className="first-letter:uppercase">
                {image.generation_model_params.creativity_strength_preset}
              </span>
            </div>
            <div className="flex-row justify-between">
              Quality
              <span className="first-letter:uppercase">
                {image.generation_model_params.quality_preset}
              </span>
            </div>
            {image.generation_model_params.prompt_strength ? (
              <div className="flex-row gap-md">
                <span className="font-semibold">Creativity</span>
                {scalePromptStrength(
                  image.generation_model_params.prompt_strength,
                )}
                %
              </div>
            ) : null}
          </div>
        </GenerationParametersLayout>
      );
    case "style_transfer":
      return (
        <GenerationParametersLayout>
          <div className="flex-col gap-xs">
            <div className="flex-row justify-between">
              Transfer Strength
              <span className="first-letter:uppercase">
                {image.generation_model_params.transfer_strength_preset}
              </span>
            </div>
            <div className="flex-row justify-between">
              Input type
              <span>
                {
                  initImageColorInfluenceLabelMapping[
                    image.generation_model_params.init_image_color_influence
                  ]
                }
              </span>
            </div>
            <div className="flex-row justify-between">
              Quality
              <span className="first-letter:uppercase">
                {image.generation_model_params.quality_preset}
              </span>
            </div>
            {image.generation_model_params.prompt_strength ? (
              <div className="flex-row justify-between">
                Input Color
                <span>
                  {promptStrengthToInputColorPercentage(
                    image.generation_model_params.prompt_strength,
                  )}
                  %
                </span>
              </div>
            ) : null}
            {image.generation_model_params.control_strength ? (
              <div className="flex-row justify-between">
                Input structure
                <span>
                  {scaleControlStrength(
                    image.generation_model_params.control_strength,
                  )}
                  %
                </span>
              </div>
            ) : null}
            {image.generation_model_params.stop ? (
              <div className="flex-row justify-between">
                Creativity
                <span>
                  {controlEndToCreativity(image.generation_model_params.stop)}%
                </span>
              </div>
            ) : null}
          </div>
        </GenerationParametersLayout>
      );
    case "upscaler":
      return (
        <GenerationParametersLayout>
          <div className="flex-col gap-xs">
            <div className="flex-row justify-between">
              Preset
              <span className="first-letter:uppercase">
                {image.generation_model_params.imagination_strength_preset}
              </span>
            </div>
            <div className="flex-row justify-between">
              Scale factor
              <span>{image.generation_model_params.scale_factor}x</span>
            </div>
            {image.generation_model_params.creativity ??
            upscaleImaginationStrengthPresets?.[
              image.generation_model_params.imagination_strength_preset
            ].creativity ? (
              <div className="flex-row justify-between">
                Creativity
                <span>
                  {Math.round(
                    (image.generation_model_params.creativity ??
                      upscaleImaginationStrengthPresets?.[
                        image.generation_model_params
                          .imagination_strength_preset
                      ].creativity ??
                      0) * 100,
                  )}
                  %
                </span>
              </div>
            ) : null}
            {image.generation_model_params.resemblance ??
            upscaleImaginationStrengthPresets?.[
              image.generation_model_params.imagination_strength_preset
            ].resemblance ? (
              <div className="flex-row justify-between">
                Similarity
                <span>
                  {Math.round(
                    (image.generation_model_params.resemblance ??
                      upscaleImaginationStrengthPresets?.[
                        image.generation_model_params
                          .imagination_strength_preset
                      ].resemblance ??
                      0) * 100,
                  )}
                  %
                </span>
              </div>
            ) : null}
          </div>
        </GenerationParametersLayout>
      );
    case "generative_fill":
      return (
        <GenerationParametersLayout>
          <div className="flex-col gap-xs">
            <div className="flex-row justify-between">
              Preset
              <span className="first-letter:uppercase">
                {image.generation_model_params.creativity_strength_preset}
              </span>
            </div>
            <div className="flex-row justify-between">
              Quality
              <span className="first-letter:uppercase">
                {image.generation_model_params.quality_preset}
              </span>
            </div>
            {image.generation_model_params.prompt_strength ? (
              <div className="flex-row justify-between">
                Creativity
                <span>
                  {Math.round(
                    image.generation_model_params.prompt_strength * 100,
                  )}
                  %
                </span>
              </div>
            ) : null}
          </div>
        </GenerationParametersLayout>
      );
    case null:
      return undefined;
  }
};

const GenerationParametersLayout = ({ children }: { children: ReactNode }) => (
  <div className="flex-col gap-md">
    <span className="font-semibold">Parameters</span>
    {children}
  </div>
);
