import classNames from "classnames";
import type { HTMLAttributes } from "react";
import type { ImageContent } from "../types.ts";
import { ImageGenerationProgress } from "./ImageGenerationProgress.tsx";

export const LoadingTile = ({
  image,
  className,
  ...rest
}: HTMLAttributes<HTMLDivElement> & {
  image: ImageContent;
  className?: string;
}) => (
  <div
    {...rest}
    className={classNames(
      "flex-col-center h-full w-full overflow-clip gap-md p-xl bg-white text-pimento-blue text-center",
      className,
    )}
  >
    {image.generation_model_type === "upscaler" ? "Upscaling" : "Generating"}

    <div className="w-3/4">
      <ImageGenerationProgress image={image} />
    </div>
  </div>
);
