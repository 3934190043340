import classNames from "classnames";
import type { ReactNode } from "react";
import { ClickableIcon } from "../../../components/Icon/ClickableIcon.tsx";
import { Popover } from "../../../components/Popover/Popover.tsx";

export const Info = ({
  content,
  iconSize,
  className,
}: {
  content: string | ReactNode;
  iconSize?: number;
  className?: string;
}) => (
  <Popover
    content={content}
    side="right"
    className={classNames(
      "z-50 rounded-sm border bg-popover m-2 p-lg max-w-[280px]",
      className,
    )}
  >
    <ClickableIcon
      name="Info"
      size={iconSize ?? 12}
      className="p-0"
      iconClassName="pb-0 stroke-2 hover:opacity-60"
    />
  </Popover>
);
