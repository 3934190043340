export const ASSETS = "/assets" as const;
export const HOME = "/" as const;
export const ADMIN = "/admin" as const;

export const BOARDS = "/boards" as const;
export const STYLES = "/styles" as const;

// Board - Workspaces
export const CREATE_WORKSPACE = "create" as const;
export const EDIT_WORKSPACE = "edit" as const;

export const ASSETS_WORKSPACE = "assets" as const;

// Board - Tools
export const GENERATE_TOOL = "generate" as const;
export const GENERATE_TOOL_PATH =
  `${CREATE_WORKSPACE}/${GENERATE_TOOL}` as const;
export const TRANSFER_TOOL = "transfer" as const;
export const TRANSFER_TOOL_PATH =
  `${CREATE_WORKSPACE}/${TRANSFER_TOOL}` as const;

export const VARIATION_TOOL = "variation" as const;
export const VARIATION_TOOL_PATH =
  `${EDIT_WORKSPACE}/${VARIATION_TOOL}` as const;
export const DRAW_TOOL = "draw" as const;
export const DRAW_TOOL_PATH = `${EDIT_WORKSPACE}/${DRAW_TOOL}` as const;
export const SELECT_TOOL = "select" as const;
export const SELECT_TOOL_PATH = `${EDIT_WORKSPACE}/${SELECT_TOOL}` as const;
export const UPSCALE_TOOL = "upscale" as const;
export const UPSCALE_TOOL_PATH = `${EDIT_WORKSPACE}/${UPSCALE_TOOL}` as const;
