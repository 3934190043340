export const Select = ({ className }: { className?: string }) => (
  <svg
    className={className}
    width="25"
    height="25"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.1172 5.72266C13.0016 5.94209 13.8162 6.27731 14.5397 6.73801"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.50233 5.12064C6.15427 4.49147 3.84618 6.72775 3.25213 7.73823C1.45533 10.8504 3.1544 15.1923 7.04108 17.4363C10.9278 19.6802 15.5375 18.9807 17.3343 15.8686"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeDasharray="2.4 2.4"
    />
    <path
      d="M20.5 4.5C20.5 6.53921 18.7843 8.5 17 8.5C18.7843 8.5 20.5 10.4608 20.5 12.5C20.5 10.4608 22.2157 8.5 24 8.5C22.2157 8.5 20.5 6.53921 20.5 4.5Z"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13 9.5C13 10.7746 12.0196 12 11 12C12.0196 12 13 13.2254 13 14.5C13 13.2254 13.9804 12 15 12C13.9804 12 13 10.7746 13 9.5Z"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
