import { useState } from "react";
import { Button } from "../../../../components/Button/Button.tsx";
import { Dialog } from "../../../../components/Dialog/Dialog.tsx";
import { Icon } from "../../../../components/Icon/Icon.tsx";
import { Image } from "../../../../components/Image/Image.tsx";
import { trackEvent } from "../../../../utils/trackEvent.ts";
import { TriColorPimentoStar } from "../../assets/TriColorPimentoStar.tsx";

export const DrawWaitingListDialog = ({
  isOpen,
  onOpenChange,
}: {
  isOpen: boolean;
  onOpenChange: (open: boolean) => void;
}) => {
  const [step, setStep] = useState<"joinBeta" | "joinBetaConfirmation">(
    "joinBeta",
  );
  return (
    <Dialog
      className="h-[500px] w-[400px]"
      isOpen={isOpen}
      onOpenChange={onOpenChange}
      clickOutsideAuthorized
      content={
        step === "joinBeta" ? (
          <div className="flex-col">
            <div className="flex-row-center p-xl gap-lg bg-gray-100 h-1/2">
              <Image
                className="h-[120px] aspect-square"
                imageClassName="object-contain object-center"
                src="https://storage.googleapis.com/419c45cf-be8a-4cba-bbcd-74a221eb2587/app/assets/navbar/tools/draw_1.png"
              />
              <Icon
                name="ArrowRight"
                size={24}
                className="stroke-gray-350 stroke-xl"
              />
              <div className="relative">
                <div className="absolute -left-2xl -top-md border border-pimento-blue rounded-xl px-md z-10">
                  Red pepper
                </div>
                <Image
                  className="h-[120px] aspect-square"
                  imageClassName="object-contain object-center"
                  src="https://storage.googleapis.com/419c45cf-be8a-4cba-bbcd-74a221eb2587/app/assets/navbar/tools/draw_2.png"
                />
              </div>
            </div>
            <div className="h-1/2 flex-col gap-xl justify-center bg-white p-xl">
              <div className="flex-row items-center gap-md">
                <Icon name="Draw" />
                <div className="text-xl font-semibold">
                  Draw is coming soon!
                </div>
              </div>
              <div className="flex-col">
                <span className="text-lg text-gray-400">
                  Draw is currently in beta version. If you’d like to test it,
                  click below to join our beta program.
                </span>
              </div>
              <Button
                onClick={() => {
                  trackEvent("upgrade:join_beta", {});
                  setStep("joinBetaConfirmation");
                }}
              >
                Join Beta
              </Button>
            </div>
          </div>
        ) : (
          <div className="flex-col">
            <div className="h-1/2 flex-col-center bg-gray-100">
              <TriColorPimentoStar />
            </div>
            <div className="h-1/2 flex-col-center justify-center gap-xl bg-white">
              <span className="text-xl font-semibold text-center">
                Welcome to the club
              </span>
              <div className="flex-col-center">
                <span className="text-lg text-gray-400 text-center">
                  Thanks for joining our beta program.
                </span>
                <span className="text-lg text-gray-400 text-center">
                  Our team will come back to you soon.
                </span>
              </div>
            </div>
          </div>
        )
      }
    />
  );
};
