import type { ReactNode } from "react";

export const SettingsMenuLayout = ({
  body,
  footer,
}: {
  body: ReactNode;
  footer: ReactNode;
}) => (
  <div className="h-full w-[270px] border-r bg-white flex-col justify-between">
    <div className="flex-shrink overflow-scroll">{body}</div>
    <div className="mb-auto">{footer}</div>
  </div>
);
