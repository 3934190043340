import { useFlags } from "launchdarkly-react-client-sdk";
import { useState } from "react";
import { Icon } from "../../../../components/Icon/Icon.tsx";
import { Tooltip } from "../../../../components/Tooltip/Tooltip.tsx";
import { useUser } from "../../../../hooks/useUser.ts";
import {
  DRAW_TOOL,
  SELECT_TOOL,
  UPSCALE_TOOL,
  VARIATION_TOOL,
} from "../../../../routes.ts";
import { isUserAllowedTo } from "../../../../types.ts";
import { trackEvent } from "../../../../utils/trackEvent.ts";
import { ToolMenuButton } from "../components/ToolMenu/ToolMenuButton.tsx";
import { ToolMenuTooltipContent } from "../components/ToolMenu/ToolMenuTooltipContent.tsx";
import { DrawWaitingListDialog } from "./DrawWaitingListDialog.tsx";

export const EditToolMenu = () => {
  const { user } = useUser();
  const flags = useFlags();

  return (
    <div className="flex-row">
      <div className="h-full flex-col bg-gray-200">
        <ToolMenuButton
          to={SELECT_TOOL}
          title="Fill"
          iconName="Select"
          tooltipContent={
            <ToolMenuTooltipContent
              title="Fill"
              description="Select a zone and edit"
              beforeImageUrl="https://storage.googleapis.com/419c45cf-be8a-4cba-bbcd-74a221eb2587/app/assets/navbar/tools/select_1.png"
              afterImageUrl="https://storage.googleapis.com/419c45cf-be8a-4cba-bbcd-74a221eb2587/app/assets/navbar/tools/select_2.png"
              learnMoreLink="https://pimento.crisp.help/en/article/regenerate-part-of-your-image-beta-1k5h2d8/"
            />
          }
        />
        {flags["drawFeature"] ||
        (user && isUserAllowedTo(user, "draw:create")) ? (
          <ToolMenuButton
            to={DRAW_TOOL}
            title="Draw"
            iconName="Draw"
            tooltipContent={
              <ToolMenuTooltipContent
                title="Draw"
                description="Sketch a new object and generate"
                beforeImageUrl="https://storage.googleapis.com/419c45cf-be8a-4cba-bbcd-74a221eb2587/app/assets/navbar/tools/draw_1.png"
                afterImageUrl="https://storage.googleapis.com/419c45cf-be8a-4cba-bbcd-74a221eb2587/app/assets/navbar/tools/draw_2.png"
                learnMoreLink="https://pimento.crisp.help/en/article/edit-your-images-beta-1d8smmj/"
              />
            }
          />
        ) : (
          <DrawWaitingListButton />
        )}
        <ToolMenuButton
          to={VARIATION_TOOL}
          title="Variations"
          iconName="Layers"
          tooltipContent={
            <ToolMenuTooltipContent
              title="Variations"
              description="Generate versions of your image"
              beforeImageUrl="https://storage.googleapis.com/419c45cf-be8a-4cba-bbcd-74a221eb2587/app/assets/navbar/tools/variation_1.png"
              afterImageUrl="https://storage.googleapis.com/419c45cf-be8a-4cba-bbcd-74a221eb2587/app/assets/navbar/tools/variation_2.png"
              learnMoreLink="https://pimento.crisp.help/en/article/get-variations-of-your-image-lwsde5/"
            />
          }
        />
        <ToolMenuButton
          to={UPSCALE_TOOL}
          title="Upscale"
          iconName="Upscale"
          tooltipContent={
            <ToolMenuTooltipContent
              title="Upscale"
              description="Improve the quality and remove artifacts"
              beforeImageUrl="https://storage.googleapis.com/419c45cf-be8a-4cba-bbcd-74a221eb2587/app/assets/navbar/tools/upscale_1.png"
              afterImageUrl="https://storage.googleapis.com/419c45cf-be8a-4cba-bbcd-74a221eb2587/app/assets/navbar/tools/upscale_2.png"
              learnMoreLink="https://pimento.crisp.help/en/article/quick-upscale-and-enhance-your-images-ck3gea/"
            />
          }
        />
        <div />
      </div>
    </div>
  );
};
const DrawWaitingListButton = () => {
  const [isWaitingListDialogOpen, setIsWaitingListDialogOpen] = useState(false);
  return (
    <>
      <Tooltip
        content={
          <ToolMenuTooltipContent
            title="Draw"
            description="Sketch a new object and generate"
            beforeImageUrl="https://storage.googleapis.com/419c45cf-be8a-4cba-bbcd-74a221eb2587/app/assets/navbar/tools/draw_1.png"
            afterImageUrl="https://storage.googleapis.com/419c45cf-be8a-4cba-bbcd-74a221eb2587/app/assets/navbar/tools/draw_2.png"
            learnMoreLink="https://pimento.crisp.help/en/article/edit-your-images-beta-1d8smmj/"
          />
        }
        className="!bg-gray-600 !rounded-md"
        delayDuration={500}
        side="right"
        arrowDisplayed
      >
        <button
          onClick={() => {
            setIsWaitingListDialogOpen(true);
            trackEvent("upgrade:display_popup", {
              reason: "draw_tool_click",
            });
          }}
          className="flex-col-center w-[72px] h-[64px] gap-md hover:bg-gray-300"
        >
          <Icon name="Draw" size={20} className="stroke-md" />
          <span>Draw</span>
        </button>
      </Tooltip>
      <DrawWaitingListDialog
        isOpen={isWaitingListDialogOpen}
        onOpenChange={setIsWaitingListDialogOpen}
      />
    </>
  );
};
