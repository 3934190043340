import { usePersistedState } from "../../../../../../../hooks/usePersistedState.ts";
import { useAppQuery } from "../../../../../../../http/useAppQuery.ts";
import type { QualityPreset } from "../../../../../types.ts";
import type {
  StyleTransferInitImageInfluence,
  StyleTransferStrengthPreset,
  StyleTransferStrengthPresets,
} from "../types.ts";

export type StyleTransferSettings = {
  num_generations: number;
  lora_scale: number;
  prompt_strength: number;
  controlnet_1_conditioning_scale: number;
  controlnet_1_end: number;
  negative_prompt: string;
  prompt_enhancement: boolean;
  // FIXME: the type could come from the backend
  transfer_strength_preset: StyleTransferStrengthPreset;
  init_image_color_influence: StyleTransferInitImageInfluence;
  quality_preset: QualityPreset;
};

export const STYLE_TRANSFER_DEFAULT_SETTINGS = {
  num_generations: 4,
  lora_scale: 0.9,
  prompt_strength: 0.92,
  controlnet_1_conditioning_scale: 0.44,
  controlnet_1_end: 0.8,
  negative_prompt: "",
  prompt_enhancement: true,
  transfer_strength_preset: "strong",
  init_image_color_influence: "color",
  quality_preset: "fast",
} as const;

// FIXME: Find best way to deal with atom updates and persistence of the settings
export const useStyleTransferSettings = (boardUuid: string) => {
  const [styleTransferSettings, setStyleTransferSettings] =
    usePersistedState<StyleTransferSettings>({
      key: `style-transfer-settings-${boardUuid}-v5`,
      defaultValue: STYLE_TRANSFER_DEFAULT_SETTINGS,
    });

  const { data: styleTransferStrengthPresets } =
    useAppQuery<StyleTransferStrengthPresets>({
      queryKey: "presets/style-transfer",
    });

  return {
    styleTransferSettings,
    setStyleTransferSettings: (newSettings: Partial<StyleTransferSettings>) => {
      let transferStrengthSettings: Partial<StyleTransferSettings> = {};
      if (
        (newSettings.transfer_strength_preset ??
          newSettings.init_image_color_influence) &&
        styleTransferStrengthPresets
      ) {
        // XXX: remove presets equal to null for custom presets
        transferStrengthSettings = Object.fromEntries(
          Object.entries(
            styleTransferStrengthPresets[
              newSettings.transfer_strength_preset ??
                styleTransferSettings.transfer_strength_preset
            ][
              newSettings.init_image_color_influence ??
                styleTransferSettings.init_image_color_influence
            ],
          ).filter(([_, v]) => v !== null),
        );
      }
      setStyleTransferSettings((settings) => ({
        ...settings,
        ...newSettings,
        ...transferStrengthSettings,
      }));
    },
  } as {
    styleTransferSettings: StyleTransferSettings;
    setStyleTransferSettings: (
      newSettings: Partial<StyleTransferSettings>,
    ) => void;
  };
};
