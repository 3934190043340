import { useEffect, useState } from "react";
import type { Board } from "../../../../types.ts";
import { CollapsableSettingsSectionWrapper } from "../../../components/SettingsMenu/CollapsableSettingsSectionWrapper.tsx";
import { QualityPresetWrappedSection } from "../../../components/SettingsMenu/QualityPresetWrappedSection.tsx";
import {
  controlEndToCreativity,
  creativityToControlEnd,
  inputColorPercentageToPromptStrength,
  promptStrengthToInputColorPercentage,
  scaleControlStrength,
  unscaleControlStrength,
} from "../../../components/SettingsMenu/settingsScaleUtils.ts";
import { SettingsSectionWrapper } from "../../../components/SettingsMenu/SettingsSectionWrapper.tsx";
import { SliderSection } from "../../../components/SettingsMenu/SliderSection.tsx";
import { StyleIntensitySection } from "../../../components/SettingsMenu/StyleIntensitySection.tsx";
import { StyleWrappedSection } from "../../../components/SettingsMenu/StyleWrappedSection.tsx";
import { SettingsMenuLayout } from "../../../components/SettingsMenuLayout.tsx";
import { useStyleTransferBaseImage } from "./hooks/useStyleTransferBaseImage.ts";
import { useStyleTransferSettings } from "./hooks/useStyleTransferSettings.ts";
import { InitialImageWrappedSection } from "./InitialImageWrappedSection.tsx";
import { InitImageColorInfluenceSection } from "./InitImageColorInfluenceSection.tsx";
import { StyleTransferGenerationBar } from "./StyleTransferGenerationBar.tsx";
import { TransferStrengthSection } from "./TransferStrengthSection.tsx";

export const StyleTransferSettings = ({ board }: { board: Board }) => {
  const { styleTransferSettings, setStyleTransferSettings } =
    useStyleTransferSettings(board.uuid);
  const { styleTransferBaseImageUuid, setStyleTransferBaseImage } =
    useStyleTransferBaseImage();
  const [advancedSectionOpen, setAdvancedSectionOpen] = useState(false);

  useEffect(() => {
    if (styleTransferSettings.transfer_strength_preset === "custom") {
      setAdvancedSectionOpen(true);
    }
  }, [styleTransferSettings.transfer_strength_preset]);

  return (
    <SettingsMenuLayout
      body={
        <div className="flex-col">
          <StyleWrappedSection board={board} />
          <InitialImageWrappedSection
            imageUuid={styleTransferBaseImageUuid}
            onImageUuidChange={(uuid) =>
              setStyleTransferBaseImage({ styleTransferBaseImageUuid: uuid })
            }
          />
          <SettingsSectionWrapper
            name="Input type"
            content={<InitImageColorInfluenceSection board={board} />}
          />
          <SettingsSectionWrapper
            name="Transfer Strength"
            content={<TransferStrengthSection board={board} />}
            infoContent={
              <div className="flex-col gap-lg">
                <div>
                  <span className="font-bold">Light</span> - Adapted when your
                  initial image is quite close to your style (ex : a realistic
                  photo you want to convert into a precise drawing).
                </div>
                <div>
                  <span className="font-bold">Strong</span> - Adapted if your
                  initial image is very different from your style (ex: a
                  realistic photo you want to convert into an icon).
                </div>
              </div>
            }
          />
          <QualityPresetWrappedSection
            value={styleTransferSettings.quality_preset}
            onChange={(preset) =>
              setStyleTransferSettings({ quality_preset: preset })
            }
          />
          <CollapsableSettingsSectionWrapper
            name="Advanced"
            open={advancedSectionOpen}
            onOpenChange={setAdvancedSectionOpen}
            content={
              <div className="flex-col">
                <StyleIntensitySection
                  loraScale={styleTransferSettings.lora_scale}
                  onLoraScaleChange={(loraScale: number) =>
                    setStyleTransferSettings({
                      lora_scale: loraScale,
                      transfer_strength_preset: "custom",
                    })
                  }
                />
                <SliderSection
                  min={0}
                  max={100}
                  value={promptStrengthToInputColorPercentage(
                    styleTransferSettings.prompt_strength,
                  )}
                  onChange={(inputColorPercentage) =>
                    setStyleTransferSettings({
                      prompt_strength:
                        inputColorPercentageToPromptStrength(
                          inputColorPercentage,
                        ),
                      transfer_strength_preset: "custom",
                      init_image_color_influence: "color",
                    })
                  }
                  sliderName="Input colors"
                  sliderInformationSection={
                    <div className="flex-col gap-lg">
                      <div>
                        <span className="font-bold">Input colors</span> - The
                        higher this parameter, the more the colors of your input
                        image will be taken into account. Select 0% if you don’t
                        want the colors of your image input to influence your
                        results.
                      </div>
                    </div>
                  }
                />
                <SliderSection
                  min={0}
                  max={100}
                  value={scaleControlStrength(
                    styleTransferSettings.controlnet_1_conditioning_scale,
                  )}
                  onChange={(value) =>
                    setStyleTransferSettings({
                      controlnet_1_conditioning_scale:
                        unscaleControlStrength(value),
                      transfer_strength_preset: "custom",
                    })
                  }
                  sliderName="Input structure"
                  sliderInformationSection={
                    <div className="flex-col gap-lg">
                      <span>
                        <span className="font-bold">Input structure</span> - The
                        higher this parameter, the more the structure and shapes
                        of your initial image will be taken into account.
                      </span>
                    </div>
                  }
                />
                <SliderSection
                  min={0}
                  max={100}
                  value={Math.round(
                    controlEndToCreativity(
                      styleTransferSettings.controlnet_1_end,
                    ),
                  )}
                  onChange={(value) => {
                    setStyleTransferSettings({
                      controlnet_1_end: creativityToControlEnd(value),
                      transfer_strength_preset: "custom",
                    });
                  }}
                  sliderName="Creativity"
                  sliderInformationSection={
                    <div className="flex-col py-sm gap-lg">
                      <div>
                        <span className="font-bold">Creativity</span> - The
                        higher this parameter, the more freedom you will give to
                        Pimento to transfer your style.
                      </div>
                    </div>
                  }
                />
              </div>
            }
          />
        </div>
      }
      footer={<StyleTransferGenerationBar board={board} />}
    />
  );
};
