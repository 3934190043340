import { useUser } from "../../../hooks/useUser.ts";
import { isUserAllowedTo } from "../../../types.ts";

export const useUserCanOpenStyleTagMenu = () => {
  const { user } = useUser();

  return (
    user &&
    (isUserAllowedTo(user, "styles:add-tags") ||
      isUserAllowedTo(user, "styles:remove-tags") ||
      isUserAllowedTo(user, "style-tags:create"))
  );
};
