export const TriColorPimentoStar = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="152"
    height="150"
    viewBox="0 0 152 150"
    fill="none"
  >
    <path
      d="M81.0315 5.15762L62.2953 13.7786L61.2523 28.695L69.6297 54.5364L60.0332 43.6924L33.8375 26.8713L15.1001 35.491L13.719 55.2556L26.8168 63.6661L54.5349 69.8664L39.9013 72.2625L11.6196 85.2742L10.2369 105.039L27.592 116.182L41.7329 109.676L61.0736 90.0348L56.0368 103.275L53.9507 133.108L71.305 144.253L90.0416 135.632L91.0846 120.715L82.7073 94.8736L92.304 105.718L118.5 122.539L137.237 113.919L138.618 94.1548L125.52 85.7443L97.8019 79.544L112.436 77.1479L140.717 64.1362L142.1 44.3718L124.745 33.2282L110.604 39.7341L91.2633 59.3754L96.2998 46.1351L98.3859 16.3023L81.0315 5.15762Z"
      fill="#F10F0D"
    />
    <path
      d="M70.908 5.21665L53.5539 16.3613L54.597 31.2777L66.4893 55.7017L55.477 46.2989L27.1953 33.2871L9.83982 44.4307L11.2229 64.1951L25.3637 70.701L53.675 72.9833L39.5173 77.3927L13.3216 94.2138L14.7031 113.978L33.4402 122.598L46.538 114.187L62.9569 92.0455L59.8118 105.858L61.8979 135.691L80.6345 144.312L97.9889 133.167L96.9458 118.251L85.0535 93.8263L96.0661 103.229L124.348 116.241L141.703 105.098L140.32 85.3332L126.179 78.8274L97.8678 76.5451L112.026 72.1357L138.221 55.3146L136.839 35.5501L118.102 26.9303L105.005 35.3409L88.5859 57.4827L91.7306 43.6704L89.6445 13.8376L70.908 5.21665Z"
      fill="#3B88FC"
    />
    <path
      d="M75.5152 5.28247L57.426 15.1894V30.1422L67.5856 55.3364L57.2561 45.1882L29.9508 30.2353L11.8604 40.1411L11.8613 59.9539L25.5139 67.4303L53.597 71.6819L39.1662 75.093L11.861 90.0458L11.8604 109.859L29.9505 119.764L43.6031 112.288L61.5266 91.3451L57.4257 104.905V134.81L75.5152 144.717L93.6047 134.81V119.857L83.4451 94.663L93.775 104.812L121.08 119.764L139.17 109.859L139.17 90.0459L125.517 82.5694L97.4337 78.3178L111.865 74.9067L139.169 59.9539L139.17 40.1412L121.08 30.2354L107.428 37.7118L89.5041 58.6544L93.6047 45.0951V15.1894L75.5152 5.28247Z"
      fill="#F3F20E"
    />
  </svg>
);
