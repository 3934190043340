import { fabric } from "fabric";
import { convertAlphaToMaskAndDilate } from "../BaseEditor/canvasUtils.ts";
import { STROKE_WIDTH } from "./constants.ts";

export const updatePathsFill = ({
  fabricCanvas,
  color,
}: {
  fabricCanvas: fabric.Canvas;
  color: string;
}) => {
  const fabricObjects = fabricCanvas.getObjects();
  for (const fabricObject of fabricObjects) {
    if (fabricObject.type === "path" && fabricObject.fill) {
      fabricObject.set("fill", color);
    }
    fabricCanvas.requestRenderAll();
  }
};

export const generateLassosMask = ({
  fabricCanvas,
}: {
  fabricCanvas: fabric.Canvas;
}) => {
  const backgroundImage = fabricCanvas.backgroundImage;
  fabricCanvas.backgroundImage = undefined;
  updatePathsStrokeWidth({ fabricCanvas, strokeWidth: 0 });

  fabricCanvas.renderAll();
  const objectsCanvas = fabricCanvas.toCanvasElement();
  if (backgroundImage) {
    fabricCanvas.setBackgroundImage(
      backgroundImage,
      fabricCanvas.renderAll.bind(fabricCanvas),
    );
  }
  updatePathsStrokeWidth({ fabricCanvas, strokeWidth: STROKE_WIDTH });
  fabricCanvas.requestRenderAll();

  return convertAlphaToMaskAndDilate({
    canvas: objectsCanvas,
    dilationWidth: 0,
  });
};

const updatePathsStrokeWidth = ({
  fabricCanvas,
  strokeWidth,
}: {
  fabricCanvas: fabric.Canvas;
  strokeWidth: number;
}) => {
  for (const fabricObject of fabricCanvas.getObjects()) {
    if (fabricObject.type === "path") {
      fabricObject.set("strokeWidth", strokeWidth);
    }
  }
};
