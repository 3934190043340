import { useSelectedStyleUuid } from "../../../../../hooks/useSelectedStyleUuid.ts";
import type { Board } from "../../../../../types.ts";
import { scrollCreateWorkspaceGridToTop } from "../../../../AssetsWorkspace/utils/scrollCreateWorkspaceGridToTop.ts";
import { GenerationBar } from "../../../../components/GenerationBar/GenerationBar.tsx";
import { useGeneratePrompt } from "../hooks/useGeneratePrompt.ts";
import { useImageFromPromptGeneration } from "../hooks/useImageFromPromptGeneration.ts";

export const GenerateGenerationBar = ({ board }: { board: Board }) => {
  const { prompt, setPrompt } = useGeneratePrompt();

  const {
    mutation: generateImageFromPrompt,
    isLoading: isImageFromPromptGenerationLoading,
  } = useImageFromPromptGeneration({
    board,
    onSuccess: scrollCreateWorkspaceGridToTop,
  });

  const { selectedStyleUuid } = useSelectedStyleUuid.getState();

  return (
    <GenerationBar
      isLoading={isImageFromPromptGenerationLoading}
      onGenerate={(generationPrompt: string) => {
        if (selectedStyleUuid) {
          generateImageFromPrompt({
            prompt: generationPrompt,
            styleUuid: selectedStyleUuid,
          });
        }
      }}
      isDeactivated={!selectedStyleUuid || !prompt}
      prompt={prompt}
      setPrompt={setPrompt}
      autoFocus
      infoContent={
        <div className="flex-col gap-sm">
          <div className="font-semibold">To get the best results:</div>
          <ul className="pl-lg list-disc">
            <li>Write in English</li>
            <li>Use short words separated by commas</li>
            <li>
              Respect the following prompt structure: subject, action,
              background, other style attributes (lightning, camera focus...)
            </li>
            <li>
              Go to <span className="font-semibold">Advanced &gt; Exclude</span>{" "}
              to remove elements
            </li>
          </ul>
        </div>
      }
    />
  );
};
