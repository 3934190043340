import { useAppMutation } from "../../../http/useAppMutation.ts";

export const useCreateCheckoutUrl = () => {
  const { mutate: createCheckoutUrl, isLoading: isCreateCheckoutUrlLoading } =
    useAppMutation<{ subscription_url: string | undefined }>({
      path: "payments/create-subscription-checkout-url",
      invalidate: [],
      onSuccess: (response) => {
        if (response.data.subscription_url) {
          window.open(response.data.subscription_url, "_self");
        }
      },
    }).mutation;

  return { createCheckoutUrl, isCreateCheckoutUrlLoading };
};
