import classNames from "classnames";
import { useState } from "react";
import { Animation } from "../../components/Animation/Animation.tsx";
import { Button } from "../../components/Button/Button.tsx";
import { Dialog } from "../../components/Dialog/Dialog.tsx";
import { Icon } from "../../components/Icon/Icon.tsx";
import { trackEvent } from "../../utils/trackEvent.ts";
import { TriColorPimentoStar } from "../../views/Board/assets/TriColorPimentoStar.tsx";
import { useCreateCheckoutUrl } from "../../views/Homepage/hooks/useCreateCheckoutUrl.ts";

export const UpgradeDialog = ({
  isOpen,
  onOpenChange,
}: {
  isOpen: boolean;
  onOpenChange: (open: boolean) => void;
}) => (
  <Dialog
    isOpen={isOpen}
    onOpenChange={onOpenChange}
    clickOutsideAuthorized
    content={isOpen && <DialogContent />}
  />
);

const DialogContent = () => {
  const [step, setStep] = useState<"upgrade" | "confirmation">("upgrade");
  const { createCheckoutUrl, isCreateCheckoutUrlLoading } =
    useCreateCheckoutUrl();
  const singlePaneDimensions = "h-[550px] w-[400px]";

  return step === "upgrade" ? (
    <div className="flex-row">
      <div
        className={classNames("bg-black content-center", singlePaneDimensions)}
      >
        <Animation
          loadData={() => import("../../assets/boardInitLoading.json")}
          loop
          autoplay
        />
      </div>
      <div
        className={classNames(
          "flex-col px-2xl gap-3xl justify-center bg-white",
          singlePaneDimensions,
        )}
      >
        <div className="flex-col text-gray-600">
          <span className="font-burg text-4xl">upgrade to pro</span>
          <span className="text-xl font-semibold">$39/month</span>
        </div>
        <div className="flex-col gap-md">
          {[
            "200 daily credits",
            "Unlimited custom styles",
            "Access to all library styles",
            "Dedicated customer support",
          ].map((item) => (
            <div key={item} className="flex-row gap-sm items-center">
              <Icon name="Check" size={12} className="stroke-gray-600" />
              <span className="text-lg text-gray-600">{item}</span>
            </div>
          ))}
        </div>
        <div className="flex-col gap-lg items-center">
          <Button
            className="w-full"
            loading={isCreateCheckoutUrlLoading}
            onClick={() => {
              createCheckoutUrl({});
            }}
          >
            Upgrade now
          </Button>
          <button
            onClick={() => {
              trackEvent("upgrade:join_beta", {});
              setStep("confirmation");
            }}
          >
            <span className="underline text-gray-400">
              Join beta program and get 1 free month!
            </span>
          </button>
        </div>
      </div>
    </div>
  ) : (
    <div className={classNames("flex-col", singlePaneDimensions)}>
      <div className="h-1/2 flex-col-center bg-gray-100">
        <TriColorPimentoStar />
      </div>
      <div className="h-1/2 flex-col p-xl justify-center gap-xl bg-white">
        <span className="text-xl font-semibold text-center">
          Welcome to the club
        </span>
        <div className="flex-col-center">
          <span className="text-lg text-gray-400 text-center">
            Thanks for joining our beta program.
          </span>
          <span className="text-lg text-gray-400 text-center">
            Our team will come back to you soon.
          </span>
        </div>
      </div>
    </div>
  );
};
