import classNames from "classnames";
import {
  type ReactNode,
  type RefObject,
  useEffect,
  useRef,
  useState,
} from "react";
import { createStorageAtom } from "../../../../atoms/createAtom.ts";
import { ClickableIcon } from "../../../../components/Icon/ClickableIcon.tsx";
import { safeCast } from "../../../../utils/common.ts";

const tileZoomAtom = createStorageAtom(
  "assets-view-tile-sizes",
  safeCast<Record<string, number>>({}),
  ({ get, update }) => ({
    getZoom: (boardUuid: string): number | undefined => get()[boardUuid],
    setZoom: (boardUuid: string, tileSize: number) => {
      update((draft) => {
        draft[boardUuid] = tileSize;
      });
    },
  }),
);

const DEFAULT_ZOOM = 200;
const GRID_ID = "exploration-panel";
const GAP = 10;

const getCurrentNbTiles = (tileSize: number) => {
  const currentWidth = document.getElementById(GRID_ID)!.clientWidth;
  return Math.floor((currentWidth + GAP) / (tileSize + GAP));
};
const getTilesSizeForNbTiles = (nbTiles: number) => {
  const currentWidth = document.getElementById(GRID_ID)!.clientWidth;
  return (currentWidth - (nbTiles - 1) * GAP) / nbTiles;
};

const useTileSize = (boardUuid: string) => {
  const zoom = tileZoomAtom((s) => s.getZoom(boardUuid));

  useEffect(() => {
    if (zoom === undefined) {
      tileZoomAtom.getState().setZoom(boardUuid, DEFAULT_ZOOM);
    }
  }, [zoom, boardUuid]);

  return zoom ?? DEFAULT_ZOOM;
};

export const ZoomControl = ({
  boardUuid,
  selectionRef,
  className,
}: {
  boardUuid: string;
  selectionRef?: RefObject<HTMLDivElement>;
  className?: string;
}) => {
  const tileSize = useTileSize(boardUuid);

  return (
    <div
      className={classNames("flex-col gap-md", className)}
      ref={selectionRef}
    >
      <ClickableIcon
        className="rounded-full border bg-white"
        tooltip={{ side: "left", content: "Zoom in" }}
        name="Plus"
        // Disabled could be implemented with a ResizeObserver on GRID_ID
        onClick={() => {
          const nbTiles = getCurrentNbTiles(tileSize);
          if (nbTiles === 2) return;
          tileZoomAtom
            .getState()
            .setZoom(
              boardUuid,
              Math.max(
                Math.floor(getTilesSizeForNbTiles(nbTiles - 1) / 50) * 50,
                tileSize + 50,
              ),
            );
        }}
      />
      <ClickableIcon
        className="rounded-full border bg-white"
        tooltip={{ side: "left", content: "Zoom out" }}
        name="Minus"
        disabled={tileSize <= 100}
        onClick={() => {
          const nbTiles = getCurrentNbTiles(tileSize);
          tileZoomAtom
            .getState()
            .setZoom(
              boardUuid,
              Math.min(
                Math.floor(getTilesSizeForNbTiles(nbTiles + 1) / 50) * 50,
                tileSize - 50,
              ),
            );
        }}
      />
    </div>
  );
};

export const ZoomableGridWrapper = ({
  boardUuid,
  children,
  className,
}: {
  boardUuid: string;
  children: ReactNode;
  className?: string;
}) => {
  const tileSize = useTileSize(boardUuid);
  const [wrapperWidth, setWrapperWidth] = useState<number>();
  const wrapperRef = useRef<HTMLDivElement>(null);

  const [fitHeight, setFitHeight] = useState<number>();

  useEffect(() => {
    if (!wrapperRef.current) return;
    const resizeObserver = new ResizeObserver((entries) => {
      setWrapperWidth(entries[0].contentRect.width);
    });
    resizeObserver.observe(wrapperRef.current);
    return () => resizeObserver.disconnect(); // clean up
  }, []);

  // Manually compute the grid column width to set the get row height with an equal value
  // on zoom change and component width change
  useEffect(() => {
    setFitHeight(getTilesSizeForNbTiles(getCurrentNbTiles(tileSize)));
  }, [tileSize, wrapperWidth]);

  return (
    <div
      ref={wrapperRef}
      id={GRID_ID}
      style={{
        gridTemplateColumns: `repeat(auto-fill, minmax(min(100%, ${tileSize}px), 1fr))`,
        // fix for chrome, set the rows size after the first render
        gridAutoRows: fitHeight ? `${fitHeight}px` : undefined,
        gridAutoFlow: "dense",
        gap: GAP,
      }}
      className={classNames(className, "grid w-full min-w-[200px]")}
    >
      {children}
    </div>
  );
};
