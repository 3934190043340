import { useRef } from "react";
import { useAppMutation } from "../../../../../../../http/useAppMutation.ts";
import { useUploadImage } from "../../../../../hooks/useUploadImage.ts";
import { useGenerativeFillSettings } from "./useGenerativeFillSettings.ts";

export const useUploadAndGenerativeFillMutation = ({
  boardUuid,
  onSuccess,
}: {
  boardUuid: string;
  onSuccess?: () => void;
}) => {
  // XXX: We use refs to store user inputs and be able to use them in the second mutation (generation mutation) called
  // on successful upload.
  // FIXME: Replace this double mutation by a single chain of async mutations for better readability
  const generationPrompt = useRef("");
  const generationAssetUuid = useRef<string>();
  const generationStyleUuid = useRef<string>();

  const { mutate: generativeFill, isLoading: isGenerativeFillLoading } =
    useGenerativeFill({
      boardUuid,
      onSuccess: () => {
        onSuccess?.();
      },
    });

  const { mutate: upload, isLoading: isUploading } = useUploadImage({
    onSuccess: (imageUuid) => {
      if (
        generationPrompt.current &&
        generationAssetUuid.current &&
        generationStyleUuid.current
      ) {
        generativeFill({
          prompt: generationPrompt.current,
          styleUuid: generationStyleUuid.current,
          assetUuid: generationAssetUuid.current,
          maskUuid: imageUuid,
        });
      }
    },
  });

  return {
    mutate: ({
      mask,
      prompt,
      assetUuid,
      styleUuid,
    }: {
      mask: Blob;
      prompt: string;
      assetUuid: string;
      styleUuid: string;
    }) => {
      generationPrompt.current = prompt;
      generationAssetUuid.current = assetUuid;
      generationStyleUuid.current = styleUuid;
      upload({ image: mask });
    },
    isLoading: isGenerativeFillLoading || isUploading,
  };
};

const useGenerativeFill = ({
  boardUuid,
  onSuccess,
}: {
  boardUuid: string;
  onSuccess?: () => void;
}) => {
  const {
    mutation: { mutate, isLoading },
  } = useAppMutation({
    path: "boards/add-element-on-image",
    invalidate: [`boards/${boardUuid}`, "boards", "users/me"],

    onSuccess: () => {
      onSuccess?.();
    },
  });

  const { generativeFillSettings } = useGenerativeFillSettings(boardUuid);

  return {
    mutate: ({
      maskUuid,
      prompt,
      assetUuid,
      styleUuid,
    }: {
      maskUuid: string;
      prompt: string;
      assetUuid: string;
      styleUuid: string;
    }) => {
      mutate({
        prompt,
        board_uuid: boardUuid,
        style_uuid: styleUuid,
        asset_uuid: assetUuid,
        mask_uuid: maskUuid,
        num_generations: generativeFillSettings.num_generations,
        prompt_strength:
          generativeFillSettings.creativity_strength_preset === "custom"
            ? generativeFillSettings.prompt_strength
            : undefined,
        lora_scale: generativeFillSettings.lora_scale,
        negative_prompt: generativeFillSettings.negative_prompt,
        padding_mask_crop: generativeFillSettings.padding_mask_crop,
        auto_patch: generativeFillSettings.auto_patch,
        creativity_strength_preset:
          generativeFillSettings.creativity_strength_preset,
        quality_preset: generativeFillSettings.quality_preset,
      });
    },
    isLoading,
  };
};
