import { GENERATE_TOOL, TRANSFER_TOOL } from "../../../../routes.ts";
import { ToolMenuButton } from "../components/ToolMenu/ToolMenuButton.tsx";
import { ToolMenuTooltipContent } from "../components/ToolMenu/ToolMenuTooltipContent.tsx";

export const CreateToolMenu = () => (
  <div className="h-full flex-col bg-gray-200">
    <ToolMenuButton
      to={GENERATE_TOOL}
      title="Generate"
      iconName="Sparks"
      tooltipContent={
        <ToolMenuTooltipContent
          title="Generate"
          description="Prompt and generate"
          beforeImageUrl="https://storage.googleapis.com/419c45cf-be8a-4cba-bbcd-74a221eb2587/app/assets/navbar/tools/create_1.png"
          afterImageUrl="https://storage.googleapis.com/419c45cf-be8a-4cba-bbcd-74a221eb2587/app/assets/navbar/tools/create_2.png"
          learnMoreLink="https://pimento.crisp.help/en/category/image-generation-5e5027/"
        />
      }
    />
    <ToolMenuButton
      to={TRANSFER_TOOL}
      title="Transfer"
      iconName="StyleTransfer"
      tooltipContent={
        <ToolMenuTooltipContent
          title="Transfer style"
          description="Apply your style to your image"
          beforeImageUrl="https://storage.googleapis.com/419c45cf-be8a-4cba-bbcd-74a221eb2587/app/assets/navbar/tools/transfer_1.png"
          afterImageUrl="https://storage.googleapis.com/419c45cf-be8a-4cba-bbcd-74a221eb2587/app/assets/navbar/tools/transfer_2.png"
          learnMoreLink="https://pimento.crisp.help/en/article/how-to-transfer-your-style-c7v46/"
        />
      }
    />
  </div>
);
