import { useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import { Button } from "../../../components/Button/Button.tsx";
import { Icon } from "../../../components/Icon/Icon.tsx";
import { Image } from "../../../components/Image/Image.tsx";
import { Modal } from "../../../components/Modal/Modal.tsx";
import { useOn } from "../../../hooks/useOn.ts";
import { useAppQuery } from "../../../http/useAppQuery.ts";
import { updateSearch } from "../../../utils/url.ts";
import type { Style } from "../../types.ts";
import { useOpenStyleImageModal } from "./useOpenStyleImageModal.ts";

export const StyleImageModal = () => {
  const [params] = useSearchParams();
  const styleUuid = params.get("styleUuid");
  const imageUuid = params.get("imageUuid");
  const navigate = params.get("navigate") === "true";

  // XXX: In oder to avoid sending query with null style we split this modal into two components, to ensure non
  // nulabillity of styleUuid and imageUuid inputs
  return styleUuid && imageUuid ? (
    <StyleImageModalContainer
      styleUuid={styleUuid}
      imageUuid={imageUuid}
      navigate={navigate}
    />
  ) : null;
};

const StyleImageModalContainer = ({
  styleUuid,
  imageUuid,
  navigate,
}: {
  styleUuid: string;
  imageUuid: string;
  navigate: boolean;
}) => {
  const [, setParams] = useSearchParams();
  const { openStyleImageModal } = useOpenStyleImageModal();

  const { data: style } = useAppQuery<Style>({
    queryKey: `styles/${styleUuid}`,
  });

  const activeImage = useMemo(
    () => style?.training_images.find(({ uuid }) => uuid === imageUuid),
    [style?.training_images, imageUuid],
  );
  const activeIndex = useMemo(
    () =>
      style?.training_images.findIndex(
        ({ uuid }) => uuid === activeImage?.uuid,
      ),
    [style?.training_images, activeImage],
  );

  const isOpened = Boolean(style ?? activeImage);

  const displayPrevious = () => {
    if (navigate && style?.training_images && activeIndex !== undefined) {
      openStyleImageModal(
        style.uuid,
        style.training_images.at(activeIndex - 1)!.uuid,
        true,
      );
    }
  };
  const displayNext = () => {
    if (navigate && style?.training_images && activeIndex !== undefined) {
      openStyleImageModal(
        style.uuid,
        style.training_images[(activeIndex + 1) % style.training_images.length]
          .uuid,
        true,
      );
    }
  };

  const close = () => {
    setParams(
      updateSearch({
        styleUuid: null,
        imageUuid: null,
        navigate: null,
      }),
    );
  };

  useOn("keydown", ({ key }) => {
    if (!(isOpened && navigate)) return;
    if (key === "ArrowLeft") displayPrevious();
    if (key === "ArrowRight") displayNext();
    if (key === "Escape") close();
  });

  return (
    <Modal open={isOpened} onClose={close} className="bg-white/95">
      {activeImage && (
        <div className="flex-row-center relative h-[650px] w-[650px] bg-white">
          <Image
            className="border-[1px] h-full w-full"
            imageClassName="h-full w-full object-contain object-center"
            src={activeImage.url}
          />
        </div>
      )}
      {navigate && (
        <div className="flex-row mt-10 justify-between w-[650px]">
          {/* FIXME: Refactor with icon + button component */}
          <Button onClick={displayPrevious} size="small">
            <div className="flex-row items-center gap-sm text-white">
              <Icon name="ChevronLeft" className="stroke-white" />
              Previous
            </div>
          </Button>
          <Button onClick={displayNext} size="small">
            <div className="flex-row items-center gap-sm text-white">
              Next
              <Icon name="ChevronRight" className="stroke-white" />
            </div>
          </Button>
        </div>
      )}
    </Modal>
  );
};
