import { createStorageAtom } from "../../../../../../atoms/createAtom.ts";

export const useAlwaysShowStyleTransferInfoTooltip = createStorageAtom(
  "always-show-style-transfer-info-tooltip",
  {
    alwaysShowStyleTransferInfoTooltip: true as boolean,
  },
  ({ setter }) => ({
    setAlwaysShowStyleTransferInfoTooltip: setter(
      "alwaysShowStyleTransferInfoTooltip",
    ),
  }),
);
