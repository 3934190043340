import classNames from "classnames";
import type { ReactNode } from "react";
import { NavLink } from "react-router-dom";
import { Icon, type IconName } from "../../../../../components/Icon/Icon.tsx";
import { Tooltip } from "../../../../../components/Tooltip/Tooltip.tsx";
import { applyCurrentSearchParamsToPath } from "../../../../../utils/url.ts";

export const ToolMenuButton = ({
  to,
  iconName,
  title,
  tooltipContent,
}: {
  to: string;
  iconName: IconName;
  title: string;
  tooltipContent: ReactNode;
}) => (
  <Tooltip
    side="right"
    align="start"
    arrowDisplayed
    delayDuration={500}
    content={tooltipContent}
    className="!bg-gray-600 !rounded-md"
  >
    <div>
      <NavLink
        to={applyCurrentSearchParamsToPath(to)}
        className={({ isActive }) =>
          classNames(
            "flex-col-center w-[72px] h-[64px] gap-md",
            isActive ? "bg-white" : "hover:bg-gray-300",
          )
        }
      >
        <Icon name={iconName} size={20} />
        <span>{title}</span>
      </NavLink>
    </div>
  </Tooltip>
);
