import { useAppMutation } from "../../../../../../../http/useAppMutation.ts";
import type { Board } from "../../../../../types.ts";
import { useUpscaleSettings } from "./useUpscaleSettings.ts";

export const useUpscaleImageGeneration = ({
  board,
  onSuccess,
}: {
  board: Board;
  onSuccess?: () => void;
}) => {
  const generateUpscaleImage = useAppMutation({
    path: "boards/upscale-image",
    invalidate: [`boards/${board.uuid}`, "boards", "users/me"],
    onSuccess,
  }).mutation;

  const { upscaleSettings } = useUpscaleSettings(board.uuid);

  return {
    mutation: ({
      prompt,
      styleUuid,
      assetUuid,
    }: {
      prompt: string;
      styleUuid: string;
      assetUuid: string;
    }) => {
      generateUpscaleImage.mutate({
        board_uuid: board.uuid,
        prompt,
        style_uuid: styleUuid,
        asset_uuid: assetUuid,
        imagination_strength_preset:
          upscaleSettings.imagination_strength_preset,
        resemblance:
          upscaleSettings.imagination_strength_preset === "custom"
            ? upscaleSettings.resemblance
            : undefined,
        creativity:
          upscaleSettings.imagination_strength_preset === "custom"
            ? upscaleSettings.creativity
            : undefined,
        scale_factor: upscaleSettings.scale_factor,
        negative_prompt: upscaleSettings.negative_prompt,
        lora_scale:
          upscaleSettings.imagination_strength_preset === "custom"
            ? upscaleSettings.lora_scale
            : undefined,
      });
    },
    isLoading: generateUpscaleImage.isLoading,
  };
};
