import { createAtom } from "../../../atoms/createAtom.ts";

export const useShowDeletedAssets = createAtom(
  {
    showDeletedAssets: false,
  },
  ({ get, set }) => ({
    toggleShowDeletedAssets: () =>
      set({
        showDeletedAssets: !get().showDeletedAssets,
      }),
  }),
);
