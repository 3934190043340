import classNames from "classnames";
import { type ButtonHTMLAttributes, forwardRef, type Ref } from "react";
import { Spinner } from "../Spinner/Spinner.tsx";
import { Tooltip, type TooltipProps } from "../Tooltip/Tooltip.tsx";
import styles from "./clickableIcon.module.css";
import { Icon, type IconName } from "./Icon.tsx";

export const ClickableIcon = forwardRef(
  (
    {
      name,
      iconClassName,
      size,
      rotate,
      tooltip,
      type = "button",
      className,
      isLoading = false,
      isDisabled = false,
      ...rest
    }: ButtonHTMLAttributes<HTMLButtonElement> & {
      name: IconName;
      iconClassName?: string;
      size?: number;
      rotate?: number;
      tooltip?: Omit<TooltipProps, "children">;
      isLoading?: boolean;
      isDisabled?: boolean;
    },
    ref: Ref<HTMLButtonElement>,
  ) => {
    if (!tooltip) {
      return (
        <IconButton
          ref={ref}
          type={type}
          isLoading={isLoading}
          isDisabled={isDisabled}
          className={className}
          iconClassName={iconClassName}
          name={name}
          size={size}
          rotate={rotate}
          {...rest}
        />
      );
    }
    return (
      <Tooltip {...tooltip}>
        <IconButton
          ref={ref}
          type={type}
          isLoading={isLoading}
          isDisabled={isDisabled}
          className={className}
          iconClassName={iconClassName}
          name={name}
          size={size}
          rotate={rotate}
          {...rest}
        />
      </Tooltip>
    );
  },
);

const IconButton = forwardRef(
  (
    {
      name,
      className,
      iconClassName,
      type = "button",
      size,
      rotate,
      isLoading = false,
      isDisabled = false,
      ...rest
    }: ButtonHTMLAttributes<HTMLButtonElement> & {
      name: IconName;
      className?: string;
      iconClassName?: string;
      size?: number;
      rotate?: number;
      isLoading: boolean;
      isDisabled: boolean;
    },
    ref: Ref<HTMLButtonElement>,
  ) => (
    <button
      ref={ref}
      type={type}
      disabled={isLoading || isDisabled}
      className={classNames(styles["button"], "disabled:opacity-30", className)}
      {...rest}
    >
      {isLoading ? (
        <Spinner small inline currentColor />
      ) : (
        <Icon
          name={name}
          size={size}
          rotate={rotate}
          className={iconClassName}
        />
      )}
    </button>
  ),
);
