import { createAtom } from "../../../atoms/createAtom.ts";

export const useSelectedStyleUuid = createAtom(
  {
    selectedStyleUuid: null as string | null,
  },
  ({ set }) => ({
    setSelectedStyleUuid: (styleUuid: string | null) =>
      set({
        selectedStyleUuid: styleUuid,
      }),
    clearSelectedStyleUuid: () =>
      set({
        selectedStyleUuid: null,
      }),
  }),
);
