import { BaseButton } from "../../../components/Button/BaseButton.tsx";
import { Icon } from "../../../components/Icon/Icon.tsx";
import { useIsUpgradeDialogOpen } from "../../../singletons/UpgradeDialog/hooks/useIsUpgradeDialogOpen.ts";
import { useCreateStyle } from "../hooks/useCreateStyle.ts";

export const NewStyleTile = () => {
  const { setIsUpgradeDialogOpen } = useIsUpgradeDialogOpen();
  const { createStyle, isCreateStyleLoading } = useCreateStyle({
    onStyleCreationForbidden: () => setIsUpgradeDialogOpen(true),
  });

  return (
    <BaseButton
      className="rounded-xs border group flex-col-center items-center gap-md hover:bg-gray-100 hover:border-gray-350"
      style={{ height: 220, width: 180 }}
      onClick={createStyle}
      loading={isCreateStyleLoading}
    >
      <Icon name="Plus" className="stroke-xl" size={24} />
      <div className="h-2xl font-semibold">Add new style</div>
    </BaseButton>
  );
};
