export const Sparks = ({ className }: { className?: string }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
    className={className}
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M17 5.25c0 1.147-1.103 2.25-2.25 2.25C15.897 7.5 17 8.603 17 9.75c0-1.147 1.103-2.25 2.25-2.25C18.103 7.5 17 6.397 17 5.25zM17 15.25c0 1.147-1.103 2.25-2.25 2.25 1.147 0 2.25 1.103 2.25 2.25 0-1.147 1.103-2.25 2.25-2.25-1.147 0-2.25-1.103-2.25-2.25zM9 8.25c0 2.167-2.083 4.25-4.25 4.25C6.917 12.5 9 14.583 9 16.75c0-2.167 2.083-4.25 4.25-4.25C11.083 12.5 9 10.417 9 8.25z"
    />
  </svg>
);
