import { useEffect, useState } from "react";
import { Button } from "../../../../../../components/Button/Button.tsx";
import { DescriptionLoader } from "../../../../components/DescriptionLoader.tsx";
import { useGetSelectedAsset } from "../../../../hooks/useGetSelectedAsset.ts";
import { useSelectedStyleUuid } from "../../../../hooks/useSelectedStyleUuid.ts";
import type { Board } from "../../../../types.ts";
import { scrollImageStripeToStart } from "../../ImageStripe/hooks/scrollImageStripeToStart.ts";
import { useUpscaleImageGeneration } from "./hooks/useUpscale.ts";

export const UpscaleGenerationButton = ({
  board,
  prompt,
}: {
  board: Board;
  prompt: string;
}) => {
  const [isWaitingForDescription, setIsWaitingForDescription] = useState(false);
  const selectedAsset = useGetSelectedAsset(board);
  const {
    mutation: generateEnhancedImage,
    isLoading: isEnhancedImageGenerationLoading,
  } = useUpscaleImageGeneration({
    board,
    onSuccess: () => {
      scrollImageStripeToStart();
    },
  });

  useEffect(() => {
    // XXX: we add prompt in the condition is case the image is never described
    if (selectedAsset === undefined || prompt) {
      setIsWaitingForDescription(false);
    } else {
      setIsWaitingForDescription(true);
    }
  }, [selectedAsset, prompt]);

  const { selectedStyleUuid } = useSelectedStyleUuid();

  return (
    <div className="flex-row-center py-2xl px-lg border-t">
      <Button
        className="w-full"
        onClick={() => {
          if (selectedAsset && selectedStyleUuid) {
            // FIXME: Instead of providing the description of the image, we should remove the prompt parameter and
            //  leaves backend handle it with the image uuid
            generateEnhancedImage({
              prompt,
              styleUuid: selectedStyleUuid,
              assetUuid: selectedAsset.uuid,
            });
          }
        }}
        disabled={
          !selectedAsset ||
          selectedAsset.image.status !== "succeeded" ||
          !selectedStyleUuid
        }
        loading={isEnhancedImageGenerationLoading || isWaitingForDescription}
        loadingContent={
          isWaitingForDescription ? <DescriptionLoader /> : undefined
        }
      >
        Upscale
      </Button>
    </div>
  );
};
