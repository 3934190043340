import { PresetButton } from "../../../../components/PresetButton.tsx";
import type { Board } from "../../../../types.ts";
import { IMAGINATION_STRENGTH_PRESET } from "./constants.ts";
import { useUpscaleSettings } from "./hooks/useUpscaleSettings.ts";

export const ImaginationStrengthSection = ({ board }: { board: Board }) => {
  const { upscaleSettings, setUpscaleSettings } = useUpscaleSettings(
    board.uuid,
  );

  return (
    <div className="flex-row-center gap-sm">
      {IMAGINATION_STRENGTH_PRESET.map((preset) => (
        <PresetButton
          key={preset}
          isSelected={upscaleSettings.imagination_strength_preset === preset}
          onClick={() =>
            setUpscaleSettings({ imagination_strength_preset: preset })
          }
        >
          {preset}
        </PresetButton>
      ))}
    </div>
  );
};
