import { useEffect, useState } from "react";
import { BaseButton } from "../../../../../../components/Button/BaseButton.tsx";
import { useGetSelectedAsset } from "../../../../hooks/useGetSelectedAsset.ts";
import type { Board } from "../../../../types.ts";
import { clearObjects } from "../../components/BaseEditor/utils.ts";
import { useGenerativeFillCanvas } from "./hooks/useGenerativeFillCanvas.ts";

export const GenerativeFillToolbar = ({ board }: { board: Board }) => {
  const { fabricCanvas, numObjectsCreated } = useGenerativeFillCanvas();

  const [isEmpty, setIsEmpty] = useState<boolean>(false);

  //  XXX: Instead of using directly the numObjectsCreated, we rather check directly if the canvas is empty to avoid
  //  any potential side effects when mixing history modifications and object creations.
  useEffect(() => {
    setIsEmpty(fabricCanvas?.isEmpty() ?? false);
  }, [numObjectsCreated, fabricCanvas]);

  const selectedImage = useGetSelectedAsset(board);

  return !(fabricCanvas && selectedImage) ? null : (
    <BaseButton
      className="h-full flex-row-center hover:text-pimento-blue disabled:text-gray-350"
      onClick={() => {
        clearObjects(fabricCanvas);
        // @ts-expect-error history.js is not typed
        fabricCanvas.clearHistory();
      }}
      disabled={isEmpty}
    >
      Clear
    </BaseButton>
  );
};
