import { DIMENSIONS_RATIOS } from "../../../../../constants.ts";
import type { Board } from "../../../../../types.ts";
import { CollapsableSettingsSectionWrapper } from "../../../../components/SettingsMenu/CollapsableSettingsSectionWrapper.tsx";
import { QualityPresetWrappedSection } from "../../../../components/SettingsMenu/QualityPresetWrappedSection.tsx";
import { RatioSection } from "../../../../components/SettingsMenu/RatioSection.tsx";
import { StyleIntensitySection } from "../../../../components/SettingsMenu/StyleIntensitySection.tsx";
import { StyleWrappedSection } from "../../../../components/SettingsMenu/StyleWrappedSection.tsx";
import { SwitchSection } from "../../../../components/SettingsMenu/SwitchSection.tsx";
import { TextSection } from "../../../../components/SettingsMenu/TextSection.tsx";
import { SettingsMenuLayout } from "../../../../components/SettingsMenuLayout.tsx";
import {
  useGenerateSettings,
  useGenerateSettingsAtom,
} from "../hooks/useGenerateSettings.ts";
import { GenerateGenerationBar } from "./GenerateGenerationBar.tsx";

export const GenerateSettings = ({ board }: { board: Board }) => {
  const { generateSettings, defaultGenerateSettings } = useGenerateSettings(
    board.uuid,
  );

  return (
    <SettingsMenuLayout
      body={
        <div className="flex-col">
          <StyleWrappedSection board={board} />
          <QualityPresetWrappedSection
            value={generateSettings.quality_preset}
            onChange={(preset) =>
              useGenerateSettingsAtom
                .getState()
                .updateGenerateSettings(board.uuid, { quality_preset: preset })
            }
          />
          <CollapsableSettingsSectionWrapper
            name="Advanced"
            content={
              <div className="flex-col">
                <StyleIntensitySection
                  defaultLoraScale={defaultGenerateSettings.lora_scale}
                  loraScale={generateSettings.lora_scale}
                  onLoraScaleChange={(loraScale: number) =>
                    useGenerateSettingsAtom
                      .getState()
                      .updateGenerateSettings(board.uuid, {
                        lora_scale: loraScale,
                      })
                  }
                />
                <RatioSection
                  ratio={generateSettings.ratio}
                  onRatioChange={(ratio) =>
                    useGenerateSettingsAtom
                      .getState()
                      .updateGenerateSettings(board.uuid, {
                        ratio,
                        width:
                          DIMENSIONS_RATIOS[generateSettings.dimension][ratio]
                            .width,
                        height:
                          DIMENSIONS_RATIOS[generateSettings.dimension][ratio]
                            .height,
                      })
                  }
                />
                <TextSection
                  title="Exclude"
                  valuePlaceholder="illustration, wonderful chilli pepper, vivid colors..."
                  value={generateSettings.negative_prompt}
                  onValueChange={(negativePrompt: string) =>
                    useGenerateSettingsAtom
                      .getState()
                      .updateGenerateSettings(board.uuid, {
                        negative_prompt: negativePrompt,
                      })
                  }
                />
                <SwitchSection
                  switchName="Prompt enhancement"
                  switchInformationSection="When activated, your prompt will be automatically modified to improve your results. You can see your modified prompt by looking at the infos of your generated image."
                  checked={generateSettings.prompt_enhancement}
                  onCheckedChange={(promptEnhancement: boolean) =>
                    useGenerateSettingsAtom
                      .getState()
                      .updateGenerateSettings(board.uuid, {
                        prompt_enhancement: promptEnhancement,
                      })
                  }
                />
              </div>
            }
          />
        </div>
      }
      footer={<GenerateGenerationBar board={board} />}
    />
  );
};
