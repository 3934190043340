import { enableMapSet, produce } from "immer";
import { type StoreApi, create as zustandCreate } from "zustand";
import { combine } from "zustand/middleware";

enableMapSet();

export const createAtom = <S1 extends object, S2 extends object>(
  initialState: S1,
  create: (props: {
    set: StoreApi<S1>["setState"];
    get: () => S1;
    api: StoreApi<S1>;
    update: (fn: (draft: S1) => void) => void;
    setter: <K extends keyof S1>(key: K) => (value: S1[K]) => void;
  }) => S2,
) =>
  zustandCreate(
    combine(initialState, (set, get, api) =>
      create({
        set,
        get,
        api,
        update: (fn) => set(produce(fn) as (state: S1) => S1),
        setter: (key) => (value) =>
          // @ts-expect-error When evaluating as an object key, keyof S1 becomes string 😥
          set({ [key]: value }),
      }),
    ),
  );

export type Serializable =
  | null
  | number
  | boolean
  | string
  | SerializableRecord
  | SerializableArray;
// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
export interface SerializableRecord
  extends Record<string, Serializable | undefined> {}
// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
export interface SerializableArray extends Array<Serializable> {}

export const createStorageAtom = <
  S1 extends SerializableRecord,
  S2 extends object,
>(
  key: string,
  initialState: S1,
  create: (props: {
    set: (v: Partial<S1>) => void;
    get: () => S1;
    update: (fn: (draft: S1) => void) => void;
    setter: <K extends keyof S1>(key: K) => (value: S1[K]) => void;
  }) => S2,
) => {
  const init: { state: S1 } = localStorage
    .getItem(key)
    ?.let((it) => ({ state: JSON.parse(it) })) ?? { state: initialState };

  return zustandCreate(
    combine(init, (_set, get) => {
      const set = (payload: Partial<S1>) => {
        const newState = { ...get().state, ...payload };
        _set({ state: newState });
        const stringified = JSON.stringify(newState);
        if (stringified === localStorage.getItem(key)) return;
        localStorage.setItem(key, stringified);
      };
      return create({
        set,
        get: () => get().state,
        update: (fn) => set(produce(get().state, fn)),
        setter: (field) => (value) =>
          // @ts-expect-error When evaluating as an object key, keyof S1 becomes string 😥
          set({ [field]: value }),
      });
    }),
  );
};
