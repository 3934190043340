export const Upscale = ({ className }: { className?: string }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="22"
    height="22"
    viewBox="0 0 24 24"
    fill="none"
    className={className}
  >
    <path
      d="M13.03 10H3.43002C2.6726 10 2.05859 10.597 2.05859 11.3333V20.6667C2.05859 21.403 2.6726 22 3.43002 22H13.03C13.7874 22 14.4015 21.403 14.4015 20.6667V11.3333C14.4015 10.597 13.7874 10 13.03 10Z"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.17221 15.3327C6.92963 15.3327 7.54364 14.7357 7.54364 13.9993C7.54364 13.263 6.92963 12.666 6.17221 12.666C5.41479 12.666 4.80078 13.263 4.80078 13.9993C4.80078 14.7357 5.41479 15.3327 6.17221 15.3327Z"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.399 18.0004L12.2829 15.9431C12.0257 15.6932 11.6769 15.5527 11.3133 15.5527C10.9496 15.5527 10.6009 15.6932 10.3437 15.9431L4.11328 22.0004"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.9729 21.9993H19.7348C20.9551 21.9993 21.9443 20.5191 21.9443 19.3327V12.2511V5.16944C21.9443 3.98306 20.9551 2.66602 19.7348 2.66602H12.0015H4.26812C3.04783 2.66602 2.05859 3.62777 2.05859 4.81416V13.3327"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeDasharray="5 5"
    />
  </svg>
);
