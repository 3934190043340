import { Icon, type IconName } from "../../../../../components/Icon/Icon.tsx";
import type { ImageContent } from "../../../types.ts";

export const InformationHeader = ({ image }: { image: ImageContent }) => {
  switch (image.generation_model_type) {
    case "text_to_image":
      return (
        <GenerationHeader
          generationType="Generate"
          iconName="Sparks"
          createdAt={image.created_at}
        />
      );
    case "image_to_image":
      return (
        <GenerationHeader
          generationType="Variations"
          iconName="Layers"
          createdAt={image.created_at}
        />
      );
    case "style_transfer":
      return (
        <GenerationHeader
          generationType="Style transfer"
          iconName="StyleTransfer"
          createdAt={image.created_at}
        />
      );
    case "upscaler":
      return (
        <GenerationHeader
          generationType="Upscale"
          iconName="Upscale"
          createdAt={image.created_at}
        />
      );
    case "generative_fill":
      return (
        <GenerationHeader
          generationType="Fill"
          iconName="Select"
          createdAt={image.created_at}
        />
      );
    case null:
      return (
        <GenerationHeader
          generationType="Not generated content"
          createdAt={image.created_at}
        />
      );
  }
};

const GenerationHeader = ({
  iconName,
  generationType,
  createdAt,
}: {
  iconName?: IconName;
  generationType: string;
  createdAt: string;
}) => (
  <div className="flex-row h-xl items-center justify-between">
    <div className="flex-row items-center gap-sm">
      {iconName && (
        <Icon name={iconName} className="stroke-pimento-blue stroke-xl" />
      )}
      <span className="text-pimento-blue font-semibold">{generationType}</span>
    </div>
    <div className="text-gray-400">
      {new Date(createdAt).getDate()}/{new Date(createdAt).getMonth() + 1}/
      {new Date(createdAt).getFullYear()}
    </div>
  </div>
);
