export const ImageStripe = ({ className }: { className?: string }) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M5.10254 10C5.10254 9.17157 5.77411 8.5 6.60254 8.5H25.3971C26.2255 8.5 26.8971 9.17157 26.8971 10V13.8904C26.8971 14.7188 26.2255 15.3904 25.3971 15.3904H6.60254C5.77411 15.3904 5.10254 14.7188 5.10254 13.8904V10Z"
      fill="none"
    />
    <path d="M5.10254 18.9844C5.10254 18.1559 5.77411 17.4844 6.60254 17.4844H9.61624C10.4447 17.4844 11.1162 18.1559 11.1162 18.9844V21.9981C11.1162 22.8265 10.4447 23.4981 9.61624 23.4981H6.60254C5.77411 23.4981 5.10254 22.8265 5.10254 21.9981V18.9844Z" />
    <path d="M12.9932 18.9844C12.9932 18.1559 13.6647 17.4844 14.4932 17.4844H17.5069C18.3353 17.4844 19.0069 18.1559 19.0069 18.9844V21.9981C19.0069 22.8265 18.3353 23.4981 17.5069 23.4981H14.4932C13.6647 23.4981 12.9932 22.8265 12.9932 21.9981V18.9844Z" />
    <path d="M20.8833 18.9844C20.8833 18.1559 21.5549 17.4844 22.3833 17.4844H25.397C26.2254 17.4844 26.897 18.1559 26.897 18.9844V21.9981C26.897 22.8265 26.2254 23.4981 25.397 23.4981H22.3833C21.5549 23.4981 20.8833 22.8265 20.8833 21.9981V18.9844Z" />
  </svg>
);
