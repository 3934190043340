import { fabric } from "fabric";
import { useRef } from "react";
import { useOn } from "../../../../../../hooks/useOn.ts";
import { BaseEditor } from "../BaseEditor/BaseEditor.tsx";
import { deleteSelectedObjects } from "../BaseEditor/utils.ts";

export const ImageEditor = ({
  fabricCanvas,
  onCanvasReady,
  initWidth,
  initHeight,
  backgroundImageUrl,
  maxWidth,
  maxHeight,
}: {
  fabricCanvas?: fabric.Canvas;
  initWidth: number;
  initHeight: number;
  onCanvasReady: (canvas: fabric.Canvas) => void;
  maxWidth: number;
  maxHeight: number;
  backgroundImageUrl?: string;
}) => {
  const copiedObject = useRef<fabric.Object | fabric.ActiveSelection>();

  useOn("keydown", ({ key, metaKey, ctrlKey }) => {
    if (fabricCanvas === undefined) return;
    if (["Backspace", "Delete"].includes(key)) {
      deleteSelectedObjects(fabricCanvas);
    }
    // from http://fabricjs.com/copypaste
    if (key === "c" && (ctrlKey || metaKey)) {
      fabricCanvas
        .getActiveObject()
        ?.clone((cloned: fabric.Object | fabric.ActiveSelection) => {
          copiedObject.current = cloned;
        });
    }
    if (key === "v" && (ctrlKey || metaKey) && copiedObject.current) {
      copiedObject.current.top = (copiedObject.current.top ?? 0) + 10;
      copiedObject.current.left = (copiedObject.current.left ?? 0) + 10;
      // XXX: clone again, so you can do multiple copies.
      copiedObject.current.clone(
        (clonedObject: fabric.Object | fabric.ActiveSelection) => {
          fabricCanvas.discardActiveObject();
          if (clonedObject instanceof fabric.ActiveSelection) {
            // XXX: active selection needs a reference to the canvas.
            clonedObject.canvas = fabricCanvas;
            clonedObject.forEachObject((obj: fabric.Object) => {
              fabricCanvas.add(obj);
            });
          } else {
            fabricCanvas.add(clonedObject);
          }
          fabricCanvas.setActiveObject(clonedObject);
        },
      );
    }
  });

  return (
    <BaseEditor
      fabricCanvas={fabricCanvas}
      initWidth={initWidth}
      initHeight={initHeight}
      onCanvasReady={onCanvasReady}
      backgroundImageUrl={backgroundImageUrl}
      maxWidth={maxWidth}
      maxHeight={maxHeight}
    />
  );
};
