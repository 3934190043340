import type { ReactNode } from "react";
import { useNonAuthAppQuery } from "../../http/useNonAuthAppQuery.ts";
import { QueryResult } from "./QueryResult.tsx";

export const NonAuthQuery = ({
  children,
  queryKey,
}: {
  children: (output: any) => ReactNode | null;
  queryKey: string;
}) => {
  const query = useNonAuthAppQuery(queryKey);

  return <QueryResult query={query}>{children}</QueryResult>;
};
