import classNames from "classnames";
import { Image } from "../../../components/Image/Image.tsx";
import type { Style, StyleImage } from "../../types.ts";

export const StylePreview = ({
  style,
  title,
}: {
  style: Style;
  title: string;
}) => (
  <div className="flex-col gap-3xl items-center">
    {style.training_images.length > 3 && (
      <div className="relative flex-row h-[17Opx] w-[170px] aspect-square">
        <div
          style={{
            transform: "translateY(12%) translateX(110%) rotate(4deg)",
          }}
          className="absolute h-5/6 aspect-square shadow-md rounded-sm"
        >
          <TrainingImage image={style.training_images[0]} />
        </div>
        <div
          style={{
            transform: "translateY(12%) translateX(-90%) rotate(-4deg)",
          }}
          className="absolute h-5/6 aspect-square shadow-md rounded-sm"
        >
          <TrainingImage image={style.training_images[1]} />
        </div>
        <div className="absolute h-full aspect-square shadow-md rounded-sm">
          <TrainingImage image={style.training_images[2]} />
        </div>
      </div>
    )}
    <div className="text-[38px] text-gray-900 font-burg">{title}</div>
  </div>
);

const TrainingImage = ({ image }: { image: StyleImage }) => (
  <div className={classNames("h-full w-full overflow-clip rounded-sm")}>
    <Image
      className="h-full w-full"
      imageClassName="h-full w-full object-cover object-center"
      src={image.url}
    />
  </div>
);
