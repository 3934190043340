import { fabric } from "fabric";
import { useState } from "react";
import { SizeObservableWrapper } from "../../../../../../components/SizeObservableWrapper/SizeObservableWrapper.tsx";
import { useGetSelectedAsset } from "../../../../hooks/useGetSelectedAsset.ts";
import type { Board } from "../../../../types.ts";
import { BaseImageContainer } from "../../components/ImageContainer/BaseImageContainer.tsx";
import { MaskEditor } from "../../components/MaskEditor/MaskEditor.tsx";

export const GenerativeFillTool = ({
  board,
  fabricCanvas,
  onCanvasReady,
}: {
  board: Board;
  fabricCanvas?: fabric.Canvas;
  onCanvasReady: (canvas: fabric.Canvas) => void;
}) => {
  const selectedAsset = useGetSelectedAsset(board);

  const [maxSize, setMaxSize] = useState<{ width: number; height: number }>();

  return (
    <SizeObservableWrapper
      className="flex-fill w-full flex-col-center"
      onSizeChange={({ width, height }) => setMaxSize({ width, height })}
    >
      <BaseImageContainer board={board} image={selectedAsset?.image}>
        {/* FIXME : compute the wanted initial size to avoid image flickering */}
        {maxSize !== undefined && (
          <MaskEditor
            initWidth={10}
            initHeight={10}
            fabricCanvas={fabricCanvas}
            onCanvasReady={(canvas) => {
              onCanvasReady(canvas);
            }}
            backgroundImageUrl={selectedAsset?.image.url}
            maxWidth={maxSize.width}
            maxHeight={maxSize.height}
          />
        )}
      </BaseImageContainer>
    </SizeObservableWrapper>
  );
};
