import { useGetSelectedAsset } from "../../../hooks/useGetSelectedAsset.ts";
import type { Board } from "../../../types.ts";

export const useGetSelectedAssetInitImageUuid = (board: Board) => {
  const selectedAsset = useGetSelectedAsset(board);

  return selectedAsset &&
    selectedAsset.image.status === "succeeded" &&
    (selectedAsset.image.generation_model_type === "upscaler" ||
      selectedAsset.image.generation_model_type === "image_to_image" ||
      selectedAsset.image.generation_model_type === "style_transfer" ||
      selectedAsset.image.generation_model_type === "generative_fill")
    ? selectedAsset.image.generation_model_params.init_image_uuid
    : undefined;
};
