import { useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useSetAppInitialState } from "../../../hooks/useAppInitialState.ts";
import { useUser } from "../../../hooks/useUser.ts";
import {
  BOARDS,
  CREATE_WORKSPACE,
  DRAW_TOOL_PATH,
  GENERATE_TOOL_PATH,
  SELECT_TOOL_PATH,
  TRANSFER_TOOL_PATH,
  UPSCALE_TOOL_PATH,
  VARIATION_TOOL_PATH,
} from "../../../routes.ts";
import { useIsUpgradeDialogOpen } from "../../../singletons/UpgradeDialog/hooks/useIsUpgradeDialogOpen.ts";
import { isUserAllowedTo } from "../../../types.ts";
import { useCreateBoard } from "../hooks/useCreateBoard.ts";
import { useCreateStyle } from "../hooks/useCreateStyle.ts";
import { ActionTileButton } from "./ActionTileButton.tsx";
import type { HomepageAction } from "./types.ts";

export const ActionsSection = () => {
  const { user } = useUser();
  // XXX: We use ref and not local state here to avoid having rendering order issue. The actionSelected state is
  // not necessary updated otherwise when used in the onSuccess that can lead to unexpected behavior.
  const actionSelected = useRef<HomepageAction | null>(null);
  const navigate = useNavigate();
  const { setIsUpgradeDialogOpen } = useIsUpgradeDialogOpen();
  const setAppInitialState = useSetAppInitialState();

  const { createBoard } = useCreateBoard({
    onBoardCreationForbidden: () => setIsUpgradeDialogOpen(true),
    onSuccess: (uuid) => {
      if (actionSelected.current === null) return;
      setAppInitialState(actionSelected.current.state);
      navigate(`${BOARDS}/${uuid}/${actionSelected.current.route}`);
    },
  });

  return (
    <div className="px-3xl flex-col gap-lg">
      <div className="text-2xl font-semibold">Get started</div>
      <div className="flex-row gap-2xl">
        <div className="flex-col border-r gap-2xl">
          <NewBoardButton />
          <NewStyleButton />
        </div>
        <div className="flex-fill flex-row flex-wrap items-center gap-2xl">
          {CreateActions.filter(
            (action) =>
              action.route !== DRAW_TOOL_PATH ||
              (user && isUserAllowedTo(user, "draw:create")),
          ).map((action, index) => (
            <ActionTileButton
              key={index}
              title={action.title}
              description={action.description}
              iconName={action.iconName}
              onClick={() => {
                actionSelected.current = action;
                createBoard();
              }}
            />
          ))}
        </div>
      </div>
    </div>
  );
};
const CreateActions: HomepageAction[] = [
  {
    title: "Text-to-image",
    description: "Bring ideas to life",
    iconName: "Sparks",
    state: "generateFromText",
    route: GENERATE_TOOL_PATH,
  },
  {
    title: "Upscale & Enhance",
    description: "Better quality and more details",
    iconName: "Upscale",
    state: "upscaleAndEnhance",
    route: UPSCALE_TOOL_PATH,
  },
  {
    title: "Style transfer",
    description: "Restyle any image",
    iconName: "StyleTransfer",
    state: "transferStyle",
    route: TRANSFER_TOOL_PATH,
  },
  {
    title: "Generative fill",
    description: "Locally edit your image",
    iconName: "Select",
    state: "editSelection",
    route: SELECT_TOOL_PATH,
  },
  {
    title: "Image variations",
    description: "Iterate easily on your image",
    iconName: "Layers",
    state: "getVariations",
    route: VARIATION_TOOL_PATH,
  },
];

const NewBoardButton = () => {
  const navigate = useNavigate();
  const { createBoard, isCreateBoardLoading } = useCreateBoard({
    onBoardCreationForbidden: () => setIsUpgradeDialogOpen(true),
    onSuccess: (uuid) => navigate(`${BOARDS}/${uuid}/${CREATE_WORKSPACE}`),
  });

  const { setIsUpgradeDialogOpen } = useIsUpgradeDialogOpen();
  return (
    <ActionTileButton
      title="Create a new project"
      iconName="Plus"
      onClick={() => createBoard()}
      isLoading={isCreateBoardLoading}
    />
  );
};

const NewStyleButton = () => {
  const { setIsUpgradeDialogOpen } = useIsUpgradeDialogOpen();
  const { createStyle, isCreateStyleLoading } = useCreateStyle({
    onStyleCreationForbidden: () => {
      setIsUpgradeDialogOpen(true);
    },
  });
  return (
    <ActionTileButton
      title="Build your own style"
      iconName="Palette"
      onClick={() => createStyle()}
      isLoading={isCreateStyleLoading}
    />
  );
};
