import { forwardRef, type ReactNode, type Ref, useState } from "react";
import { ClickableIcon } from "../../../../../../components/Icon/ClickableIcon.tsx";
import type { IconName } from "../../../../../../components/Icon/Icon.tsx";
import { Popover } from "../../../../../../components/Popover/Popover.tsx";
import type { TooltipProps } from "../../../../../../components/Tooltip/Tooltip.tsx";

export const ToolbarIconButton = forwardRef(
  (
    {
      iconName,
      onClick,
      tooltip,
      isSelected,
      isLoading,
      isDisabled,
      optionContent,
    }: {
      iconName: IconName;
      onClick?: () => void;
      tooltip?: Omit<TooltipProps, "children">;
      isSelected?: boolean;
      isLoading?: boolean;
      isDisabled?: boolean;
      optionContent?: ReactNode;
    },
    ref: Ref<HTMLButtonElement>,
  ) => {
    const [isOptionOpen, setIsOptionOpen] = useState(false);

    const iconClassName = isSelected
      ? "stroke-active-button stroke-xl"
      : isOptionOpen
      ? "stroke-active-button stroke-xl"
      : isDisabled
      ? "stroke-gray-350"
      : "hover:stroke-pimento-blue";

    return (
      <div className="flex-row-center h-full">
        <ClickableIcon
          name={iconName}
          ref={ref}
          onClick={onClick}
          tooltip={{ side: "bottom", ...tooltip }}
          isLoading={isLoading}
          isDisabled={isDisabled}
          iconClassName={iconClassName}
          className="h-full p-0"
          size={18}
        />
        {optionContent !== undefined && (
          <Popover
            isOpen={isOptionOpen}
            onOpenChange={setIsOptionOpen}
            content={optionContent}
            side="bottom"
            className="mt-4 !p-0"
          >
            <ClickableIcon
              name="ChevronDown"
              iconClassName={iconClassName}
              isDisabled={isDisabled}
              className="h-full px-xs"
              size={10}
              rotate={isOptionOpen ? 180 : undefined}
            />
          </Popover>
        )}
      </div>
    );
  },
);
