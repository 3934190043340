import { useRef } from "react";
import { useAppMutation } from "../../../../../../../http/useAppMutation.ts";
import { useUploadImage } from "../../../../../hooks/useUploadImage.ts";
import { useMagicDrawSettings } from "./useMagicDrawSettings.ts";

export const useUploadAndGenerateMagicDraw = ({
  boardUuid,
  onSuccess,
}: {
  boardUuid: string;
  onSuccess?: () => void;
}) => {
  // XXX: We use refs to store user inputs and first mutation inputs so we can be able to use them in the following
  // mutations (upload and generation mutations) called on successful upload.
  // FIXME: Replace this triple mutation by a single chain of async mutations for better readability
  const generationPrompt = useRef("");
  const generationImage = useRef<Blob>();
  const generationMaskUuid = useRef<string>();
  const generationWidth = useRef<number>();
  const generationHeight = useRef<number>();
  const generationStyleUuid = useRef<string>();
  const generationAssetUuid = useRef<string>();
  const { mutate: generateMagicDraw, isLoading: isGenerationLoading } =
    useInpaintingMutation({
      boardUuid,
      onSuccess: () => {
        onSuccess?.();
      },
    });

  const { mutate: uploadMask, isLoading: isMaskLoading } = useUploadImage({
    onSuccess: (uuid) => {
      generationMaskUuid.current = uuid;
      if (generationImage.current) {
        uploadImage({ image: generationImage.current });
      }
    },
  });

  const { mutate: uploadImage, isLoading: isImageLoading } = useUploadImage({
    onSuccess: (uuid) => {
      if (
        generationWidth.current &&
        generationHeight.current &&
        generationMaskUuid.current &&
        generationStyleUuid.current &&
        generationAssetUuid.current
      ) {
        generateMagicDraw({
          prompt: generationPrompt.current,
          maskUuid: generationMaskUuid.current,
          width: generationWidth.current,
          height: generationHeight.current,
          initImageUuid: uuid,
          styleUuid: generationStyleUuid.current,
          assetUuid: generationAssetUuid.current,
        });
      }
    },
  });

  return {
    mutate: ({
      image,
      prompt,
      width,
      height,
      mask,
      styleUuid,
      assetUuid,
    }: {
      image: Blob;
      prompt: string;
      mask: Blob;
      width: number;
      height: number;
      styleUuid: string;
      assetUuid: string;
    }) => {
      generationPrompt.current = prompt;
      generationWidth.current = width;
      generationHeight.current = height;
      generationImage.current = image;
      generationStyleUuid.current = styleUuid;
      generationAssetUuid.current = assetUuid;
      uploadMask({ image: mask });
    },
    isLoading: isGenerationLoading || isImageLoading || isMaskLoading,
  };
};

const useInpaintingMutation = ({
  boardUuid,
  onSuccess,
}: {
  boardUuid: string;
  onSuccess?: () => void;
}) => {
  const generateImageInpaintingMutation = useAppMutation({
    path: "boards/generate-image-inpainting",
    invalidate: [`boards/${boardUuid}`, "boards", "users/me"],
    onSuccess: () => {
      onSuccess?.();
    },
  }).mutation;

  const { magicDrawSettings } = useMagicDrawSettings(boardUuid);

  return {
    mutate: ({
      maskUuid,
      width,
      height,
      prompt,
      initImageUuid,
      styleUuid,
      assetUuid,
    }: {
      maskUuid: string;
      width: number;
      height: number;
      prompt: string;
      initImageUuid: string;
      styleUuid: string;
      assetUuid: string;
    }) => {
      generateImageInpaintingMutation.mutate({
        board_uuid: boardUuid,
        style_uuid: styleUuid,
        mask_uuid: maskUuid,
        width,
        height,
        prompt,
        asset_uuid: assetUuid,
        init_image_uuid: initImageUuid,
        num_generations: magicDrawSettings.num_generations,
        sampling_steps: magicDrawSettings.sampling_steps,
        cfg_scale: magicDrawSettings.cfg_scale,
        prompt_strength: magicDrawSettings.prompt_strength,
        refine: magicDrawSettings.sdxlRefiner,
        refine_steps: magicDrawSettings.refine_steps,
        high_noise_frac: magicDrawSettings.high_noise_frac,
        scheduler: magicDrawSettings.scheduler,
        lora_scale: magicDrawSettings.lora_scale,
        seed: magicDrawSettings.seed,
        apply_watermark: magicDrawSettings.apply_watermark,
        negative_prompt: magicDrawSettings.negative_prompt,
      });
    },
    isLoading: generateImageInpaintingMutation.isLoading,
  };
};
