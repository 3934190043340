import { useState } from "react";
import { BaseButton } from "../../../components/Button/BaseButton.tsx";
import { Icon } from "../../../components/Icon/Icon.tsx";
import { ExtraMenu } from "../components/ExtraMenu.tsx";
import type { Board } from "../types.ts";
import { BoardDeletionDialog } from "./BoardDeletionDialog.tsx";

export const BoardExtraMenu = ({
  board,
  className,
}: {
  board: Board;
  className: string;
}) => {
  const [deletionMenuOpen, setDeletionMenuOpen] = useState(false);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  return (
    <ExtraMenu
      className={className}
      isOpen={isPopoverOpen}
      onOpenChange={setIsPopoverOpen}
      dialog={
        <BoardDeletionDialog
          board={board}
          onDialogChange={setDeletionMenuOpen}
          dialogOpen={deletionMenuOpen}
          onCancel={() => setDeletionMenuOpen(false)}
        />
      }
      popoverContent={
        <div className="flex-col">
          <BaseButton
            className="p-md rounded-sm bg-transparent hover:bg-gray-200 w-full"
            onClick={() => {
              setIsPopoverOpen(false);
              setDeletionMenuOpen(true);
            }}
          >
            <div className="gap-lg flex-row text-popover-foreground">
              <Icon name="Trash" />
              Delete
            </div>
          </BaseButton>
        </div>
      }
    />
  );
};
