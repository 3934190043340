import classNames from "classnames";
import { type ReactNode, useLayoutEffect, useRef, useState } from "react";
import { useSnapCarousel } from "react-snap-carousel";
import { ClickableIcon } from "../Icon/ClickableIcon.tsx";

export const Carousel = ({
  items,
  className,
  leftButtonClassName,
  rightButtonClassName,
}: {
  items: ReactNode[];
  className?: string;
  leftButtonClassName?: string;
  rightButtonClassName?: string;
}) => {
  const {
    scrollRef,
    snapPointIndexes,
    next,
    prev,
    activePageIndex,
    pages,
    refresh,
  } = useSnapCarousel();

  const localScrollRef = useRef<HTMLUListElement | null>(null);

  const [scrollState, setScrollState] = useState<"start" | "middle" | "end">(
    "start",
  );

  useLayoutEffect(() => {
    refresh();
  }, [items, refresh]);

  const handleScroll = (event: React.UIEvent<HTMLElement>) => {
    setScrollState(
      event.currentTarget.scrollLeft === 0
        ? "start"
        : event.currentTarget.scrollWidth - event.currentTarget.scrollLeft ===
          event.currentTarget.clientWidth
        ? "end"
        : "middle",
    );
  };

  return (
    <div className="relative group/carousel">
      <ul
        className={classNames(
          "flex overflow-x-auto snap-mandatory scrollbar-hide",
          className,
        )}
        ref={(elem) => {
          localScrollRef.current = elem;
          scrollRef(elem);
        }}
        onScroll={handleScroll}
      >
        {items.map((item, i) => (
          <li
            key={i}
            className="flex-shrink-0"
            style={{
              scrollSnapAlign: snapPointIndexes.has(i) ? "start" : "",
            }}
          >
            {item}
          </li>
        ))}
      </ul>
      {pages.length > 1 && (
        <>
          <ClickableIcon
            name="ArrowLeft"
            className={classNames(
              "absolute rounded-full border-gray-200 shadow-md bg-white left-0 top-[50%]",
              scrollState === "start"
                ? "hidden"
                : "invisible group-hover/carousel:visible",
              leftButtonClassName,
            )}
            iconClassName="stroke-xl"
            size={32}
            onClick={() => {
              if (activePageIndex <= 0) {
                localScrollRef.current?.scroll({
                  left: 0,
                  behavior: "smooth",
                });
              } else {
                prev();
              }
            }}
          />
          <ClickableIcon
            name="ArrowRight"
            className={classNames(
              "absolute rounded-full border-gray-200 shadow-md bg-white right-0 top-[50%]",
              scrollState === "end"
                ? "hidden"
                : "invisible group-hover/carousel:visible",
              rightButtonClassName,
            )}
            iconClassName="stroke-xl"
            size={32}
            onClick={() => {
              if (activePageIndex === pages.length - 1) {
                localScrollRef.current?.scroll({
                  behavior: "smooth",
                  left:
                    localScrollRef.current.scrollWidth -
                    localScrollRef.current.clientWidth,
                });
              } else {
                next();
              }
            }}
          />
        </>
      )}
    </div>
  );
};
