import { useEffect, useState } from "react";
import { UrlAnimation } from "../../../../../../components/Animation/UrlAnimation.tsx";
import { Toggle } from "../../../../../../components/Toggle/Toggle.tsx";
import { LearnMoreLink } from "../../../../components/LearnMoreLink.tsx";
import { useGetSelectedAsset } from "../../../../hooks/useGetSelectedAsset.ts";
import type { Board } from "../../../../types.ts";
import { CollapsableSettingsSectionWrapper } from "../../../components/SettingsMenu/CollapsableSettingsSectionWrapper.tsx";
import { SettingsSectionWrapper } from "../../../components/SettingsMenu/SettingsSectionWrapper.tsx";
import { SliderSection } from "../../../components/SettingsMenu/SliderSection.tsx";
import { StyleIntensitySection } from "../../../components/SettingsMenu/StyleIntensitySection.tsx";
import { StyleWrappedSection } from "../../../components/SettingsMenu/StyleWrappedSection.tsx";
import { TextSection } from "../../../components/SettingsMenu/TextSection.tsx";
import { SettingsMenuLayout } from "../../../components/SettingsMenuLayout.tsx";
import {
  UPSCALE_DEFAULT_SETTINGS,
  useUpscaleSettings,
} from "./hooks/useUpscaleSettings.ts";
import { ImaginationStrengthSection } from "./ImaginationStrengthSection.tsx";
import { UpscaleGenerationButton } from "./UpscaleGenerationButton.tsx";

export const UpscaleSettings = ({ board }: { board: Board }) => {
  const { upscaleSettings, setUpscaleSettings } = useUpscaleSettings(
    board.uuid,
  );
  const selectedAsset = useGetSelectedAsset(board);

  const [advancedSectionOpen, setAdvancedSectionOpen] = useState(false);
  const [prompt, setPrompt] = useState(selectedAsset?.image.description ?? "");

  useEffect(() => {
    if (selectedAsset) setPrompt(selectedAsset.image.description ?? "");
  }, [selectedAsset]);

  useEffect(() => {
    if (upscaleSettings.imagination_strength_preset === "custom") {
      setAdvancedSectionOpen(true);
    }
  }, [upscaleSettings.imagination_strength_preset]);

  return (
    <SettingsMenuLayout
      body={
        <div className="flex-col">
          <StyleWrappedSection board={board} />
          <SettingsSectionWrapper
            name="Upscale factor"
            inline
            content={
              <div>
                <Toggle
                  leftItemName="2x"
                  rightItemName="4x"
                  value={
                    (upscaleSettings.scale_factor ??
                      UPSCALE_DEFAULT_SETTINGS.scale_factor) === 2
                      ? "left"
                      : "right"
                  }
                  onValueChange={(value) =>
                    setUpscaleSettings({
                      scale_factor: value === "left" ? 2 : 4,
                    })
                  }
                />
              </div>
            }
          />
          <SettingsSectionWrapper
            name="Imagination"
            content={<ImaginationStrengthSection board={board} />}
            // FIXME: Add info content
            infoContent={undefined}
          />
          <CollapsableSettingsSectionWrapper
            name="Advanced"
            open={advancedSectionOpen}
            onOpenChange={setAdvancedSectionOpen}
            content={
              <div className="flex-col">
                <StyleIntensitySection
                  loraScale={upscaleSettings.lora_scale}
                  onLoraScaleChange={(loraScale: number) =>
                    setUpscaleSettings({
                      lora_scale: loraScale,
                      imagination_strength_preset: "custom",
                    })
                  }
                />
                <SliderSection
                  min={0}
                  max={100}
                  value={Math.round(upscaleSettings.creativity * 100)}
                  sliderName="Creativity"
                  onChange={(creativity: number) =>
                    setUpscaleSettings({
                      creativity: creativity / 100,
                      imagination_strength_preset: "custom",
                    })
                  }
                  sliderInformationSection={
                    <div className="flex-col gap-md">
                      <div>
                        <span className="font-bold">Creativity</span> - Allows
                        the AI to "hallucinate" additional details, achieving
                        greater realism at the cost of moving further away from
                        the original image. Here's the Pimento’s touch! But be
                        careful: really high values can lead to some pretty
                        strange results.
                      </div>
                      <span className="font-semibold">
                        Recommendation:{" "}
                        {Math.round(UPSCALE_DEFAULT_SETTINGS.creativity * 100)}%
                      </span>
                      <LearnMoreLink
                        url="https://pimento.crisp.help/en/article/upscale-parameters-and-examples-1s8bhae/"
                        className="self-end"
                      />
                    </div>
                  }
                />
                <SliderSection
                  min={0}
                  max={100}
                  value={Math.round(upscaleSettings.resemblance * 100)}
                  sliderName="Similarity"
                  onChange={(resemblance: number) =>
                    setUpscaleSettings({
                      resemblance: resemblance / 100,
                      imagination_strength_preset: "custom",
                    })
                  }
                  sliderInformationSection={
                    <div className="min-h-[330px] flex-col gap-md">
                      <div>
                        <span className="font-bold">Similarity</span> -
                        Increasing this value will make the generation more
                        closely resemble the original image, but very high
                        values can result in blotches or a dirtier look. Lower
                        values give more freedom to the generation at the cost
                        of moving further away from the original image.
                      </div>
                      <span className="font-semibold">
                        Recommendation:{" "}
                        {Math.round(UPSCALE_DEFAULT_SETTINGS.resemblance * 100)}
                        %
                      </span>
                      <UrlAnimation url="https://storage.googleapis.com/419c45cf-be8a-4cba-bbcd-74a221eb2587/app/assets/092871bb-0f96-45bc-926d-c94a22fa46cc.json" />
                      <LearnMoreLink
                        url="https://pimento.crisp.help/en/article/upscale-parameters-and-examples-1s8bhae/"
                        className="mr-4 self-end"
                      />
                    </div>
                  }
                />
                <TextSection
                  title="Image description"
                  valuePlaceholder={selectedAsset?.image.description ?? ""}
                  value={prompt}
                  onValueChange={(value: string) => setPrompt(value)}
                />
                <TextSection
                  title="Exclude"
                  valuePlaceholder="illustration, wonderful chilli pepper, vivid colors..."
                  value={upscaleSettings.negative_prompt}
                  onValueChange={(negativePrompt: string) =>
                    setUpscaleSettings({ negative_prompt: negativePrompt })
                  }
                />
              </div>
            }
          />
        </div>
      }
      footer={<UpscaleGenerationButton prompt={prompt} board={board} />}
    />
  );
};
