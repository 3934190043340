import { Animation } from "../components/Animation/Animation.tsx";

export const MobileView = () => (
  <div className="flex-col-center h-full gap-[44px] text-black text-center pb-[32px]">
    <div className="max-w-[250px]">
      <Animation
        loadData={() => import("../assets/boardInitLoading.json")}
        loop
        autoplay
        className="w-full"
      />
    </div>
    <div className="flex-col-center">
      <span className="text-[40px] font-burg">we're desktop only!</span>
      <div className="flex-col-center text-[20px] py-[15px]">
        <span>Pimento is currently built for desktop.</span>
        <span> See you there!</span>
      </div>
    </div>
  </div>
);
