import { useEffect, useState } from "react";
import { useUser } from "../../hooks/useUser.ts";
import { ActionsSection } from "./Action/ActionsSection.tsx";
import { BoardSection } from "./Board/BoardSection.tsx";
import { UserInformationDialog } from "./components/UserInformationDialog.tsx";
import { HowToSection } from "./HowToSection.tsx";
import { Sidebar } from "./Sidebar/Sidebar.tsx";
import { StyleSection } from "./Style/StyleSection.tsx";

export const Homepage = () => {
  const { user } = useUser();
  const [isUserInformationOpen, setIsUserInformationOpen] = useState(false);

  // XXX: We display the user information dialog only for new users meaning users who haven't filled out their
  // occupation, industry
  useEffect(() => {
    if (user && (user.occupation === null || user.industry === null)) {
      setIsUserInformationOpen(true);
    }
  }, [user]);

  return (
    <>
      <div className="flex-row flex-fill bg-white">
        <Sidebar />
        <div className="flex-col flex-fill pt-3xl">
          <div className="text-4xl font-semibold mx-3xl pb-lg border-b">
            Home
          </div>
          <div className="flex-col flex-fill overflow-y-auto gap-[64px] py-3xl">
            <ActionsSection />
            <BoardSection />
            <StyleSection />
            <HowToSection />
          </div>
        </div>
      </div>
      <UserInformationDialog
        isOpen={isUserInformationOpen}
        onOpenChange={setIsUserInformationOpen}
      />
    </>
  );
};
