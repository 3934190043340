import classNames from "classnames";
import type { ReactNode } from "react";
import { Info } from "../../../components/Info.tsx";

export const SettingsSectionWrapper = ({
  name,
  infoContent,
  content,
  rightActionButton,
  className,
  inline = false,
}: {
  name: string;
  infoContent?: ReactNode;
  content: ReactNode;
  className?: string;
  rightActionButton?: ReactNode;
  inline?: boolean;
}) => (
  <SettingsSectionLayout className={className}>
    <div
      className={classNames(
        inline ? "flex-row items-center justify-between" : "flex-col",
      )}
    >
      <div className="h-3xl flex-row items-center justify-between">
        <div className="flex-row gap-sm items-center">
          <span className="font-semibold">{name}</span>
          {infoContent !== undefined && <Info content={infoContent} />}
        </div>
        {rightActionButton}
      </div>
      {content}
    </div>
  </SettingsSectionLayout>
);

export const SettingsSectionLayout = ({
  children,
  className,
}: {
  children: ReactNode;
  className?: string;
}) => (
  <div
    className={classNames("flex-col w-full px-lg py-md border-b", className)}
  >
    {children}
  </div>
);
