import { usePersistedState } from "../../../../../../../hooks/usePersistedState.ts";
import type {
  Dimension,
  Ratio,
  Scheduler,
  SDXLRefiner,
} from "../../../../../types.ts";

export type MagicDrawSettings = {
  num_generations: number;
  batch_size: number;
  sampling_steps: number;
  cfg_scale: number;
  width: number;
  height: number;
  prompt_strength: number;
  sdxlRefiner: SDXLRefiner;
  refine_steps: number;
  high_noise_frac: number;
  scheduler: Scheduler | undefined;
  lora_scale: number;
  seed: number | undefined;
  apply_watermark: boolean;
  negative_prompt: string;
  prompt_enhancement: boolean;
  ratio: Ratio;
  dimension: Dimension;
};

export const MAGIC_DRAW_DEFAULT_SETTINGS = {
  num_generations: 4,
  batch_size: 1,
  sampling_steps: 30,
  cfg_scale: 7,
  width: 768,
  height: 768,
  prompt_strength: 0.9,
  sdxlRefiner: "no_refiner",
  refine_steps: 5,
  high_noise_frac: 0.95,
  scheduler: undefined,
  lora_scale: 0.6,
  seed: undefined,
  apply_watermark: false,
  negative_prompt: "",
  prompt_enhancement: false,
  ratio: "1:1",
  dimension: 768,
} as const;

export const useMagicDrawSettings = (boardUuid: string) => {
  const [magicDrawSettings, setMagicDrawSettings] =
    usePersistedState<MagicDrawSettings>({
      // XXX: we version the persisted state to reset defaults for existing boards
      key: `magic-draw-settings-v2-${boardUuid}`,
      defaultValue: MAGIC_DRAW_DEFAULT_SETTINGS,
    });

  return {
    magicDrawSettings,
    setMagicDrawSettings: (newSettings: Partial<MagicDrawSettings>) =>
      setMagicDrawSettings((settings) => ({ ...settings, ...newSettings })),
  } as {
    magicDrawSettings: MagicDrawSettings;
    setMagicDrawSettings: (newSettings: Partial<MagicDrawSettings>) => void;
  };
};
