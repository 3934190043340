import classNames from "classnames";
import { Image } from "../../../components/Image/Image.tsx";
import type { ImageContent } from "../types.ts";
import { LoadingTile } from "./LoadingTile.tsx";

export const ImageTile = ({
  image,
  className,
  loading = "eager",
}: {
  image: ImageContent;
  className?: string;
  loading?: "eager" | "lazy";
}) => {
  if (image.status === "processing") {
    return <LoadingTile image={image} className={className} />;
  }

  return (
    <div className={classNames(className, "h-full w-full overflow-clip")}>
      <Image
        className="h-full w-full"
        imageClassName="h-full w-full object-cover object-center"
        src={image.url}
        loading={loading}
      />
    </div>
  );
};
