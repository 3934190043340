import { useMemo } from "react";
import { useUser } from "../../../hooks/useUser.ts";
import { useAppQuery } from "../../../http/useAppQuery.ts";
import { isUserAllowedTo } from "../../../types.ts";
import type { Board } from "../types.ts"; // FIXME: these values should come from the backend directly.

// FIXME: these values should come from the backend directly.
// For now we protect ourselves with a 10.000.000 boards for premium users.
const LIMITED_AMOUNT_OF_BOARDS = 2;
const UNLIMITED_AMOUNT_OF_BOARDS = 10000000;

export const useBoards = () => {
  const { user } = useUser();
  const boardsQuery = useAppQuery<Board[]>({ queryKey: "boards" });

  const maxAmountOfUserBoards =
    user && isUserAllowedTo(user, "boards:create-unlimited")
      ? UNLIMITED_AMOUNT_OF_BOARDS
      : LIMITED_AMOUNT_OF_BOARDS;

  const remainingUserBoards: number = useMemo(() => {
    if (boardsQuery.data?.length) {
      return Math.max(0, maxAmountOfUserBoards - boardsQuery.data.length);
    }
    // If we don't know how many user board the user has created,
    // we consider they don't have remaining ones to create.
    return 0;
  }, [boardsQuery.data?.length, maxAmountOfUserBoards]);

  return {
    userBoards: boardsQuery.data,
    maxAmountOfUserBoards,
    remainingUserBoards,
  };
};
