export const Share = ({ className }: { className?: string }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="16"
    viewBox="0 0 14 18"
    fill="none"
    className={className}
  >
    <path d="M0.600098 9V15.4C0.600098 15.8243 0.768669 16.2313 1.06873 16.5314C1.36878 16.8314 1.77575 17 2.2001 17H11.8001C12.2244 17 12.6314 16.8314 12.9315 16.5314C13.2315 16.2313 13.4001 15.8243 13.4001 15.4V9" />
    <path d="M10.2 4.2L7.00005 1L3.80005 4.2" />
    <path d="M7 1V11.4" />
  </svg>
);
