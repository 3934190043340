import { useEffect } from "react";
import { createStorageAtom } from "../../../../../../../atoms/createAtom.ts";
import { safeCast } from "../../../../../../../utils/common.ts";
import type { Dimension, QualityPreset, Ratio } from "../../../../../types.ts";

export type GenerateSettings = {
  num_generations: number;
  width: number;
  height: number;
  prompt_strength: number;
  lora_scale: number;
  negative_prompt: string;
  prompt_enhancement: boolean;
  ratio: Ratio;
  dimension: Dimension;
  quality_preset: QualityPreset;
};

export const GENERATE_DEFAULT_SETTINGS = {
  num_generations: 4,
  width: 768,
  height: 768,
  prompt_strength: 0.8,
  lora_scale: 0.78,
  negative_prompt: "",
  prompt_enhancement: true,
  ratio: "1:1",
  dimension: 768,
  quality_preset: "fast",
} as const;

export const useGenerateSettingsAtom = createStorageAtom(
  "board-generate-settings-v4",
  safeCast<Record<string, GenerateSettings>>({}),
  ({ get, update }) => ({
    getGenerateSettings: (boardUuid: string): GenerateSettings | undefined =>
      get()[boardUuid],
    updateGenerateSettings: (
      boardUuid: string,
      newSettings: Partial<GenerateSettings>,
    ) => {
      update((draft) => {
        const existingSettings = draft[boardUuid];
        draft[boardUuid] = { ...existingSettings, ...newSettings };
      });
    },
    resetGenerateSettings: (boardUuid: string) => {
      update((draft) => {
        const existingSettings = draft[boardUuid];
        draft[boardUuid] = {
          ...existingSettings,
          ...GENERATE_DEFAULT_SETTINGS,
        };
      });
    },
  }),
);

export const useDefaultGenerateSettingsAtom = createStorageAtom(
  "board-default-generate-settings-v3",
  safeCast<Record<string, GenerateSettings>>({}),
  ({ get, update }) => ({
    getDefaultGenerateSettings: (
      boardUuid: string,
    ): GenerateSettings | undefined => get()[boardUuid],
    updateDefaultGenerateSettings: (
      boardUuid: string,
      newSettings: GenerateSettings,
    ) => {
      update((draft) => {
        const existingSettings = draft[boardUuid];
        draft[boardUuid] = { ...existingSettings, ...newSettings };
      });
    },
    resetDefaultGenerateSettings: (boardUuid: string) => {
      update((draft) => {
        const existingSettings = draft[boardUuid];
        draft[boardUuid] = {
          ...existingSettings,
          ...GENERATE_DEFAULT_SETTINGS,
        };
      });
    },
  }),
);

export const useGenerateSettings = (boardUuid: string) => {
  const generateSettings = useGenerateSettingsAtom((s) =>
    s.getGenerateSettings(boardUuid),
  );
  const defaultGenerateSettings = useDefaultGenerateSettingsAtom((s) =>
    s.getDefaultGenerateSettings(boardUuid),
  );

  useEffect(() => {
    if (generateSettings === undefined) {
      useGenerateSettingsAtom
        .getState()
        .updateGenerateSettings(boardUuid, GENERATE_DEFAULT_SETTINGS);
    }
    if (defaultGenerateSettings === undefined) {
      useDefaultGenerateSettingsAtom
        .getState()
        .updateDefaultGenerateSettings(boardUuid, GENERATE_DEFAULT_SETTINGS);
    }
  }, [defaultGenerateSettings, generateSettings, boardUuid]);

  return {
    defaultGenerateSettings:
      defaultGenerateSettings ?? GENERATE_DEFAULT_SETTINGS,
    generateSettings: generateSettings ?? GENERATE_DEFAULT_SETTINGS,
  };
};
