import type { ReactNode } from "react";
import { Switch } from "../../../../../components/Switch/Switch.tsx";
import { Info } from "../../../components/Info.tsx";

export const SwitchSection = ({
  checked,
  onCheckedChange,
  switchName,
  switchInformationSection,
}: {
  checked: boolean;
  onCheckedChange: (value: boolean) => void;
  switchName: ReactNode;
  switchInformationSection?: ReactNode;
}) => (
  <div className="h-3xl flex-row items-center w-full justify-between">
    <div className="flex-row items-center gap-sm">
      {switchName}
      {switchInformationSection ? (
        <Info content={switchInformationSection} />
      ) : null}
    </div>
    <Switch checked={checked} onCheckedChange={onCheckedChange} />
  </div>
);
