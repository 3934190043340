import { useSelectedAssetUuid } from "../views/Board/hooks/useSelectedAssetUuid.ts";
import type { AppInitialState } from "../views/types.ts";

export const useSetAppInitialState = () => {
  const { clearSelectedAssetUuid } = useSelectedAssetUuid();
  return (state: AppInitialState) => {
    switch (state) {
      case "draw": {
        clearSelectedAssetUuid();
        break;
      }
      case "editSelection": {
        clearSelectedAssetUuid();
        break;
      }
      case "generateFromText": {
        break;
      }
      case "getVariations": {
        // XXX: the default behavior will be applied. An image in the board will be selected so the user will not upload
        //  an external image before making an image variation.
        //  To transform an uploaded image, the user should use the style transfer tool.
        break;
      }
      case "transferStyle": {
        break;
      }
      case "upscaleAndEnhance":
        clearSelectedAssetUuid();
        break;
    }
  };
};

// FIXME(@tomasyany): uncomment when we decide how to retrieve also the boardUuid.
// export const setAppInitialStateFromUrl = () => {
//   const initialState = new URLSearchParams(window.location.search).get(
//     "initialState",
//   );
//   if (initialState) {
//     // Clear the initial state from the URL.
//     window.history.replaceState({}, "", window.location.pathname);
//
//     if (APP_INITIAL_STATES.includesWiden(initialState)) {
//       setAppInitialState(initialState);
//     }
//   }
// };
