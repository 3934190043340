import { Image } from "../../../../../../components/Image/Image.tsx";
import type { Board, ImageContent } from "../../../../types.ts";
import { BaseImageContainer } from "./BaseImageContainer.tsx";

export const SingleImageContainer = ({
  image,
  board,
}: {
  image?: ImageContent;
  board: Board;
}) => (
  <BaseImageContainer image={image} board={board}>
    {image === undefined ? null : (
      <Image
        className="max-h-full max-w-full"
        imageClassName="max-h-full max-w-full"
        src={image.url}
      />
    )}
  </BaseImageContainer>
);
