import classNames from "classnames";
import { type ReactNode, useEffect } from "react";
import { useMatch } from "react-router";
import { ClickableIcon } from "../../../../../components/Icon/ClickableIcon.tsx";
import { Icon } from "../../../../../components/Icon/Icon.tsx";
import { Spinner } from "../../../../../components/Spinner/Spinner.tsx";
import { Tooltip } from "../../../../../components/Tooltip/Tooltip.tsx";
import { BOARDS, TRANSFER_TOOL } from "../../../../../routes.ts";
import { ImageDropZone } from "../../../components/ImageDropZone/ImageDropZone.tsx";
import { ImageTile } from "../../../components/ImageTile.tsx";
import { useAddImageOnBoard } from "../../../hooks/useAddImageOnBoard.ts";
import { useSelectedAssetUuid } from "../../../hooks/useSelectedAssetUuid.ts";
import type { Board } from "../../../types.ts";
import { useAlwaysShowStyleTransferInfoTooltip } from "./hooks/useAlwaysShowStyleTransferInfoTooltip.ts";

export const ImageStripe = ({ board }: { board: Board }) => {
  const { selectedAssetUuid, setSelectedAssetUuid } = useSelectedAssetUuid();

  useEffect(() => {
    document
      .getElementById(selectedAssetUuid ?? "")
      ?.scrollIntoView({ inline: "center", behavior: "instant" });
  }, [selectedAssetUuid]);

  return (
    <div className="h-full px-lg py-sm flex-row items-end gap-lg">
      <div
        className={classNames(
          "aspect-square",
          selectedAssetUuid ? "h-[90%]" : "h-full",
        )}
      >
        <StyleTransferInfoTooltip>
          <AddImageButton board={board} />
        </StyleTransferInfoTooltip>
      </div>
      <div
        id="image-stripe"
        className="h-full flex-row flex-fill items-end gap-lg overflow-x-auto"
      >
        {board.assets.map((asset) => (
          <div
            key={asset.uuid}
            id={asset.uuid}
            className={classNames(
              asset.uuid === selectedAssetUuid
                ? "h-full border-pimento-blue border-[2px]"
                : "h-[90%]",
              "relative aspect-square cursor-pointer",
            )}
            onClick={() => {
              setSelectedAssetUuid(asset.uuid);
            }}
          >
            {asset.deleted_at !== null && (
              <Icon
                name="Trash"
                className="z-10 absolute bottom-0 right-0 p-xs bg-pimento-red stroke-white"
              />
            )}
            <ImageTile
              image={asset.image}
              className="text-xs !p-0 !bg-gray-100"
              loading="lazy"
            />
          </div>
        ))}
      </div>
    </div>
  );
};

const AddImageButton = ({ board }: { board: Board }) => {
  const { setSelectedAssetUuid } = useSelectedAssetUuid();
  const { mutate: uploadImage, isLoading: isUploadLoading } =
    useAddImageOnBoard({
      boardUuid: board.uuid,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      onUploaded: (asset_uuid) => {
        setSelectedAssetUuid(asset_uuid);
        useAlwaysShowStyleTransferInfoTooltip
          .getState()
          .setAlwaysShowStyleTransferInfoTooltip(false);
      },
    });
  return (
    <ImageDropZone
      onDrop={(file) => {
        if (file) {
          uploadImage({
            image: file.fileToUpload,
          });
        }
      }}
      content={
        isUploadLoading ? (
          <Spinner />
        ) : (
          <Icon name="ImagePlus" size={22} className="stroke-pimento-blue" />
        )
      }
    />
  );
};

const StyleTransferInfoTooltip = ({ children }: { children: ReactNode }) => {
  const {
    state: { alwaysShowStyleTransferInfoTooltip },
  } = useAlwaysShowStyleTransferInfoTooltip();
  const transferMatch = useMatch(`${BOARDS}/:item/${TRANSFER_TOOL}`);
  const isTooltipOpen =
    transferMatch !== null
      ? alwaysShowStyleTransferInfoTooltip
        ? true
        : // XXX: Tooltip has its basic behavior on hover when it receives undefined
          undefined
      : false;

  return (
    <Tooltip
      side="right"
      arrowDisplayed
      delayDuration={200}
      isOpened={isTooltipOpen}
      content={
        <div className="relative w-full h-full max-w-[240px]">
          <div className="text-white font-semibold text-wrap">
            Start by uploading a new picture you’ll transfer your style on.
          </div>
          {isTooltipOpen === true && (
            <ClickableIcon
              name="X"
              className="absolute -right-20 -top-20"
              iconClassName="stroke-white"
              onClick={() => {
                useAlwaysShowStyleTransferInfoTooltip
                  .getState()
                  .setAlwaysShowStyleTransferInfoTooltip(false);
              }}
            />
          )}
        </div>
      }
      className="!p-lg !bg-gray-600 !rounded-sm !shadow-xl"
    >
      <div className="w-full h-full">{children}</div>
    </Tooltip>
  );
};
