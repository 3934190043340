import classNames from "classnames";
import type { ReactNode } from "react";
import { createPortal } from "react-dom";

export type ModalProps = {
  open: boolean;
  onClose: () => void;
  children: ReactNode;
  className?: string;
};

const portal = document.getElementById("modal-root")!;

export const Modal = ({ open, onClose, children, className }: ModalProps) => {
  if (!open) return null;

  return createPortal(
    <div
      className={classNames(
        "fixed inset-0 z-30 flex-col-center p-[100px] bg-white/80",
        className,
      )}
      onMouseDown={(e) => {
        if (e.currentTarget === e.target) onClose();
      }}
    >
      {children}
    </div>,
    portal,
  );
};
