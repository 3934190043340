import { createAtom } from "../../../../../atoms/createAtom.ts";

export const useImageStripeDisplay = createAtom(
  {
    isImageStripeOpen: true as boolean,
  },
  ({ get, set }) => ({
    toggleImageStripe: () =>
      set({
        isImageStripeOpen: !get().isImageStripeOpen,
      }),
  }),
);
