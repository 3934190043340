import { useLayoutEffect } from "react";
import { Navigate, Route } from "react-router";
import { Routes } from "react-router-dom";
import { useOn } from "../../../../hooks/useOn.ts";
import {
  DRAW_TOOL,
  SELECT_TOOL,
  UPSCALE_TOOL,
  VARIATION_TOOL,
} from "../../../../routes.ts";
import { applyCurrentSearchParamsToPath } from "../../../../utils/url.ts";
import { useInformationMenuDisplayState } from "../../hooks/useInformationMenuDisplayState.ts";
import { useSelectedAssetUuid } from "../../hooks/useSelectedAssetUuid.ts";
import { useOpenStyleImageModal } from "../../ImageModal/useOpenStyleImageModal.ts";
import type { Board } from "../../types.ts";
import { InformationMenu } from "../components/InformationMenu/InformationMenu.tsx";
import { WorkspaceLayout } from "../WorkspaceLayout.tsx";
import { EditToolMenu } from "./EditToolMenu.tsx";
import { useImageStripeDisplay } from "./hooks/useImageStripeDisplay.ts";
import { ImageStripe } from "./ImageStripe/ImageStripe.tsx";
import { GenerativeFillView } from "./Tools/GenerativeFillView/GenerativeFillView.tsx";
import { MagicDrawView } from "./Tools/MagicDrawView/MagicDrawView.tsx";
import { UpscaleView } from "./Tools/UpscaleView/UpscaleView.tsx";
import { VariationView } from "./Tools/VariationView/VariationView.tsx";

export const EditWorkspace = ({ board }: { board: Board }) => {
  const { selectedAssetUuid, setSelectedAssetUuid, clearSelectedAssetUuid } =
    useSelectedAssetUuid();

  useOn("keydown", (event) => {
    if (isOpenStyleImageModal) return;
    const selectedImageIndex = board.assets.findIndex(
      (asset) => asset.uuid === selectedAssetUuid,
    );
    if (
      event.key === "ArrowRight" &&
      selectedImageIndex < board.assets.length - 1
    ) {
      setSelectedAssetUuid(board.assets.at(selectedImageIndex + 1)!.uuid);
    }
    if (event.key === "ArrowLeft" && selectedImageIndex > 0) {
      setSelectedAssetUuid(board.assets.at(selectedImageIndex - 1)!.uuid);
    }
  });

  const { isOpenStyleImageModal } = useOpenStyleImageModal();
  const { isImageStripeOpen } = useImageStripeDisplay();
  const { isInformationMenuOpen } = useInformationMenuDisplayState();

  useLayoutEffect(() => {
    if (
      // XXX: If selectedImageUuid is null, we don't want to override the value,
      // because it means that we put it to that value on purpose, most likely
      // for an initial "empty" state coming from external link or homepage.
      selectedAssetUuid !== null &&
      !board.assets.some((asset) => asset.uuid === selectedAssetUuid)
    ) {
      board.assets
        .filter(
          (asset) =>
            asset.image.status === "succeeded" ||
            asset.image.status === "processing",
        )
        .let((assets) => {
          if (assets.length > 0) {
            setSelectedAssetUuid(assets[0].uuid);
          } else {
            clearSelectedAssetUuid();
          }
        });
    }
  }, [
    clearSelectedAssetUuid,
    selectedAssetUuid,
    board.assets,
    setSelectedAssetUuid,
  ]);

  return (
    <WorkspaceLayout
      toolMenu={<EditToolMenu />}
      content={
        <div className="flex-fill flex-col justify-end">
          <div className="flex-fill flex-row">
            <Routes>
              <Route
                path={VARIATION_TOOL}
                element={<VariationView board={board} />}
              />
              <Route
                path={DRAW_TOOL}
                element={<MagicDrawView board={board} />}
              />
              <Route
                path={SELECT_TOOL}
                element={<GenerativeFillView board={board} />}
              />
              <Route
                path={UPSCALE_TOOL}
                element={<UpscaleView board={board} />}
              />
              <Route
                path="*"
                element={
                  <Navigate
                    replace
                    to={applyCurrentSearchParamsToPath(SELECT_TOOL)}
                  />
                }
              />
            </Routes>
            {isInformationMenuOpen && <InformationMenu board={board} />}
          </div>
          {isImageStripeOpen && (
            <div className="h-[88px] border-t bg-white">
              <ImageStripe board={board} />
            </div>
          )}
        </div>
      }
    />
  );
};
