export const StyleTransfer = ({ className }: { className?: string }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    className={className}
  >
    <path
      d="M10.6 1C11.48 1 12.2 1.72 12.2 2.6V5.8C12.2 6.68 11.48 7.4 10.6 7.4M15.4 1C16.28 1 17 1.72 17 2.6V5.8C17 6.68 16.28 7.4 15.4 7.4M7.4 13.8H3.4C2.04 13.8 1 12.76 1 11.4V10.6M7.4 13.8L5 16.2M7.4 13.8L5 11.4M2.6 1H5.8C6.68366 1 7.4 1.71634 7.4 2.6V5.8C7.4 6.68366 6.68366 7.4 5.8 7.4H2.6C1.71634 7.4 1 6.68366 1 5.8V2.6C1 1.71634 1.71634 1 2.6 1ZM12.2 10.6H15.4C16.2837 10.6 17 11.3163 17 12.2V15.4C17 16.2837 16.2837 17 15.4 17H12.2C11.3163 17 10.6 16.2837 10.6 15.4V12.2C10.6 11.3163 11.3163 10.6 12.2 10.6Z"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
