import { saveAs } from "file-saver";
import JSZip from "jszip";
import { useState } from "react";
import { ClickableIcon } from "../../../../../components/Icon/ClickableIcon.tsx";
import { Tooltip } from "../../../../../components/Tooltip/Tooltip.tsx";

export const DownloadButton = ({
  imagesToDownload,
}: {
  imagesToDownload: { url: string; uuid: string }[];
}) => {
  const [isDownloading, setIsDownloading] = useState(false);

  const downloadImages = async () => {
    setIsDownloading(true);
    try {
      if (imagesToDownload.length === 1) {
        saveAs(imagesToDownload[0].url, `${imagesToDownload[0].uuid}.jpg`);
      } else {
        const zip = new JSZip();
        const remoteZips = imagesToDownload.map(async (image) => {
          const response = await fetch(image.url);
          const data = await response.blob();
          zip.file(`${image.uuid}.jpg`, data);
          return data;
        });
        await Promise.all(remoteZips);
        const blob = await zip.generateAsync({ type: "blob" });
        saveAs(blob, "pimento.zip");
      }
      setIsDownloading(false);
    } catch {
      setIsDownloading(false);
    }
  };

  return (
    <Tooltip content="Download" side="top">
      <ClickableIcon
        name="Download"
        iconClassName="stroke-gray-600 stroke-[0.7px]"
        onClick={() => void downloadImages()}
        isDisabled={isDownloading}
        isLoading={isDownloading}
      />
    </Tooltip>
  );
};
