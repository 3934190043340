import classNames from "classnames";
import { memo } from "react";
import { useNavigate } from "react-router-dom";
import { ClickableIcon } from "../../../../components/Icon/ClickableIcon.tsx";
import { Icon } from "../../../../components/Icon/Icon.tsx";
import { useAppMutation } from "../../../../http/useAppMutation.ts";
import { BOARDS, EDIT_WORKSPACE } from "../../../../routes.ts";
import { addSearchParamsToPath } from "../../../../utils/url.ts";
import { useDeleteAssetOnBoard } from "../../hooks/useDeleteAssetOnBoard.ts";
import type { Board, BoardAsset, ImageContent } from "../../types.ts";
import { ImageTile } from "../ImageTile.tsx";

export const AssetImageTile = memo(
  ({
    className,
    asset,
    board,
    innerClassName,
    favoriteTagUuid,
  }: {
    favoriteTagUuid?: string;
    className?: string;
    asset: BoardAsset;
    board: Board;
    innerClassName?: string;
  }) => {
    const deleteAsset = useDeleteAssetOnBoard(board);
    const addTag = useAppMutation({
      path: "tags/add-assets",
      invalidate: [`boards/${board.uuid}`],
    }).mutation;
    const removeTag = useAppMutation({
      path: "tags/remove-assets",
      invalidate: [`boards/${board.uuid}`],
    }).mutation;
    const navigate = useNavigate();

    const isDeleteLoading = deleteAsset.isLoading;

    const addOrRemoveFavoriteTag = () => {
      if (asset.is_in_favorite && favoriteTagUuid) {
        removeTag.mutate({
          asset_uuids: [asset.uuid],
          tag_uuid: favoriteTagUuid,
        });
      } else {
        addTag.mutate({
          asset_uuids: [asset.uuid],
          tag_uuid: favoriteTagUuid,
        });
      }
    };

    const setIsLiked = useAppMutation({
      path: "contents/set-is-liked",
      invalidate: [`boards/${board.uuid}`],
    }).mutation;

    return (
      <div
        className={classNames("group relative overflow-clip", className)}
        style={{
          gridArea: `auto / auto / span ${asset.image.tile_height} / span ${asset.image.tile_width}`,
        }}
        id={`AssetImageTile-${asset.image.uuid}`}
      >
        {asset.deleted_at !== null && (
          <Icon
            name="Trash"
            className="z-10 absolute bottom-0 right-0 p-xs bg-pimento-red stroke-white"
          />
        )}
        {asset.image.status === "succeeded" && (
          <div className="absolute top-0 left-0 w-full h-full p-md flex-row justify-between invisible group-hover:visible">
            <div className="flex-col h-full gap-md">
              <ClickableIcon
                name="ThumbsUp"
                iconClassName={
                  asset.image.is_liked === true ? "fill-pimento-blue" : ""
                }
                className="z-10 tile-action disabled:!opacity-100"
                tooltip={
                  asset.image.is_liked !== null
                    ? {
                        side: "left",
                        content: "You can't change your feedback",
                      }
                    : { side: "left", content: "Give positive feedback" }
                }
                isDisabled={asset.image.is_liked !== null}
                onClick={() => {
                  setIsLiked.mutate({ uuid: asset.image.uuid, is_liked: true });
                }}
              />
              <ClickableIcon
                name="ThumbsDown"
                iconClassName={
                  // FIXME: We should use different red colour as same one than favorite action
                  asset.image.is_liked === false ? "fill-pimento-red" : ""
                }
                className="z-10 tile-action disabled:!opacity-100"
                tooltip={
                  asset.image.is_liked !== null
                    ? {
                        side: "left",
                        content: "You can't change your feedback",
                      }
                    : { side: "left", content: "Give negative feedback" }
                }
                isDisabled={asset.image.is_liked !== null}
                onClick={() => {
                  setIsLiked.mutate({
                    uuid: asset.image.uuid,
                    is_liked: false,
                  });
                }}
              />
            </div>
            <div className="flex-col h-full gap-md">
              <ClickableIcon
                name="Heart"
                iconClassName={
                  asset.is_in_favorite
                    ? "fill-pimento-red stroke-pimento-red"
                    : ""
                }
                className="z-10 tile-action"
                tooltip={{
                  side: "right",
                  content: asset.is_in_favorite
                    ? "Remove from favorites"
                    : "Add to favorites",
                }}
                onClick={(event) => {
                  event.stopPropagation();
                  addOrRemoveFavoriteTag();
                }}
              />
              {!asset.deleted_at && (
                <ClickableIcon
                  name="Trash"
                  className="z-10 mt-auto tile-action"
                  tooltip={{ side: "right", content: "Delete" }}
                  disabled={isDeleteLoading}
                  onClick={(event) => {
                    event.stopPropagation();
                    deleteAsset.mutate({
                      uuids: [asset.uuid],
                    });
                  }}
                />
              )}
            </div>
          </div>
        )}
        <button
          className="h-full w-full"
          onClick={() => {
            navigate(
              addSearchParamsToPath(
                `${BOARDS}/${board.uuid}/${EDIT_WORKSPACE}`,
                { assetUuid: asset.uuid },
              ),
            );
          }}
        >
          <BaseItem image={asset.image} innerClassName={innerClassName} />
        </button>
      </div>
    );
  },
);
AssetImageTile.displayName = "AssetImageTile";

// XXX: we add min-h-[80px] min-w-[80px] so all the images are not at the top of the screen on page load, which would make the lazy loading inefficient
const BaseItem = ({
  image,
  innerClassName,
}: {
  image: ImageContent;
  innerClassName?: string;
}) => (
  <ImageTile
    className={classNames(
      "hover:scale-105 min-h-[100px] min-w-[100px]",
      innerClassName,
    )}
    image={image}
    loading="lazy"
  />
);
