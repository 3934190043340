import classNames from "classnames";
import type { ReactNode } from "react";
import { ClickableIcon } from "../../../components/Icon/ClickableIcon.tsx";

export const Card = ({
  cardTitle,
  onCancel,
  children,
  size = "medium",
}: {
  cardTitle: ReactNode;
  onCancel: () => void;
  children: ReactNode;
  size?: "small" | "medium";
}) => (
  <div
    className={classNames(
      size === "medium" ? "w-[280px]" : "w-[180px]",
      "flex-col px-md py-sm gap-lg",
    )}
  >
    <div className="flex-row justify-between items-center">
      {cardTitle}
      <ClickableIcon
        name="X"
        size={20}
        className="p-0"
        onClick={() => onCancel()}
      />
    </div>
    {children}
  </div>
);
