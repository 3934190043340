import classNames from "classnames";
import type { ReactNode } from "react";

export const PresetButton = ({
  isSelected,
  onClick,
  className,
  children,
}: {
  isSelected: boolean;
  onClick: () => void;
  className?: string;
  children: ReactNode;
}) => (
  <button
    className={classNames(
      "flex-fill px-md rounded-sm border h-2xl text-center capitalize",
      className,
      isSelected
        ? "bg-gray-300 border-gray-300 cursor-auto"
        : "border-gray-200 hover:bg-gray-200",
    )}
    onClick={onClick}
  >
    {children}
  </button>
);
