import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "../../components/Button/Button.tsx";
import { useUser } from "../../hooks/useUser.ts";
import { useAppMutation } from "../../http/useAppMutation.ts";
import { BOARDS } from "../../routes.ts";
import { isUserAllowedTo } from "../../types.ts";

export const AdminView = () => {
  const navigate = useNavigate();
  const { user } = useUser();

  const [targetUserEmail, setTargetUserEmail] = useState<string>();
  const [boardUuidToCopy, setBoardUuidToCopy] = useState<string>();
  const [newCopiedBoardUuid, setNewCopiedBoardUuid] = useState<string>();

  const copyBoard = useAppMutation({
    path: "boards/copy",
    invalidate: [],
    onSuccess: (response: { data: { uuid: string } }) =>
      setNewCopiedBoardUuid(response.data.uuid),
    onError: () => setNewCopiedBoardUuid(undefined),
  }).mutation;

  useEffect(() => {
    if (!(user && isUserAllowedTo(user, "boards:copy"))) navigate(BOARDS);
  }, [user, navigate]);
  return (
    <div className="flex-fill flex-col items-center">
      <div className="mt-36 text-2xl text-gray-900 font-semibold">Admin</div>

      <div className="flex-col gap-md w-[300px]">
        <div className="self-center mt-36 text-xl text-gray-900 font-semibold">
          Copy board
        </div>
        <div className="flex-col gap-sm">
          <span className="font-semibold">Board UUID</span>
          <input
            type="text"
            className="p-md rounded-sm border-[1px] border-gray-300 text-gray-500 placeholder:text-gray-300"
            placeholder="aeaeae-ffff-eeee-ffff-a1a1a1a1"
            value={boardUuidToCopy}
            onChange={(event) => {
              setBoardUuidToCopy(event.target.value);
              setNewCopiedBoardUuid(undefined);
            }}
          />
        </div>
        <div className="flex-col gap-sm">
          <span className="font-semibold">Target user email</span>
          <input
            type="text"
            className="p-md rounded-sm border-[1px] border-gray-300 text-gray-500 placeholder:text-gray-300"
            placeholder="user@email.com"
            value={targetUserEmail}
            onChange={(event) => {
              setTargetUserEmail(event.target.value);
              setNewCopiedBoardUuid(undefined);
            }}
          />
        </div>
        <Button
          disabled={!targetUserEmail || !boardUuidToCopy}
          loading={copyBoard.isLoading}
          onClick={() =>
            copyBoard.mutate({
              target_user_email: targetUserEmail,
              uuid: boardUuidToCopy,
            })
          }
        >
          Copy
        </Button>
        {newCopiedBoardUuid !== undefined && (
          <Link
            to={`${BOARDS}/${newCopiedBoardUuid}`}
            className="self-center text-blue-600 underline hover:text-blue-400"
          >
            Go to new copied board
          </Link>
        )}
      </div>
    </div>
  );
};
