import { usePersistedState } from "../../../../../../../hooks/usePersistedState.ts";
import { useAppQuery } from "../../../../../../../http/useAppQuery.ts";
import type { ScaleFactor } from "../../../../../types.ts";
import type {
  ImaginationStrengthPreset,
  ImaginationStrengthPresets,
} from "../types.ts";

export type UpscaleSettings = {
  resemblance: number;
  creativity: number;
  lora_scale: number;
  negative_prompt: string;
  num_outputs: number;
  scale_factor: ScaleFactor | undefined;
  // FIXME: the type could come from the backend
  imagination_strength_preset: ImaginationStrengthPreset;
};

export const UPSCALE_DEFAULT_SETTINGS = {
  lora_scale: 0.4,
  resemblance: 0.5,
  creativity: 0.6,
  // FIXME: Should be handled directly in the upscale node
  negative_prompt:
    "open mouth, long body, low resolution, bad anatomy, bad hands, missing fingers, extra digit, fewer digits, cropped, worst quality, low quality, mutant",
  num_outputs: 1,
  scale_factor: 2,
  imagination_strength_preset: "medium",
} as const;

// FIXME: Find best way to deal with atom updates and persistence of the settings
export const useUpscaleSettings = (boardUuid: string) => {
  const [upscaleSettings, setUpscaleSettings] =
    usePersistedState<UpscaleSettings>({
      key: `upscale-settings-${boardUuid}-v3`,
      defaultValue: UPSCALE_DEFAULT_SETTINGS,
    });

  const { data: upscaleImaginationStrengthPresets } =
    useAppQuery<ImaginationStrengthPresets>({ queryKey: "presets/upscale" });

  return {
    upscaleSettings,
    setUpscaleSettings: (newSettings: Partial<UpscaleSettings>) => {
      let transferStrengthSettings: Partial<UpscaleSettings> = {};
      if (
        newSettings.imagination_strength_preset &&
        upscaleImaginationStrengthPresets
      ) {
        // XXX: remove presets equal to null for custom presets
        transferStrengthSettings = Object.fromEntries(
          Object.entries(
            upscaleImaginationStrengthPresets[
              newSettings.imagination_strength_preset
            ],
          ).filter(([_, v]) => v !== null),
        );
      }
      setUpscaleSettings((settings) => ({
        ...settings,
        ...newSettings,
        ...transferStrengthSettings,
      }));
    },
  } as {
    upscaleSettings: UpscaleSettings;
    setUpscaleSettings: (newSettings: Partial<UpscaleSettings>) => void;
  };
};
