import classNames from "classnames";
import { type ReactElement, useState } from "react";
import { Link } from "react-router-dom";
import { BaseButton } from "../../../components/Button/BaseButton.tsx";
import { Button } from "../../../components/Button/Button.tsx";
import { Icon } from "../../../components/Icon/Icon.tsx";
import { useAppMutation } from "../../../http/useAppMutation.ts";
import { HOME } from "../../../routes.ts";
import type { Style, StyleColorKind } from "../../types.ts";
import { StylePreview } from "../components/StylePreview.tsx";

export const StyleColorKindForm = ({
  style,
  onContinue,
}: {
  style: Style;
  onContinue: () => void;
}) => {
  const [styleColorKind, setStyleColorKind] = useState<StyleColorKind>();

  const { mutate: updateStyle } = useAppMutation({
    path: "styles/update",
    invalidate: [`styles/${style.uuid}`],
  }).mutation;

  return (
    <div className="relative flex-col-center w-full flex-shrink overflow-y-auto pt-[80px] gap-[100px]">
      <Link
        to={HOME}
        className="absolute top-16 left-16 rounded-sm border-[1px] border-gray-300 bg-white text-gray-600"
      >
        <Icon
          name="X"
          size={32}
          className="p-8 rounded-sm bg-white text-gray-600 hover:bg-gray-200"
        />
      </Link>
      <StylePreview
        style={style}
        title="what best describe your style colors"
      />
      <div className="flex-col-center flex-fill w-full gap-[100px]">
        <div className="flex-row w-full justify-center flex-wrap gap-xl">
          {Object.keys(COLOR_STYLE_BUTTONS).map((it) => (
            <BaseButton
              key={it}
              onClick={() => setStyleColorKind(it)}
              className={classNames(
                it === styleColorKind
                  ? "border-gray-600"
                  : "hover:border-gray-350",
                "max-w-[192px] w-full p-lg rounded-sm border-xl",
              )}
            >
              <div className="flex-col gap-lg">
                {COLOR_STYLE_BUTTONS[it].header ? (
                  COLOR_STYLE_BUTTONS[it].header
                ) : (
                  <div className="flex-row h-3xl border w-fit">
                    {COLOR_STYLE_BUTTONS[it].colors.map((color) => (
                      <div
                        key={color}
                        style={{ backgroundColor: color }}
                        className="w-2xl h-full"
                      />
                    ))}
                  </div>
                )}
                <div className="flex-col text-lg">
                  <span className="font-semibold ">
                    {COLOR_STYLE_BUTTONS[it].title}
                  </span>
                  <span className="text-gray-400">
                    {COLOR_STYLE_BUTTONS[it].content}
                  </span>
                </div>
              </div>
            </BaseButton>
          ))}
        </div>
        <Button
          style={{ width: "300px" }}
          disabled={styleColorKind === undefined}
          onClick={() => {
            updateStyle({
              uuid: style.uuid,
              color_kind: styleColorKind,
            });
            onContinue();
          }}
        >
          Continue
        </Button>
      </div>
    </div>
  );
};

const COLOR_STYLE_BUTTONS: Record<
  StyleColorKind,
  { colors: string[]; title: string; content: string; header?: ReactElement }
> = {
  black_and_white: {
    colors: ["#1D1E21", "#B3B3B3", "#E1E1E1", "#FFFFFF"],
    title: "Black & White",
    content: "All shades of grey palette",
  },
  monochrome: {
    colors: ["#0f084b", "#3D60A7", "#81B1D5", "#A0D2E7"],
    title: "Monochrome",
    content: "Same shades and tints of a single color",
  },
  bland: {
    colors: ["#ABC3CE", "#E4C6C6", "#EDE8C5", "#B5CFAF"],
    title: "Bland",
    content: "Muted, neutral or dull palette",
  },
  regular: {
    colors: ["#044DBC", "#FF6666", "#FDDC19", "#70BE5B"],
    title: "Regular",
    content: "Balanced and neutral palette",
  },
  bright: {
    colors: ["#00AFF8", "#F80000", "#F3CE25", "#26E129"],
    title: "Bright",
    content: "Vivid, lively hues, and vibrant palette",
  },
  other: {
    colors: [],
    header: (
      <Icon
        name="Ellipsis"
        className="h-3xl w-3xl px-sm rounded-xs border fill-gray-600"
      />
    ),
    title: "Other",
    content: "If nothing fits in the previous categories",
  },
};
