import { type ReactNode, useEffect, useRef, useState } from "react";
import { Navigate, Route } from "react-router";
import { Routes } from "react-router-dom";
import { GENERATE_TOOL, TRANSFER_TOOL } from "../../../../routes.ts";
import { AssetImageTile } from "../../components/AssetImageTile/AssetImageTile.tsx";
import { FAVORITE_TAG_NAME } from "../../constants.ts";
import type { Board } from "../../types.ts";
import { EmptyStateInformation } from "../components/EmptyStateInformation.tsx";
import { WorkspaceLayout } from "../WorkspaceLayout.tsx";
import { CreateToolMenu } from "./CreateToolMenu.tsx";
import { GenerateSettings } from "./Tools/GenerateView/Settings/GenerateSettings.tsx";
import { StyleTransferSettings } from "./Tools/StyleTransferView/StyleTransferSettings.tsx";

export const CreateWorkspace = ({ board }: { board: Board }) => (
  <WorkspaceLayout
    toolMenu={<CreateToolMenu />}
    content={
      <div className="flex-fill flex-row">
        <Routes>
          <Route
            path={GENERATE_TOOL}
            element={<GenerateSettings board={board} />}
          />
          <Route
            path={TRANSFER_TOOL}
            element={<StyleTransferSettings board={board} />}
          />
          <Route path="*" element={<Navigate replace to={GENERATE_TOOL} />} />
        </Routes>
        <CreateWorkspaceImageGrid board={board} />
      </div>
    }
  />
);

const CreateWorkspaceImageGrid = ({ board }: { board: Board }) => (
  <div
    id="CreateWorkspaceGrid"
    className="p-3xl flex-fill flex-col w-full overflow-auto"
  >
    {board.assets.length > 0 ? (
      <SquareGrid nbColumns={4}>
        {board.assets.map((asset) => (
          // FIXME: The padding should be the responsibility of the asset tile
          <AssetImageTile
            key={asset.uuid}
            innerClassName="w-full h-full"
            className="shadow-sm"
            asset={asset}
            favoriteTagUuid={
              board.tags.find((it) => it.name === FAVORITE_TAG_NAME)?.uuid
            }
            board={board}
          />
        ))}
      </SquareGrid>
    ) : (
      <EmptyStateInformation
        iconName="PimentoStar"
        iconClassName="stroke-pimento-blue fill-pimento-blue"
        title="nothing here yet!"
        body="Use the generation bar to start creating."
      />
    )}
  </div>
);

const GAP = 10;
const SquareGrid = ({
  children,
  nbColumns,
}: {
  children: ReactNode;
  nbColumns: number;
}) => {
  const gridRef = useRef<HTMLDivElement>(null);

  const [rowHeight, setRowHeight] = useState<number>();

  useEffect(() => {
    if (!gridRef.current) return;
    const resizeObserver = new ResizeObserver((entries) => {
      setRowHeight(
        (entries[0].contentRect.width - (nbColumns - 1) * GAP) / nbColumns,
      );
    });
    resizeObserver.observe(gridRef.current);
    return () => resizeObserver.disconnect(); // clean up
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      ref={gridRef}
      className="grid grid-flow-dense"
      style={{
        gridTemplateColumns: `repeat(${nbColumns}, minmax(0, 1fr))`,
        gridAutoRows: rowHeight ? `${rowHeight}px` : undefined,
        gap: GAP,
      }}
    >
      {children}
    </div>
  );
};
