import classNames from "classnames";
import { BaseButton } from "../Button/BaseButton.tsx";

const TOGGLE_POSITIONS = ["left", "right"] as const;
type TogglePositionType = (typeof TOGGLE_POSITIONS)[number];

//  XXX: this component can be extended to more than 2 inputs. May be better to use the radix Toggle Group
export const Toggle = ({
  leftItemName,
  rightItemName,
  value,
  onValueChange,
}: {
  leftItemName: string;
  rightItemName: string;
  value: TogglePositionType;
  onValueChange: (value: TogglePositionType) => void;
}) => (
  <div className="flex-row items-center p-[1px] rounded-sm border border-gray-200">
    {TOGGLE_POSITIONS.map((position) => (
      <BaseButton
        key={position}
        className={classNames(
          value === position ? "bg-gray-300" : "",
          "px-md rounded-xs",
        )}
        onClick={() => onValueChange(position)}
      >
        {position === "right" ? rightItemName : leftItemName}
      </BaseButton>
    ))}
  </div>
);
