import { PresetButton } from "../../../../components/PresetButton.tsx";
import type { Board } from "../../../../types.ts";
import { GENERATIVE_FILL_CREATIVITY_STRENGTH_PRESETS } from "./constants.ts";
import { useGenerativeFillSettings } from "./hooks/useGenerativeFillSettings.ts";

export const GenerativeFillCreativityStrengthPresetSection = ({
  board,
}: {
  board: Board;
}) => {
  const { generativeFillSettings, setGenerativeFillSettings } =
    useGenerativeFillSettings(board.uuid);

  return (
    <div className="flex-row-center gap-sm">
      {GENERATIVE_FILL_CREATIVITY_STRENGTH_PRESETS.map((preset) => (
        <PresetButton
          key={preset}
          isSelected={
            generativeFillSettings.creativity_strength_preset === preset
          }
          onClick={() =>
            setGenerativeFillSettings({ creativity_strength_preset: preset })
          }
        >
          {preset}
        </PresetButton>
      ))}
    </div>
  );
};
